import { ExclamationCircleFilled } from "@ant-design/icons";
import AgoraRTC, { IAgoraRTCClient } from "agora-rtc-sdk-ng";
import {
  Button,
  Descriptions,
  Dropdown,
  Form,
  Input, message,
  Modal, Space,
  Switch,
  Table
} from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import {
  banFamSpaceUserApi,
  changeFamSpaceUserRoleApi,
  closeFamSpaceApi,
  createFamSpaceApi,
  getAccountInfoApi,
  getFamSpaceByIdApi,
  getFamSpaceListApi,
  getFamSpaceMembersApi,
  getFamSpaceTokenApi,
  getSelfFamSpaceRoleApi,
  joinFamSpaceApi,
  quitFamSpaceApi,
  requestMicroPhoneApi,
  TOKEN_KEY,
  transferFamspaceApi
} from "../../api";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import useAgora from "../../utils/useAgora";
import "./index.less";

const { confirm } = Modal;

// const client: IAgoraRTCClient = AgoraRTC.createClient({
//   mode: "rtc",
//   codec: "vp8",
// });
const client: any = null;

const agoraRoleMap: { [key: number]: string } = {
  1: "Owner",
  2: "Host",
  4: "Speaker",
  3: "Guest",
};

function Agora() {
  const ws = useRef<WebSocket | null>(null);
  const [readyState, setReadyState] = useState("正在链接中");
  const [wsMessage, setWsMessage] = useState();
  // const { localAudioTrack, joinState, leave, join, controller, remoteUsers, togglePublish } =
  //   useAgora(client);
  const { localAudioTrack, joinState, leave, join, controller, remoteUsers, togglePublish } =
    {} as any;
  const [agoraList, setAgoraList] = useState<API.FamSpaceDTO[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [currentAgora, setCurrentAgora] = useState<API.FamSpaceDTO>();
  const [agoraMembers, setAgoraMembers] = useState<API.FamSpaceMemberDTO[]>();
  const [params, setParams] = useState<API.FamSpaceListBody>({
    page: 1,
    size: 10,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<API.UserInfo>();
  const [form] = Form.useForm();
  const [userRole, setUserRole] = useState<number>(3);
  const [mute, setMute] = useState<boolean>(false);

  const getAllAgora = async () => {
    const res = await getFamSpaceListApi(params);
    setAgoraList(res.channels);
    setTotal(res.total);
  };

  const createAgora = async (values: API.CreateFamSpaceBody) => {
    const res = await createFamSpaceApi(values);
    getAllAgora();
    const newAgora = await getFamSpaceByIdApi({ famspace_id: res.famspace_id });
    joinAgora(newAgora);
  };

  const getSelfRole = async (id: number) => {
    const res = await getSelfFamSpaceRoleApi({ famspace_id: id });
    console.log(res);
  };

  const joinAgora = async (agora: API.FamSpaceDTO) => {
    leave();
    if (!userInfo) {
      return;
    }
    const res = await joinFamSpaceApi({
      famspace_id: agora.id,
      nick: userInfo?.nick,
      icon: userInfo?.profile_icon,
    });
    setUserRole(res.role);
    setCurrentAgora(agora);
    fetchAgoraMembers(agora);
    // get space token
    const tokenRes = await getFamSpaceTokenApi({
      famspace_id: agora.id,
      uid: userInfo.uid,
    });
    join(`${agora.id}`, tokenRes.space_token, userInfo.uid);
    setOpen(false);
    setConfirmLoading(false);
    changeMute(res.role !== 3);
    // heartbeat
    ws.current?.send(
      JSON.stringify({
        cmd: "ping",
        famspace_id: agora.id || 0,
        timestamp: new Date().getTime(),
      })
    );
  };

  const fetchAgoraMembers = async (agora: API.FamSpaceDTO) => {
    const res = await getFamSpaceMembersApi({ famspace_id: agora.id });
    setAgoraMembers(res.members);
  };

  const changeMemberRole = async (
    member: API.FamSpaceMemberDTO,
    roleId: number
  ) => {
    if (!currentAgora) {
      return;
    }
    await changeFamSpaceUserRoleApi({
      famspace_id: currentAgora.id,
      uid: member.uid,
      role: roleId,
    });
    fetchAgoraMembers(currentAgora);
  };

  const kickOutMember = async (member: API.FamSpaceMemberDTO) => {
    if (!currentAgora) {
      return;
    }
    const res = await banFamSpaceUserApi({
      famspace_id: currentAgora.id,
      uid: member.uid,
    });
    console.log(res);
    fetchAgoraMembers(currentAgora);
  };

  const quitAgora = async () => {
    if (!currentAgora) {
      return;
    }
    await quitFamSpaceApi({ famspace_id: currentAgora.id });
    setCurrentAgora(undefined);
    setAgoraMembers([]);
    leave();
    // heartbeat
    ws.current?.send(
      JSON.stringify({
        cmd: "ping",
        famspace_id: 0,
        timestamp: new Date().getTime(),
      })
    );
  };

  const closeAgora = async () => {
    if (!currentAgora) {
      return;
    }
    await closeFamSpaceApi({ famspace_id: currentAgora.id });
    setCurrentAgora(undefined);
    setAgoraMembers([]);
    getAllAgora();
    leave();
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        createAgora(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const getUserInfo = async () => {
    const famToken = localStorage.getItem(TOKEN_KEY);
    if (!famToken) {
      return;
    }
    const res = await getAccountInfoApi(famToken);
    setUserInfo(res);
  };

  const requestMicroPhone = async () => {
    if (!currentAgora) {
      return;
    }
    await requestMicroPhoneApi({ famspace_id: currentAgora?.id });
    message.success("Request send");
  };

  const changeVolume = (vol: number | null) => {
    console.log(vol, localAudioTrack?.getVolumeLevel());
    localAudioTrack?.setVolume(vol || 0);
  };

  const webSocketInit = useCallback(() => {
    const stateArr = [
      "正在链接中",
      "已经链接并且可以通讯",
      "连接正在关闭",
      "连接已关闭或者没有链接成功",
    ];
    if (!ws.current || ws.current.readyState === 3) {
      ws.current = new WebSocket("wss://dev.famchat.io/famspacewss/");
      ws.current.onopen = (_e) => {
        setReadyState(stateArr[ws.current?.readyState ?? 0]);
        // auth
        ws.current?.send(
          JSON.stringify({
            cmd: "syn",
            token: localStorage.getItem(TOKEN_KEY),
          })
        );
      };
      ws.current.onclose = (_e) =>
        setReadyState(stateArr[ws.current?.readyState ?? 0]);
      ws.current.onerror = (e) =>
        setReadyState(stateArr[ws.current?.readyState ?? 0]);
      ws.current.onmessage = (e) => {
        setWsMessage(e.data);
      };
    }
  }, [ws]);

  const transferFamspace = async (member: API.FamSpaceMemberDTO) => {
    if (!currentAgora) {
      return;
    }
    await transferFamspaceApi({
      famspace_id: currentAgora.id,
      to_uid: member.uid,
    });
  };

  const changeMute = (checked: boolean) => {
    setMute(checked);
    togglePublish(checked);
  };

  // ws message
  useEffect(() => {
    if (wsMessage) {
      const m = JSON.parse(wsMessage);
      // update members
      if (m.cmd === "exit" || m.cmd === "join") {
        message.info(`Famspace members changed: ${m.cmd}`);
        currentAgora && fetchAgoraMembers(currentAgora);
      }
      // close
      if (m.cmd === "close") {
        message.info("Famspace closed");
        setCurrentAgora(undefined);
        setAgoraMembers([]);
        getAllAgora();
        leave();
      }
      // request microphone
      if (m.cmd === "reqmic") {
        message.info("Request Mic");
        const user = agoraMembers?.find((member) => member.uid === m.uid);
        if (!user) {
          return;
        }
        // confirm
        confirm({
          title: "Request microphone",
          icon: <ExclamationCircleFilled />,
          content: `Do you want to allow user: ${user.nick} open microphone`,
          onOk() {
            changeMemberRole(user, 4);
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      }
      // role change
      if (m.cmd === "role") {
        message.info("Role changed");
        setUserRole(m.role);
        changeMute(m.role !== 3);
        currentAgora && fetchAgoraMembers(currentAgora);
        // const agora = currentAgora;
        // quitAgora().then(() => {
        //   agora && joinAgora(agora);
        // });
      }
    }
  }, [wsMessage, currentAgora]);

  useEffect(() => {
    getUserInfo();

    return () => {
      leave();
    }
  }, []);

  useEffect(() => {
    getAllAgora();
  }, [params]);

  useLayoutEffect(() => {
    webSocketInit();

    // heartbeat
    const timer = setInterval(() => {
      try {
        ws.current?.send(
          JSON.stringify({
            cmd: "ping",
            famspace_id: currentAgora?.id || 0,
            timestamp: new Date().getTime(),
          })
        );
      } catch (error) {
        console.log(error);
      }
    }, 60 * 1000);

    return () => {
      // ws.current?.close();
      clearInterval(timer);
    };
  }, [ws, webSocketInit, currentAgora]);

  useEffect(() => {
    console.log(joinState, localAudioTrack);
  }, [joinState, localAudioTrack]);

  const columns: ColumnsType<API.FamSpaceDTO> = [
    {
      title: "Logo",
      dataIndex: "logo",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => <a>{text}</a>,
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "host",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Button type="primary" size="small" onClick={() => joinAgora(record)}>
            Join
          </Button>
        </Space>
      ),
    },
  ];

  const MemberColumns: ColumnsType<API.FamSpaceMemberDTO> = [
    {
      title: "UID",
      dataIndex: "uid",
    },
    {
      title: "Nick",
      dataIndex: "nick",
    },
    {
      title: "Icon",
      dataIndex: "icon",
      render(value, record, index) {
        if (!value) {
          return "";
        }
        return (
          <img src={value} alt="" style={{ width: "16px", height: "16px" }} />
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role: number) => <span>{agoraRoleMap[role]}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small" align="center">
          {/* {<Button type="primary" size="small" danger onClick={() => kickOutMember(record)}>Ban</Button>} */}
          <Dropdown
            disabled={userRole === 3 || userRole === 4 || record.uid === userInfo?.uid || record.role === 1}
            menu={{
              items: Object.entries(agoraRoleMap)
                .filter(([roleId]) => roleId !== "1")
                .map(([roleId, roleName]) => ({
                  key: roleId,
                  label: (
                    <div
                      key={roleId}
                      onClick={() => changeMemberRole(record, Number(roleId))}
                    >
                      {roleName}
                    </div>
                  ),
                })),
            }}
          >
            <Button type="primary" size="small">
              Change Role
            </Button>
          </Dropdown>
          <Button
            type="primary"
            size="small"
            onClick={() => transferFamspace(record)}
            disabled={userRole !== 1}
          >
            Assign Owner
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div id="agora">
      <div className="agora-header">
        <div className="title">FamSpace</div>
        <Button type={"primary"} onClick={showModal}>
          Create Famspace
        </Button>
      </div>
      <div className="agora-container">
        <div className="table">
          <Table
            columns={columns}
            rowKey={"id"}
            dataSource={agoraList}
            pagination={{
              total,
              onChange(page, size) {
                setParams({ page, size });
              },
            }}
          />
        </div>
        <div className="current">
          {currentAgora && (
            <div className="info">
              <Descriptions title="Current FamSpace Info">
                <Descriptions.Item label="ID">
                  {currentAgora.id}
                </Descriptions.Item>
                <Descriptions.Item label="Logo">
                  {currentAgora.logo}
                </Descriptions.Item>
                <Descriptions.Item label="Name">
                  {currentAgora.name}
                </Descriptions.Item>
                <Descriptions.Item label="Owner">
                  {currentAgora.owner}
                </Descriptions.Item>
                <Descriptions.Item label="Description">
                  {currentAgora.desc}
                </Descriptions.Item>
                <Descriptions.Item label="Background">
                  {currentAgora.background}
                </Descriptions.Item>
                <Descriptions.Item label="My Role">
                  {agoraRoleMap[userRole]}
                </Descriptions.Item>
              </Descriptions>
              <div className="operation">
                <Button onClick={requestMicroPhone} disabled={userRole !== 3}>
                  Request Microphone
                </Button>
                {/* <Button onClick={triggerMute}> */}
                {/* Microphone {localAudioTrack?.muted ? "Unmute" : "Mute"} */}
                {userRole !== 3 && (
                  <span>
                    {" "}
                    Microphone：
                    <Switch
                      checked={mute}
                      checkedChildren="Unmute"
                      unCheckedChildren="Mute"
                      onChange={changeMute}
                    />
                  </span>
                )}
                {/* </Button> */}
                {/* Volume：<InputNumber defaultValue={100} onChange={changeVolume}></InputNumber> */}
              </div>
              <div className="operation">
                <Button type="primary" onClick={quitAgora}>
                  Quit
                </Button>
                <Button
                  type="primary"
                  disabled={userRole !== 1}
                  danger
                  onClick={closeAgora}
                >
                  Close
                </Button>
              </div>
              <div className="members">
                <div className="sub-title">Members</div>
                <Table
                  columns={MemberColumns}
                  dataSource={agoraMembers}
                  rowKey={"uid"}
                  pagination={false}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Title"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        closeIcon={<CloseIcon />}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
          <Form.Item name="desc" label="Description">
            <Input type="textarea" />
          </Form.Item>
          <Form.Item name="icon" label="Icon">
            <Input />
          </Form.Item>
          <Form.Item name="background" label="Background">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Agora;
