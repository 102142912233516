import { useContext, useEffect, useState } from "react";
import Header from "./Components/Header";
import Spaces from "./Spaces";
import Hosts from "./Hosts";
import { useParams } from "react-router-dom";
import BackTopSvg from "../assets/backtop_normal.svg";
import { BackTop, Button, Input } from "antd";
import "./index.less";
import { addTwitterSpaceApi, CUSTOM_URL_KEY, getGlobalInfoApi } from "../api";
// import Search from "./Search";
import { notify } from "../utils";
import LoginModal from "./Components/Login";
// import Featured from "./Featured";
import Airdrop from "./Airdrop";
import { Context } from "../utils/context";
import AISummary from "./AISummary";

function Home({
  changeTheme,
  theme,
  loggedIn,
}: {
  changeTheme: (theme: string) => void;
  theme: string;
  loggedIn: boolean;
}) {
  const searchParams = useParams();
  const [activeTab, setActiveTab] = useState<string>(
    searchParams.tab || "spaces"
  );
  const [hasLive, setHasLive] = useState<boolean>(false);
  const [spaceUrl, setSpaceUrl] = useState<string>("");
  const [loginOpen, setLoginOpen] = useState<boolean>(false);
  const context = useContext(Context);
  const { updateAirdropState }= context;

  const changeTab = (tab: string) => {
    setActiveTab(tab);
    window.history.pushState("", "", `${window.location.origin}/${tab}`);
  };

  const getGlobal = async () => {
    const res = await getGlobalInfoApi();
    if (res.data && res.data.follow_user_live_space_total) {
      setHasLive(true);
    }
    if (res.data) {
      updateAirdropState(res.data.airdrop_state);
    }
  };

  const onSpaceUrlChange = (event: any) => {
    setSpaceUrl(event.target.value);
  };

  const handleAddSpace = async () => {
    if (!spaceUrl) {
      return;
    }
    if (!spaceUrl.startsWith('https://')) {
      notify({
        type: 'warning',
        message: 'Wrong url',
        description: 'Please input correct twitter space url'
      })
    }
    const res = await addTwitterSpaceApi({ url: spaceUrl });
    if (res.errno === 0) {
      notify({
        type: "success",
        message: "Successfully added",
        description: "This Space has successfully added",
      });
    }
  };

  useEffect(() => {
    getGlobal();

    const urlParam = new URLSearchParams(window.location.search);
    const customUrl = urlParam.get('customUrl') || '';
    localStorage.setItem(CUSTOM_URL_KEY, customUrl);

    (window as any).famliveLogin = () => setLoginOpen(true);
  }, []);

  return (
    <div className="home-container">
      <Header
        theme={theme}
        changeTheme={changeTheme}
        activeTab={activeTab}
        changeTab={changeTab}
        showSlogan={true}
        hasLive={hasLive}
      />

      {/* {activeTab === 'featured' && <Featured theme={theme} loggedIn={loggedIn} />} */}

      {activeTab === "spaces" && <Spaces theme={theme} loggedIn={loggedIn} />}

      {activeTab === "hosts" && (
        <Hosts
          theme={theme}
          hasLive={hasLive}
          closeHasLive={() => setHasLive(false)}
        />
      )}

      {/* {activeTab === "search" && <Search theme={theme} />} */}

      {activeTab === "add" && (
        <div className="add-container">
          <div className="add-input">
            <Input
              className="input"
              value={spaceUrl}
              allowClear
              placeholder="Twitter space url"
              onChange={onSpaceUrlChange}
              onPressEnter={handleAddSpace}
            />
            <Button className="add-button" onClick={handleAddSpace}>
              Submit
            </Button>
          </div>
        </div>
      )}

      {activeTab === 'airdrop' && <Airdrop theme={theme} />}

      {activeTab === 'summary' && <AISummary theme={theme} />}

      <LoginModal visible={loginOpen} close={() => setLoginOpen(false)} />

      <BackTop>
        <img src={BackTopSvg} alt="" />
      </BackTop>
    </div>
  );
}

export default Home;
