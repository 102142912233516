import {
  AppstoreOutlined,
  MenuOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Col, Drawer, Input, List, Row, Select, Tag } from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "react-lottie";
import {
  createFavoriteSpaceApi,
  deleteFavoriteSpaceApi,
  getInviteInfoApi,
  getLabelListApi,
  getSpaceListApi,
  searchSpaceApi,
  getSpaceDetail,
  getMediaStream,
  TOKEN_KEY,
} from "../../api/index";
import Cancel from "../../assets/cancel.svg";
import CancelLight from "../../assets/cancel_light.svg";
import { ReactComponent as Collected } from "../../assets/collected.svg";
import { ReactComponent as Expand } from "../../assets/expand.svg";
import LiveAnimation from "../../assets/live_icon.json";
import LiveLight from "../../assets/live_icon_light.json";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import { ReactComponent as JumpTwitterIcon } from "../../assets/out_link.svg";
import { ReactComponent as ShareIcon } from "../../assets/share_twitter.svg";
import { ReactComponent as UnCollect } from "../../assets/unCollect.svg";
import { ReactComponent as PlayButton } from "../../assets/play-button.svg";
import { ReactComponent as PauseButton } from "../../assets/pause.svg";
import { ReactComponent as SkipButton } from "../../assets/skip_to_live.svg";
import { ReactComponent as SpaceEnd } from "../../assets/space-end.svg";
import { useWindowSize } from "../../utils/useWindowSize";
import ListLoader from "../Components/ListLoader";
import RenderCard from "../Components/SpaceCard/card";
import RedPacket from "../Components/RedPacket";
import "./index.less";

const SpaceLanguageDict = [
  { label: "All Language", value: "" },
  { label: "English", value: "en" },
  { label: "Chinese", value: "zh" },
  { label: "Arabic", value: "ar" },
  { label: "Armenian", value: "hy" },
  { label: "Danish", value: "da" },
  { label: "Finish", value: "fi" },
  { label: "French", value: "fr" },
  { label: "German", value: "de" },
  { label: "Hindi", value: "hi" },
  { label: "Hebrew", value: "iw" },
  { label: "Indonesian", value: "in" },
  { label: "Italian", value: "it" },
  { label: "Japanese", value: "ja" },
  { label: "Kazakh", value: "kk" },
  { label: "Korean", value: "ko" },
  { label: "Norwegian", value: "no" },
  { label: "Polish", value: "pl" },
  { label: "Portuguese", value: "pt" },
  { label: "Romanian", value: "ro" },
  { label: "Russian", value: "ru" },
  { label: "Spanish", value: "es" },
  { label: "Swedish", value: "sv" },
  { label: "Turkish", value: "tr" },
  { label: "Ukranian", value: "uk" },
];

const defaultParams = {
  label: "",
  state: "live",
  lang: "",
};

const PAGE_SIZE = 12;

const stateTags = [
  { label: "Featured", value: "featured" },
  { label: "Live", value: "live" },
  { label: "Scheduled", value: "scheduled" },
  { label: "Ended", value: "ended" },
];

const layoutTags = [
  { icon: <MenuOutlined style={{ fontSize: "22px" }} />, value: "list" },
  { icon: <AppstoreOutlined style={{ fontSize: "22px" }} />, value: "card" },
];

function EndSpaceTip({ url }: { url: string }) {
  return (
    <div className="end-space-tip-container">
      <div className="end-title">Space Ended</div>
      <SpaceEnd />
      <div className="end-detail">
        it seems like this space has ended, but you can check the space details
        here.
      </div>
      <Button
        className="go-detail-button"
        type="primary"
        onClick={() => {
          window.open(`/space/${url}`);
        }}
      >
        enter space
      </Button>
    </div>
  );
}

function Spaces({ theme, loggedIn }: { theme: string; loggedIn: boolean }) {
  const [list, setList] = useState<API.SpaceDTO[]>([]);
  const [labelList, setLabelList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMoreSpace, setHasMoreSpace] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openedSpace, setOpenedSpace] = useState<API.SpaceDTO>();
  const [params, setParams] = useState<API.SpaceListQuery>({
    ...defaultParams,
    page: 1,
    size: PAGE_SIZE,
  });
  const [selectedState, setSelectState] = useState<string>(defaultParams.state);
  const [selectedLabel, setSelectLabel] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [layout, setLayout] = useState<string>("card");
  const { width } = useWindowSize();
  const timer = useRef<any>();
  // search
  const [liveSpaces, setLiveSpaces] = useState<API.SpaceDTO[]>([]);
  const [scheduleSpaces, setScheduleSpaces] = useState<API.SpaceDTO[]>([]);
  const [endSpaces, setEndSpaces] = useState<API.SpaceDTO[]>([]);
  const [livePage, setLivePage] = useState<number>(1);
  const [scheduledPage, setScheduledPage] = useState<number>(1);
  const [endedPage, setEndedPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState({
    host: true,
    live: true,
    scheduled: true,
    ended: true,
  });
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [showPlayButton, setShowPlayButton] = useState<boolean>(false);
  const [showSkipButton, setShowSkipButton] = useState<boolean>(false);
  const [playButtonHovering, setPlayButtonHovering] = useState<boolean>(false);
  const [skipButtonHovering, setSkipButtonHovering] = useState<boolean>(false);
  const [hasAudio, setHasAudio] = useState<string>("none");
  const [showEndTip, setShowEndTip] = useState<boolean>(false);
  const searchLive = useCallback(async () => {
    if (!searchValue) {
      return;
    }
    // live
    const res = await searchSpaceApi({
      key: searchValue,
      page: livePage,
      size: PAGE_SIZE,
      state: "live",
    });
    if (livePage === 1) {
      setLiveSpaces(res.data);
    } else {
      setLiveSpaces([...liveSpaces, ...res.data]);
    }
    setHasMore({
      ...hasMore,
      live: res.data?.length === PAGE_SIZE,
    });
  }, [hasMore, livePage, liveSpaces, searchValue]);

  const searchScheduled = useCallback(async () => {
    if (!searchValue) {
      return;
    }
    // scheduled
    const res = await searchSpaceApi({
      key: searchValue,
      page: scheduledPage,
      size: PAGE_SIZE,
      state: "scheduled",
    });
    if (scheduledPage === 1) {
      setScheduleSpaces(res.data);
    } else {
      setScheduleSpaces([...scheduleSpaces, ...res.data]);
    }
    setHasMore({
      ...hasMore,
      scheduled: res.data?.length === PAGE_SIZE,
    });
  }, [hasMore, scheduleSpaces, scheduledPage, searchValue]);

  const searchEnded = useCallback(async () => {
    if (!searchValue) {
      return;
    }
    // ended
    const res = await searchSpaceApi({
      key: searchValue,
      page: endedPage,
      size: 8,
      state: "ended",
    });
    if (endedPage === 1) {
      setEndSpaces(res.data);
    } else {
      setEndSpaces([...endSpaces, ...res.data]);
    }
    setHasMore({
      ...hasMore,
      ended: res.data?.length === PAGE_SIZE,
    });
  }, [endSpaces, endedPage, hasMore, searchValue]);

  useEffect(() => {}, []);

  useEffect(() => {
    function canSpacePlay(location: string) {
      const regex = /\?type=(\w+)/;
      const match = regex.exec(location);
      if (match !== null) {
        const type = match[1];
        if (type === "replay") {
          setHasAudio("replay");
          setShowEndTip(true);
          (window as any).clearFamChatWidget();
          (window as any).stopLoad = true;
        } else {
          setHasAudio("live");
          setShowPlayButton(true);
        }
      } else {
        setHasAudio("live");
      }
    }
    function setSpaceCantPlay() {
      setHasAudio("none");
      setShowEndTip(true);
      (window as any).clearFamChatWidget();
      (window as any).stopLoad = true;
    }

    (window as any).canSpacePlay = canSpacePlay;
    (window as any).setSpaceCantPlay = setSpaceCantPlay;
  }, []);

  const onSearch = (event: any) => {
    setLivePage(1);
    setScheduledPage(1);
    setEndedPage(1);
    setSearchValue(event.target.value);
  };
  const updateSearchItem = (item: API.SpaceDTO) => {
    if (item.state === "ended") {
      setEndSpaces(
        endSpaces.map((space) => (space.space === item.space ? item : space))
      );
      return;
    }
    if (item.state === "scheduled") {
      setScheduleSpaces(
        scheduleSpaces.map((space) =>
          space.space === item.space ? item : space
        )
      );
      return;
    }
    if (item.state === "live") {
      setLiveSpaces(
        liveSpaces.map((space) => (space.space === item.space ? item : space))
      );
      return;
    }
  };

  useEffect(() => {
    if (livePage !== 1) {
      searchLive();
    }
  }, [livePage]);

  useEffect(() => {
    if (scheduledPage !== 1) {
      searchScheduled();
    }
  }, [scheduledPage]);

  useEffect(() => {
    if (endedPage !== 1) {
      searchEnded();
    }
  }, [endedPage]);
  /** load more end */

  // debounce search
  useEffect(() => {
    if (!searchValue) {
      return;
    }
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      searchLive();
      searchScheduled();
      searchEnded();
    }, 500);
  }, [searchValue]);
  const { column, scrollWidth, listLayout } = useMemo(() => {
    if (width < 1301 && width > 1060) {
      return {
        column: 3,
        scrollWidth: "calc(100vw - 80px)",
        listLayout: "list",
      };
    }
    if (width < 1061 && width > 750) {
      return {
        column: 2,
        scrollWidth: "calc(100vw - 80px)",
        listLayout: "list",
      };
    }
    if (width < 751) {
      return {
        column: 1,
        scrollWidth: "calc(100vw - 40px)",
        listLayout: "card",
      };
    }
    return {
      column: 4,
      scrollWidth: "calc(100vw - 80px)",
      listLayout: "list",
    };
  }, [width]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
      (window as any).isModalOpen = true;
    }
    return () => {
      if (!isOpen) {
        setShowPlayButton(false);
        setIsPlaying(true);
        setShowEndTip(false);
      }
      if (isOpen) document.body.classList.remove("modal-open");
      if ((window as any).spacePlayLogTimer) {
        clearInterval((window as any).spacePlayLogTimer);
      }
      (window as any).isModalOpen = false;
      (window as any).hlsInstance?.destroy();
      (window as any).hlsInstance = null;
      (window as any).audioResourceUrlPrefix = "";
      (window as any).mediaAddress = "";
      (window as any).stopLoad = false;
    };
  }, [isOpen]);

  const searchSpaceList = (params: API.SpaceListQuery) => {
    if (loading) {
      return;
    }
    setLoading(true);
    setList([]);
    const handler = async () =>
      await getSpaceListApi({ ...params, page: 1, size: PAGE_SIZE });
    handler()
      .then((res) => {
        if (res.errno === 0) {
          setList(res.data);
        }
        setHasMoreSpace(res.data.length === PAGE_SIZE);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchLabelList = () => {
    getLabelListApi().then((res) => {
      if (res.errno === 0 && res.data) {
        setLabelList(res.data);
      }
    });
  };

  const fetchSpaceListScroll = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const newParam = {
      ...params,
      page: params.page + 1,
    };
    const handler = async () => await getSpaceListApi(newParam);
    handler()
      .then((res) => {
        if (res.errno === 0) {
          setList([...list, ...res.data]);
          setHasMoreSpace(res.data.length === PAGE_SIZE);
        }
        setParams(newParam);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const openChat = (space: API.SpaceDTO) => {
    setOpenedSpace(space);
    setIsOpen(true);
    (window as any).currentChannel = space.channel_unique_label;
    (window as any).currentSpace = space.space;
  };

  useEffect(() => {
    if (isOpen && openedSpace) {
      (window as any).renderFamChatWidget(theme, {
        renderMode: "tab",
        loggedIn,
      });
      const currentChannel = (window as any).currentChannel;
      (window as any).currentChannel = openedSpace.channel_unique_label;
      if (currentChannel !== openedSpace.channel_unique_label) {
        if ((window as any).resetFamChatState)
          (window as any).resetFamChatState();
        (window as any).mediaAddress = "";
      }
      // localStorage.setItem("current_channel", openedSpace.channel_unique_label);
      // localStorage.setItem("current_space", openedSpace.space);
    }
  }, [isOpen, openedSpace, theme, loggedIn]);

  const handleStateChange = (tag: string, checked: boolean) => {
    if (tag === selectedState) {
      return;
    }
    setSelectState(tag);
    setParams({ ...params, state: tag, page: 1 });
    searchSpaceList({ ...params, state: tag, page: 1 });
  };

  const handleLangChange = (tag: string) => {
    if (tag === params.lang) {
      return;
    }
    setParams({ ...params, lang: tag });
    searchSpaceList({ ...params, lang: tag });
  };

  const handleLabelChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedLabel, tag]
      : selectedLabel.filter((t) => t !== tag);
    setSelectLabel(nextSelectedTags);
    setParams({ ...params, label: nextSelectedTags.join(",") });
    searchSpaceList({ ...params, label: nextSelectedTags.join(",") });
  };

  const collectSpace = async () => {
    if (!openedSpace) {
      return;
    }
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      (window as any).famliveLogin();
      return;
    }
    const handler = openedSpace.is_collect
      ? deleteFavoriteSpaceApi
      : createFavoriteSpaceApi;
    try {
      const res = await handler({ space_id: openedSpace?.space });
      if (res.errno === 0) {
        setOpenedSpace({
          ...openedSpace,
          is_collect: !openedSpace.is_collect,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const composeTweet = async (e: any) => {
    e?.stopPropagation();
    if (!openedSpace) {
      return;
    }
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      (window as any).famliveLogin();
      return;
    }
    const res = await getInviteInfoApi({ space_id: openedSpace.space });
    if (res.errno !== 0) {
      return;
    }
    const { text, url, hashtag } = res.data;
    const newUrl = `https://twitter.com/intent/tweet?text=${text}&hashtags=${hashtag}&url=${url}`;

    window.open(newUrl);
  };

  const updateItem = (item: API.SpaceDTO) => {
    setList(
      list.map((space) => {
        if (space.space === item.space) {
          return item;
        }
        return space;
      })
    );
  };

  useEffect(() => {
    searchSpaceList(params);
    fetchLabelList();
  }, []);

  const formatList = (arr: API.SpaceDTO[]) => {
    if (layout === "list") {
      return arr;
    }
    if (selectedState === "scheduled" || selectedState === "ended") {
      // format date
      arr = arr.map((item) => {
        const timestamp =
          selectedState === "scheduled" ? item.scheduled_start : item.ended_at;
        return {
          ...item,
          date: moment.unix(timestamp).utc().format("MM-DD"),
        };
      });
      // group
      const newArr: API.SpaceDTO[][] = [];
      let date = arr[0] && arr[0].date;
      let tempArr: API.SpaceDTO[] = [];
      arr.forEach((item) => {
        if (item.date === date) {
          tempArr.push(item);
        } else {
          newArr.push(tempArr);
          tempArr = [item];
          date = item.date;
        }
      });
      if (tempArr.length) {
        newArr.push(tempArr);
      }
      return newArr;
    }
    return arr;
  };

  const renderListItem = (item: any) => {
    if (layout === "list") {
      return (
        <RenderCard
          item={item}
          layout={layout}
          openChat={openChat}
          theme={theme}
          updateItem={updateItem}
        />
      );
    }
    if (selectedState === "scheduled" || selectedState === "ended") {
      const list = item;
      const windowWidth =
        document.body.clientWidth > 1440 ? 1440 : document.body.clientWidth;
      const itemWidth = (windowWidth - 96) / column - 12;
      return (
        <div className="date-card">
          <div className="date">
            <span>{list[0] && list[0].date}</span>
          </div>
          <div className="line"></div>
          <Row>
            {list.map((i: any) => {
              return (
                <Col
                  style={{
                    width: `${itemWidth}px`,
                    margin: "0 5px",
                  }}
                  key={i.space}
                >
                  <RenderCard
                    item={i}
                    layout={layout}
                    openChat={openChat}
                    theme={theme}
                    updateItem={updateItem}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      );
    }
    return (
      <RenderCard
        item={item}
        layout={layout}
        openChat={openChat}
        theme={theme}
        updateItem={updateItem}
      />
    );
  };
  const listColumn = useMemo(() => {
    if (layout === "list") {
      return 1;
    }
    if (layout === "card" && selectedState === "scheduled") {
      return 1;
    }
    if (layout === "card" && selectedState === "ended") {
      return 1;
    }
    return column;
  }, [column, layout, selectedState]);

  return (
    <div className="home-content">
      <div className="search-input">
        <Input
          className="input"
          value={searchValue}
          allowClear
          prefix={<SearchOutlined style={{ marginRight: "8px" }} />}
          placeholder="Search space"
          onChange={onSearch}
          onPressEnter={onSearch}
        />
      </div>
      {!searchValue && (
        <div className="filter">
          <div className="filter-top">
            <div className="state">
              {stateTags.map((tag) => (
                <CheckableTag
                  className={"state-tag " + tag.value}
                  key={tag.value}
                  // checked={selectedState.indexOf(tag.value) > -1}
                  checked={selectedState === tag.value}
                  onChange={(checked) => handleStateChange(tag.value, checked)}
                >
                  {tag.value === "live" && (
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData:
                          theme === "dark" || selectedState === tag.value
                            ? LiveAnimation
                            : LiveLight,
                      }}
                      width={20}
                      height={40}
                      style={{ marginRight: "3px" }}
                    />
                  )}
                  {tag.value === "featured" && "🔥 "}
                  {tag.label}
                </CheckableTag>
              ))}
            </div>
            <div className="lang-layout">
              <Select
                className="filter-select"
                defaultValue=""
                onChange={handleLangChange}
                options={SpaceLanguageDict}
              />
              <div className="layout">
                {layoutTags.map((item) => (
                  <CheckableTag
                    key={item.value}
                    className="state-tag layout-tag"
                    checked={layout === item.value}
                    onChange={(checked) => checked && setLayout(item.value)}
                  >
                    {item.icon}
                  </CheckableTag>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="filter-bottom">
            {labelList.map((tag) => (
              <CheckableTag
                className="label-tag"
                key={tag}
                checked={selectedLabel.indexOf(tag) > -1}
                onChange={(checked) => handleLabelChange(tag, checked)}
              >
                {tag}
                {selectedLabel.indexOf(tag) > -1 && (
                  <img
                    className="cancel-label"
                    src={theme === "dark" ? Cancel : CancelLight}
                    alt=""
                    onClick={() => handleLabelChange(tag, false)}
                  />
                )}
              </CheckableTag>
            ))}
          </div> */}
        </div>
      )}
      {searchValue ? (
        <div className="search-result">
          {/* live */}
          {liveSpaces?.length > 0 && (
            <div className="space-list">
              <div className="search-sub-title">Live</div>
              <div className="result">
                {liveSpaces.map((space) => (
                  <RenderCard
                    item={space}
                    layout={listLayout}
                    theme={theme}
                    updateItem={updateSearchItem}
                  />
                ))}
              </div>
              {hasMore.live && (
                <div className="more">
                  <Button
                    className="load-more-btn"
                    onClick={() => setLivePage(livePage + 1)}
                  >
                    Load more
                  </Button>
                </div>
              )}
            </div>
          )}

          {/* scheduled */}
          {scheduleSpaces?.length > 0 && (
            <div className="space-list">
              <div className="search-sub-title">Scheduled</div>
              <div className="result">
                {scheduleSpaces.map((space) => (
                  <RenderCard
                    item={space}
                    layout={listLayout}
                    theme={theme}
                    updateItem={updateSearchItem}
                  />
                ))}
              </div>
              {hasMore.scheduled && (
                <div className="more">
                  <Button
                    className="load-more-btn"
                    onClick={() => setScheduledPage(scheduledPage + 1)}
                  >
                    Load more
                  </Button>
                </div>
              )}
            </div>
          )}

          {/* ended */}
          {endSpaces?.length > 0 && (
            <div className="space-list">
              <div className="search-sub-title">Ended</div>
              <div className="result">
                {endSpaces.map((space) => (
                  <RenderCard
                    item={space}
                    layout={listLayout}
                    theme={theme}
                    updateItem={updateSearchItem}
                  />
                ))}
              </div>
              {hasMore.ended && (
                <div className="more">
                  <Button
                    className="load-more-btn"
                    onClick={() => setEndedPage(endedPage + 1)}
                  >
                    Load more
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <InfiniteScroll
          dataLength={list.length}
          next={fetchSpaceListScroll}
          hasMore={hasMoreSpace}
          style={{ width: scrollWidth, maxWidth: "1356px" }}
          loader={<ListLoader layout={layout} />}
          scrollableTarget="body"
        >
          <List
            className="home-list"
            rowKey={"space"}
            grid={{ gutter: 16, column: listColumn }}
            dataSource={formatList(list) as any}
            renderItem={(item) => renderListItem(item)}
          />
        </InfiniteScroll>
      )}
      {/* chat */}
      <Drawer
        open={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        headerStyle={{ display: "none" }}
        bodyStyle={{ padding: 0 }}
        getContainer={"html"}
      >
        {openedSpace && (
          <div className={`chat-space ${openedSpace.state}`}>
            <div className="close-btn">
              <Expand
                onClick={() => {
                  setIsOpen(!isOpen);
                  window.open(`/space/${openedSpace.space}`);
                }}
              />
              <CloseIcon
                onClick={() => setIsOpen(!isOpen)}
                style={{ fontSize: "18px", color: "#fff" }}
              />
            </div>
            <div className="state">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: LiveAnimation,
                }}
                width={20}
                height={40}
                style={{ margin: "0 3px" }}
              />
              {openedSpace.state.toUpperCase()}
              &nbsp;·&nbsp;
              <span className="hostname">
                {openedSpace.users[0] && openedSpace.users[0].username}
              </span>
            </div>
            <div className="title">{openedSpace.title}</div>

            <div className="labels">
              {showPlayButton && (
                <div className="audio-control-btn-container">
                  {!isPlaying && (
                    <>
                      <div
                        className="audio-control-btn"
                        onClick={() => {
                          const element = document.getElementById(
                            "audio"
                          ) as HTMLAudioElement;
                          element.play();
                          setIsPlaying(true);
                        }}
                        onMouseEnter={() => {
                          setPlayButtonHovering(true);
                        }}
                        onMouseLeave={() => {
                          setPlayButtonHovering(false);
                        }}
                      >
                        <PlayButton />
                        {playButtonHovering ? "Resume from pause" : ""}
                      </div>
                      <div
                        className="audio-control-btn"
                        onClick={() => {
                          const element = document.getElementById(
                            "audio"
                          ) as HTMLAudioElement;
                          element.currentTime = element.duration;
                          element.play();
                          setIsPlaying(true);
                          setShowSkipButton(false);
                          setSkipButtonHovering(false);
                        }}
                        onMouseEnter={() => {
                          setSkipButtonHovering(true);
                        }}
                        onMouseLeave={() => {
                          setSkipButtonHovering(false);
                        }}
                      >
                        <SkipButton />
                        {skipButtonHovering ? "Skip to Live" : ""}
                      </div>
                    </>
                  )}
                  {isPlaying && (
                    <>
                      <div
                        className={`audio-control-btn ${
                          playButtonHovering ? "hovering" : ""
                        }`}
                        onClick={() => {
                          const element = document.getElementById(
                            "audio"
                          ) as HTMLAudioElement;
                          element.pause();
                          setShowSkipButton(true);
                          setIsPlaying(false);
                        }}
                        onMouseEnter={() => {
                          setPlayButtonHovering(true);
                        }}
                        onMouseLeave={() => {
                          setPlayButtonHovering(false);
                        }}
                      >
                        <PauseButton />
                        {playButtonHovering ? "Pause" : ""}
                      </div>
                      {showSkipButton && (
                        <div
                          className="audio-control-btn"
                          onClick={() => {
                            const element = document.getElementById(
                              "audio"
                            ) as HTMLAudioElement;
                            element.currentTime = element.duration;
                            element.play();
                            setIsPlaying(true);
                            setShowSkipButton(false);
                            setSkipButtonHovering(false);
                          }}
                          onMouseEnter={() => {
                            setSkipButtonHovering(true);
                          }}
                          onMouseLeave={() => {
                            setSkipButtonHovering(false);
                          }}
                        >
                          <SkipButton />
                          {skipButtonHovering ? "Skip to Live" : ""}
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              <div className="links">
                <Tag
                  className="btn-tag out-link"
                  onClick={() => window.open(openedSpace.space_url)}
                >
                  <JumpTwitterIcon />
                </Tag>
                <Tag className="btn-tag" onClick={composeTweet}>
                  <ShareIcon />
                </Tag>
                <Tag className="btn-tag" onClick={collectSpace}>
                  {openedSpace.is_collect ? <Collected /> : <UnCollect />}
                </Tag>
              </div>
            </div>
          </div>
        )}
        {/* chat widget container */}
        <div id="famlive_chat_widget_container"></div>
        {(hasAudio === "replay" || hasAudio === "none") &&
          showEndTip &&
          openedSpace?.space && <EndSpaceTip url={openedSpace?.space} />}
        {openedSpace && (
          <RedPacket
            spaceId={openedSpace.space}
            channel_id={openedSpace.channel_id}
          />
        )}
      </Drawer>
    </div>
  );
}

export default Spaces;
