import { notification } from "antd";
import { createElement, ReactNode } from "react";
import SuccessIcon from "../assets/notify_success.svg";
import ErrorIcon from "../assets/notify_error.svg";
import WarningIcon from "../assets/notify_warning.svg";
import InfoIcon from "../assets/notify_info.svg";
import CancelIcon from "../assets/notify_cancel.svg";
import PendingIcon from "../assets/notify_pending.svg";
// @ts-ignore
import Identicon from "identicon.js";
import NoImg from "../assets/noImg.png";
import moment from "moment";

export const DOMAIN = window.location.host;

export const DEPOSIT_CURSOR_KEY = 'DEPOSIT_CURSOR';

export const formatNumber = (number?: number) => {
  if (!number) {
    return 0;
  }
  if (number > 1000000) {
    let n = Number((number / 1000000).toFixed(1)).toLocaleString();
    return `${n}M`;
  }
  if (number > 10000) {
    let n = Number((number / 1000).toFixed(1)).toLocaleString();
    return `${n}K`;
  }
  return number.toLocaleString();
};

type NotifyConfig = {
  key?: string;
  type?: string;
  message: ReactNode;
  description: ReactNode;
  holding?: boolean;
  longMessage?: boolean;
  onClick?: () => void;
};

// custom notify
export const notify = (config: NotifyConfig) => {
  let src = InfoIcon;

  if (config.type === "success") {
    src = SuccessIcon;
  }
  if (config.type === "warning") {
    src = WarningIcon;
  }
  if (config.type === "error") {
    src = ErrorIcon;
  }
  if (config.type === "cancel") {
    src = CancelIcon;
  }
  if (config.type === "pending") {
    src = PendingIcon;
  }

  notification.open({
    key: config.key || `${moment().unix()}`,
    icon: createElement("img", { src, style: { animation: config.type === 'pending' && 'loading-360 2s infinite linear'} }),
    message: config.message,
    description: config.description,
    className: `custom-notify ${config?.longMessage && 'long-message'}`,
    duration: config?.holding ? null : 4.5,
    onClick: config.onClick,
  });
};

(window as any).famliveNotify = notify;

// identicon
export const identiconGenerator = (hash: string) => {
  if (!hash) {
    return;
  }
  // 配置项
  let options = {
    size: 80,
    margin: 0.15,
    format: "svg",
    saturation: 0.5,
  };

  let base64 = new Identicon(hash, options).toString();

  let src = `data:image/svg+xml;base64,${base64}`;

  return src;
};

export const formatTime = (sec: number) => {
  if (!sec) {
    return "0s";
  }

  let str = "";
  let s = sec % 60;
  let m = Math.floor(sec / 60);
  let h = Math.floor(m / 60);

  if (h) {
    m = m % 60;
  }
  if (sec >= 3600) {
    str += `${h}h`;
  }
  if (sec >= 60) {
    str += `${m}m`;
  }

  return (str += `${s}s`);
};

export const base64ToFile = (base64: string, filename: string) => {
  let arr = base64.split(",");
  // @ts-ignore
  let mime = arr[0].match(/:(.*?);/)[1];
  let suffix = mime.split("/")[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  });
};

export const imgOnError = (e: any) => {
  e.target.src = NoImg;
};
