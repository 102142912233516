import { Button, Modal } from "antd";
import Twitter from "../../../assets/loginTwitter.png";
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import {
  connectTwitterHandler,
} from "../../../utils/account";
import "./index.less";

function LoginModal({
  visible,
  close,
}: {
  visible: boolean;
  close: () => void;
}) {
  return (
    <Modal
      className="login-modal"
      open={visible}
      onCancel={close}
      footer={false}
      centered
      closeIcon={<CloseIcon />}
    >
      <div className="login-title">Login</div>
      <div className="login-box">
        <div className="login-item">
          <img className="login-item-icon" src={Twitter} alt="" />
          <div className="login-item-title">Welcome to Famlive</div>
          <Button className="login-button" type="primary" onClick={connectTwitterHandler}>Login with Twitter</Button>
        </div>
      </div>
    </Modal>
  );
}
export default LoginModal;
