import { List } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getSpaceListApi } from "../../api";
import { ReactComponent as ChatGPT4 } from "../../assets/chat_gpt.svg";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import { ReactComponent as GPTIcon } from "../../assets/gpt_icon.svg";
import { ReactComponent as DateIcon } from "../../assets/calendar.svg";
import SpaceBg1 from "../../assets/landing/bg1.png";
import SpaceBg2 from "../../assets/landing/bg2.png";
import SpaceBg3 from "../../assets/landing/bg3.png";
import SpaceBg4 from "../../assets/landing/bg4.png";
import SpaceBg5 from "../../assets/landing/bg5.png";
import SpaceBg6 from "../../assets/landing/bg6.png";
import { imgOnError } from "../../utils";
import { useWindowSize } from "../../utils/useWindowSize";
import "./index.less";

const SpaceBgList = [
  SpaceBg1,
  SpaceBg2,
  SpaceBg3,
  SpaceBg4,
  SpaceBg5,
  SpaceBg6,
];

const PAGE_SIZE = 9;

function AISummary({ theme }: { theme: string }) {
  const [list, setList] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [currentSummary, setCurrentSummary] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { width } = useWindowSize();

  const { column, scrollWidth } = useMemo(() => {
    if (width > 1440) {
      return {
        column: 3,
        scrollWidth: "1380px",
      };
    }
    if (width < 1350 && width > 900) {
      return {
        column: 2,
        scrollWidth: "calc(100vw - 80px)",
      };
    }
    if (width < 900) {
      return {
        column: 1,
        scrollWidth: "calc(100vw - 40px)",
      };
    }
    return {
      column: 3,
      scrollWidth: "calc(100vw - 80px)",
    };
  }, [width]);

  const getSummaryList = useCallback(async () => {
    const res = await getSpaceListApi({
      page,
      size: PAGE_SIZE,
      summary_published: true,
    });
    setList(page === 1 ? res.data : [...list, ...res.data]);
    setHasMore(res.data.length === PAGE_SIZE);
    console.log(res.data.length === PAGE_SIZE);
  }, [page]);

  const openSummary = (e: any, summary: any) => {
    setCurrentSummary(summary);
    setOpen(true);
  };

  const formatText = (text: string) => {
    const sentences = text.split(".").filter((x) => !!x);
    let list: string[] = [];
    sentences.forEach((val) => {
      if (val.length === 1) {
        list[list.length - 1] = `${list[list.length - 1]}.${val}`;
        return;
      }
      list.push(val);
    });

    return list.map((s) => <p className="sentence">{s}.</p>);
  };

  useEffect(() => {
    getSummaryList();
  }, [getSummaryList]);

  const renderCard = (space: API.SpaceDTO, index: number) => {
    const host = space.users[0];
    const others = space.users.slice(1);
    const windowWidth =
      document.body.clientWidth > 1440 ? 1440 : document.body.clientWidth;
    const itemWidth =
      column === 1
        ? windowWidth - 40
        : (windowWidth - 96 - (column - 1) * 15) / column;

    return (
      <div
        className="summary-card"
        key={space.space}
        onClick={(e) => openSummary(e, space)}
        style={{ width: `${itemWidth}px` }}
      >
        <div
          className="card-top"
          style={{
            backgroundImage: `url(${SpaceBgList[index % SpaceBgList.length]})`,
          }}
        >
          <div className="gpt-icon">
            <GPTIcon />
          </div>
          <div className="title">{space.title}</div>
          <div className="speakers">
            <div className="host">
              <img
                className="host-avatar"
                src={host.profile_image_url}
                alt="host"
                onError={imgOnError}
              />
              <div className="host-name">
                <div className="name">{host.name}</div>
                <div className="twitter-id">@{host.username}</div>
              </div>
            </div>
            <div className="users">
              {others.map((u, i) => (
                <div className="user" key={i}>
                  <img
                    className="user-avatar"
                    src={u.profile_image_url}
                    alt="speaker"
                    onError={imgOnError}
                  ></img>
                </div>
              ))}
            </div>
            <div className="date">
              <DateIcon />
              {moment
                .unix(space.ended_at)
                .utc()
                .format("MMM D - HH:mm")
                .replace("-", "at")}
            </div>
          </div>
        </div>
        <div className="summary-text">
          {space.summary_en ||
            space.summary_zh ||
            space.summary_jp ||
            space.summary_kr}
        </div>
      </div>
    );
  };

  return (
    <div className="AI-summary">
      <div className="slogan">
        <span className="type1">Read</span>
        {" a space in 1 min "}
        <br />
        {"with the help of "}
        <span className="type2">OpenAI</span>
      </div>
      <div className="gpt">
        <div className="powered">Powered by</div>
        <ChatGPT4 />
      </div>
      <InfiniteScroll
        dataLength={list.length}
        next={() => setPage(page + 1)}
        hasMore={hasMore}
        style={{ width: scrollWidth, maxWidth: "1356px" }}
        loader={null}
        scrollableTarget="body"
      >
        <List
          className="home-list"
          rowKey={"space"}
          grid={{ gutter: 16, column: column }}
          dataSource={list}
          renderItem={(item, index) => renderCard(item, index)}
        />
      </InfiniteScroll>

      <Modal
        className="summary-modal"
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
        closable={false}
        centered
      >
        {currentSummary && (
          <div>
            <div className="summary-modal-header">
              <div className="title">
                <GPTIcon />
                <span>AI-GENERATED SUMMARY</span>
              </div>
              <CloseIcon className="close" onClick={() => setOpen(false)} />
            </div>
            <div className="summary-modal-content">
              <div className="space">
                <div className="space-title">{currentSummary.title}</div>
                <div className="gpt">
                  <ChatGPT4 />
                </div>
              </div>
              <div className="summary-text">
                {formatText(
                  currentSummary.summary_en ||
                    currentSummary.summary_zh ||
                    currentSummary.summary_jp ||
                    currentSummary.summary_kr
                )}
              </div>
              <div className="button">
                <span
                  className="open-space"
                  onClick={() => window.open(`/space/${currentSummary.space}`)}
                >
                  Space Detail
                </span>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default AISummary;
