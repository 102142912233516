import { Tag, Collapse, Modal, Button } from "antd";

import { useCallback, useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useParams } from "react-router-dom";
import {
  createFavoriteSpaceApi,
  deleteFavoriteSpaceApi,
  getInviteInfoApi,
  getSpaceInfoApi,
  getSpaceTranscribeStatus,
  getSpaceDetail,
  getMediaStream,
  TOKEN_KEY,
  getCaptionsByLocation,
} from "../../api";
import AskIcon from "../../assets/ask_20dp.svg";
import AskIconLight from "../../assets/ask_20dp_light.svg";
import ChatIcon from "../../assets/chat_20dp.svg";
import ChatIconLight from "../../assets/chat_20dp_light.svg";
import { ReactComponent as Collected } from "../../assets/collected.svg";
import RecordPlayer from "../RecordPlayer";

import EmptyDark from "../../assets/empty_img_dark.svg";
import EmptyLight from "../../assets/empty_img_light.svg";
import LiveAnimation from "../../assets/live_icon.json";
import { ReactComponent as OutLink } from "../../assets/out_link.svg";
import { ReactComponent as ShareIcon } from "../../assets/share_twitter.svg";
import SpeakerIcon from "../../assets/speaker_20dp.svg";
import SpeakerIconLight from "../../assets/speaker_20dp_light.svg";
import aiIcon from "../../assets/ai_20dp.png";
import { ReactComponent as UnCollect } from "../../assets/unCollect.svg";
import { ReactComponent as PlayButton } from "../../assets/play-button.svg";
import { ReactComponent as PauseButton } from "../../assets/pause.svg";
import { ReactComponent as SkipButton } from "../../assets/skip_to_live.svg";
import { ReactComponent as EnterSpace } from "../../assets/enter-space.svg";

import { Context } from "../../utils/context";
import Header from "../Components/Header";
import LoginModal from "../Components/Login";
import "./index.less";

import Plyr from "plyr";
import "plyr/dist/plyr.css";
import RedPacket from "../Components/RedPacket";

const { Panel } = Collapse;
type Captions = {
  jobName: string;
  accountId: string;
  results: {
    transcripts: {
      transcript: string;
    }[];
    items: {
      start_time: string;
      language_code: string;
      end_time: string;
      alternatives: {
        confidence: string;
        content: string;
      }[];
      type: string;
    }[];
  };
  status: string;
};
type summaryKey = "zh" | "en" | "jp" | "kr";

function EnterModal({
  visible,
  close,
}: {
  visible: boolean;
  close: () => void;
}) {
  return (
    <Modal
      className="enter-modal"
      open={visible}
      onCancel={close}
      footer={false}
      centered
    >
      <div className="enter-title">Welcome to Famlive</div>
      <div className="enter-image">
        <EnterSpace />
      </div>
      <div className="enter-text">Click to enter the space</div>
      <Button className="enter-button" type="primary" onClick={close}>
        enter space
      </Button>
    </Modal>
  );
}

function SpaceInfo({
  changeTheme,
  theme,
  loggedIn,
}: {
  changeTheme: (theme: string) => void;
  theme: string;
  loggedIn: boolean;
}) {
  const [activeTab] = useState<string>("spaces");
  const [spaceInfo, setSpaceInfo] = useState<API.SpaceDTO>();
  const [loginOpen, setLoginOpen] = useState<boolean>(false);
  const [hasCaption, setHasCaption] = useState(false);
  const [hasAudio, setHasAudio] = useState<string>("none");
  const [captionLocation, setCaptionLocation] = useState("");
  const [aiSummary, setAiSummary] = useState({
    en: "",
    zh: "",
    jp: "",
    kr: "",
  });
  const [currentKey, setCurrentKey] = useState<summaryKey>("en");
  const [captions, setCaptions] = useState<Captions>();
  const [activeKey, setActiveKey] = useState("3");
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [showPlayButton, setShowPlayButton] = useState<boolean>(false);
  const [showSkipButton, setShowSkipButton] = useState<boolean>(false);
  const [playButtonHovering, setPlayButtonHovering] = useState<boolean>(false);
  const [skipButtonHovering, setSkipButtonHovering] = useState<boolean>(false);
  const [hasLogged, setHaslogged] = useState(false);
  const [showEnterModal, setShowEnterModal] = useState(false);
  const searchParams = useParams();
  const spaceId = searchParams.id || null;
  (window as any).currentSpace = spaceId || "";
  const context = useContext(Context);
  const { userInfo } = context;
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const inviteCode = params.get("invite_code") || "";

  const checkLogin = () => {
    if (userInfo?.uid) {
      return true;
    }
    setLoginOpen(true);
    return false;
  };

  useEffect(() => {
    if (hasLogged) {
      const audioElement = (document.getElementById("audio-replay") ||
        document.getElementById("audio-replay")) as HTMLAudioElement;
      if (audioElement.duration && audioElement.currentTime > 0.1) {
        return;
      }
      const promise = audioElement.play();
      if (promise !== undefined) {
        promise
          .then((_) => {
            console.log("start play");
            // Autoplay started!
          })
          .catch((error) => {
            console.log(error);
            setShowEnterModal(true);
            // Autoplay was prevented.
            // Show a "Play" button so that user can start playback.
          });
      }
    }
  }, [hasLogged]);

  useEffect(() => {
    async function fetchCaptions() {
      if (!spaceId) return;
      const res = await getSpaceTranscribeStatus(spaceId);
      if (res.data.txt_url) {
        setCaptionLocation(res.data.txt_url);
        setHasCaption(true);
      }
      if (res.data) {
        setAiSummary({
          zh: res.data.summary_zh,
          jp: res.data.summary_jp,
          en: res.data.summary_en,
          kr: res.data.summary_kr,
        });
        if (res.data.summary_kr) {
          setCurrentKey("kr");
        }
        if (res.data.summary_jp) {
          setCurrentKey("jp");
        }
        if (res.data.summary_zh) {
          setCurrentKey("zh");
        }
        if (res.data.summary_en) {
          setCurrentKey("en");
        }
      }
    }
    fetchCaptions();
  }, [spaceId]);

  useEffect(() => {
    async function fetchSpaceDetail() {
      try {
        const res: any = await getSpaceDetail({
          space_id: spaceId || "",
        });
        const streamRes = await getMediaStream({
          space_id: spaceId || "",
          media_key: res.data.media_key,
        });
        const regex = /\?type=(\w+)/;
        const match = regex.exec(streamRes.data.stream_location);
        if (match !== null) {
          const type = match[1];
          if (type === "replay") {
            setHasAudio("replay");
          } else {
            setHasAudio("live");
            setShowPlayButton(true);
          }
        } else {
          setHasAudio("live");
        }
      } catch (error) {
        setHasAudio("none");
      }
    }

    fetchSpaceDetail();
  }, [spaceId]);

  useEffect(() => {
    async function fetchCaptions() {
      const captionRes = await getCaptionsByLocation(captionLocation);
      setCaptions(captionRes);
    }
    fetchCaptions();
  }, [captionLocation]);

  useEffect(() => {
    if (hasAudio === "replay") {
      const player = new Plyr(
        document.getElementById("audio-replay") as HTMLAudioElement
      );
    }
  }, [hasAudio]);

  const changeTab = (tab: string) => {
    if (tab === "spaces") {
      window.location.href = "/";
    }
    if (tab === "hosts") {
      window.location.href = "/hosts";
    }
    setLoginOpen(true);
    return false;
  };

  const getSpaceInfo = useCallback(async () => {
    if (!spaceId) {
      return;
    }
    const res = await getSpaceInfoApi({
      space_id: spaceId,
      invite_code: inviteCode,
    });
    setSpaceInfo(res.data);
    (window as any).currentChannel = res.data.channel_unique_label;
  }, [spaceId, inviteCode]);

  const collectSpace = async () => {
    if (!spaceInfo || !checkLogin()) {
      return;
    }
    const handler = spaceInfo.is_collect
      ? deleteFavoriteSpaceApi
      : createFavoriteSpaceApi;
    try {
      const res = await handler({ space_id: spaceInfo?.space });
      if (res.errno === 0) {
        getSpaceInfo();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const composeTweet = async () => {
    if (!spaceId || !checkLogin()) {
      return;
    }
    const res = await getInviteInfoApi({ space_id: spaceId });
    if (res.errno !== 0) {
      return;
    }
    const { text, url, hashtag } = res.data;
    const newUrl = `https://twitter.com/intent/tweet?text=${text}&hashtags=${hashtag}&url=${url}`;

    window.open(newUrl);
  };

  useEffect(() => {
    getSpaceInfo();
    setTimeout(() => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (!token) {
        setLoginOpen(true);
      } else setHaslogged(true);
    }, 100);
  }, [getSpaceInfo, spaceId]);

  useEffect(() => {
    // console.log(document.getElementById("chat_widget_speaker_container"),(window as any)?.renderFamChatWidget,'123123');
    (window as any)?.renderFamChatWidget?.(theme, {
      renderMode: "all",
      loggedIn,
    });
  }, [theme, loggedIn, hasCaption]);

  useEffect(() => {
    (window as any).isModalOpen = true;

    return () => {
      (window as any).isModalOpen = false;
    };
  }, []);

  const chatAndFaqContent = (
    <>
      <div className="middle-container">
        <div className="widget-header">
          <img
            className="icon"
            src={theme === "dark" ? ChatIcon : ChatIconLight}
            alt=""
          />
          CHAT
        </div>
        <div id="chat_widget_chat_container"></div>
        {!userInfo.uid && (
          <div className="placeholder-img">
            <img src={theme === "dark" ? EmptyDark : EmptyLight} alt="" />
            <div className="login-btn" onClick={() => setLoginOpen(true)}>
              Login to chat
            </div>
          </div>
        )}
      </div>
      <div className="right-container">
        <div className="widget-header">
          <img
            className="icon"
            src={theme === "dark" ? AskIcon : AskIconLight}
            alt=""
          />
          ASK
        </div>
        <div id="chat_widget_ask_container"></div>
        {!userInfo.uid && (
          <div className="placeholder-img">
            <img src={theme === "dark" ? EmptyDark : EmptyLight} alt="" />
            <div className="login-btn" onClick={() => setLoginOpen(true)}>
              Login to ask
            </div>
          </div>
        )}
      </div>
    </>
  );

  // const endSpaceContent = (
  //     <div className="record-container">
  //         <div className="record-header">
  //             <div>Recording</div>
  //         </div>
  //         {hasCaption && (
  //             <Replay
  //                 space_id={spaceId || ""}
  //                 captionLocation={captionLocation}
  //             />
  //         )}
  //     </div>
  // );

  const canReplay = spaceInfo && spaceInfo.state === "ended" && hasCaption;
  const renderSummaryLabel = () => {
    const keys: summaryKey[] = ["en", "zh", "jp", "kr"];
    return keys.map((key) => {
      if (aiSummary[key].length) {
        return (
          <div
            className="summary-key"
            onClick={() => {
              setCurrentKey(key);
            }}
            key={key}
          >
            {key}
          </div>
        );
      } else return <></>;
    });
  };

  return (
    <div className="space-container famlive_chat_widget_container">
      <Header
        theme={theme}
        changeTheme={changeTheme}
        activeTab={activeTab}
        // changeTab={changeTab}
        showSlogan={false}
      />
      <div className="space-info-container">
        <div className="left-container">
          {spaceInfo && (
            <div className={`space-info-chat-space ${spaceInfo.state}`}>
              <div className="state">
                {spaceInfo.state === "live" && (
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: LiveAnimation,
                    }}
                    width={20}
                    height={20}
                    style={{ margin: "0 3px" }}
                  />
                )}
                {spaceInfo.state.toUpperCase()}
                &nbsp;·&nbsp;
                <span className="hostname">
                  {spaceInfo.users[0] && spaceInfo.users[0].username}
                </span>
                <div
                  className="out-link"
                  onClick={() => window.open(spaceInfo.space_url)}
                >
                  <OutLink />
                </div>
              </div>
              <div className="title">{spaceInfo.title}</div>
              <div className="labels">
                {/* {spaceInfo.labels?.map((label) => (
                  <Tag className="label-tag" key={label}>
                    {label}
                  </Tag>
                ))} */}
              </div>

              <div className="labels">
                {showPlayButton && (
                  <div className="audio-control-btn-container">
                    {!isPlaying && (
                      <>
                        <div
                          className="audio-control-btn"
                          onClick={() => {
                            const element = document.getElementById(
                              "audio-replay"
                            ) as HTMLAudioElement;
                            element.play();
                            setIsPlaying(true);
                            setPlayButtonHovering(false);
                          }}
                          onMouseEnter={() => {
                            setPlayButtonHovering(true);
                          }}
                          onMouseLeave={() => {
                            setPlayButtonHovering(false);
                          }}
                        >
                          <PlayButton />
                          {playButtonHovering ? "Resume from pause" : ""}
                        </div>
                        <div
                          className="audio-control-btn"
                          onClick={() => {
                            const element = document.getElementById(
                              "audio-replay"
                            ) as HTMLAudioElement;
                            element.currentTime = element.duration;
                            element.play();
                            setIsPlaying(true);
                            setShowSkipButton(false);
                            setSkipButtonHovering(false);
                          }}
                          onMouseEnter={() => {
                            setSkipButtonHovering(true);
                          }}
                          onMouseLeave={() => {
                            setSkipButtonHovering(false);
                          }}
                        >
                          <SkipButton />
                          {skipButtonHovering ? "Skip to Live" : ""}
                        </div>
                      </>
                    )}
                    {isPlaying && (
                      <>
                        <div
                          className={`audio-control-btn ${
                            playButtonHovering ? "hovering" : ""
                          }`}
                          onClick={() => {
                            const element = document.getElementById(
                              "audio-replay"
                            ) as HTMLAudioElement;
                            element.pause();
                            setIsPlaying(false);
                            setPlayButtonHovering(false);
                          }}
                          onMouseEnter={() => {
                            setPlayButtonHovering(true);
                          }}
                          onMouseLeave={() => {
                            setPlayButtonHovering(false);
                          }}
                        >
                          <PauseButton />
                          {playButtonHovering ? "Pause" : ""}
                        </div>
                        {showSkipButton && (
                          <div
                            className="audio-control-btn"
                            onClick={() => {
                              const element = document.getElementById(
                                "audio-replay"
                              ) as HTMLAudioElement;
                              element.currentTime = element.duration;
                              element.play();
                              setIsPlaying(true);
                              setShowSkipButton(false);
                              setSkipButtonHovering(false);
                            }}
                            onMouseEnter={() => {
                              setSkipButtonHovering(true);
                            }}
                            onMouseLeave={() => {
                              setSkipButtonHovering(false);
                            }}
                          >
                            <SkipButton />
                            {skipButtonHovering ? "Skip to Live" : ""}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
                <div className="links">
                  <Tag className="btn-tag" onClick={composeTweet}>
                    <ShareIcon />
                  </Tag>
                  <Tag className="btn-tag" onClick={collectSpace}>
                    {spaceInfo.is_collect ? <Collected /> : <UnCollect />}
                  </Tag>
                </div>
              </div>
            </div>
          )}

          <audio id="audio-replay"></audio>
          <div
            className={`speaker ${
              hasAudio !== "none" && spaceInfo && spaceInfo.state === "ended"
                ? "record-button"
                : ""
            }`}
          >
            {spaceInfo?.state === "ended" && hasCaption && (
              <Collapse
                accordion
                activeKey={activeKey}
                onChange={(key) => {
                  setActiveKey(key ? key[0] : key);
                }}
              >
                {spaceInfo?.state === "ended" && hasCaption && (
                  <Panel
                    header={
                      <div>
                        <img
                          className="icon"
                          src={theme === "light" ? ChatIconLight : ChatIcon}
                          alt=""
                        />
                        Recording
                      </div>
                    }
                    key="1"
                    className="record-header"
                  >
                    {captions && (
                      <div className="record-container">
                        <RecordPlayer captions={captions} />
                      </div>
                    )}
                  </Panel>
                )}
                <Panel
                  header={
                    <div className="">
                      <img className="icon" src={aiIcon} alt="" />
                      AI-generated summary
                    </div>
                  }
                  key="2"
                >
                  <div className="ai-summary-lang-label">
                    {renderSummaryLabel()}
                  </div>
                  <div className="ai-summary">{aiSummary[currentKey]}</div>
                </Panel>
                <Panel
                  header={
                    <div className="">
                      <img
                        className="icon"
                        src={theme === "dark" ? SpeakerIcon : SpeakerIconLight}
                        alt=""
                      />
                      SPEAKER
                    </div>
                  }
                  key="3"
                  className="speaker-panel"
                >
                  <div id="chat_widget_speaker_container"></div>
                </Panel>
              </Collapse>
            )}
            {!hasCaption && (
              <>
                <div className="widget-header">
                  <img
                    className="icon"
                    src={theme === "dark" ? SpeakerIcon : SpeakerIconLight}
                    alt=""
                  />
                  SPEAKER
                </div>
                <div id="chat_widget_speaker_container"></div>
              </>
            )}
          </div>
        </div>
        {/* {spaceInfo &&
                    (spaceInfo.state === "ended" && hasCaption && openReplay
                        ? endSpaceContent
                        : chatAndFaqContent)} */}
        {chatAndFaqContent}
      </div>
      <LoginModal visible={loginOpen} close={() => setLoginOpen(false)} />
      <EnterModal
        visible={showEnterModal}
        close={() => {
          setShowEnterModal(false);
          const audioElement = (document.getElementById("audio-replay") ||
            document.getElementById("audio-replay")) as HTMLAudioElement;
          if (audioElement.duration && audioElement.currentTime > 0.1) {
            return;
          }
          const promise = audioElement.play();
          if (promise !== undefined) {
            promise
              .then((_) => {
                console.log("start play");
                // Autoplay started!
              })
              .catch((error) => {
                console.log(error);
                // Autoplay was prevented.
                // Show a "Play" button so that user can start playback.
              });
          }
        }}
      />
      <div id="famlive_chat_widget_container"></div>
      {spaceId && spaceInfo && (
        <RedPacket spaceId={spaceId} channel_id={spaceInfo.channel_id} />
      )}
    </div>
  );
}

export default SpaceInfo;
