import {
  AppstoreOutlined,
  EditOutlined,
  LeftOutlined,
  MenuOutlined,
  RightOutlined,
  TwitterOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { Button, Calendar, Col, Input, Modal, Row } from "antd";
import { CalendarMode } from "antd/lib/calendar/generateCalendar";
import CheckableTag from "antd/lib/tag/CheckableTag";
import "cropperjs/dist/cropper.css";
import type { Moment } from "moment";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import Cropper from "react-cropper";
import {
  getAccountInfoApi,
  getAWSConfigApi,
  getCreatedSpaceApi,
  getFavoriteSpacesApi,
  getListenedSpaceApi,
  getMonthlySpacesApi,
  getPersonalInfoApi,
  TOKEN_KEY,
  updateAccountInfoApi,
} from "../../api";
import DateIcon from "../../assets/calendar.svg";
import DateIconLight from "../../assets/calendar_light.svg";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import NoDataDark from "../../assets/NoData_dark.svg";
import NoDataLight from "../../assets/NoData_light.svg";
import NoImg from "../../assets/noImg.png";
import {
  base64ToFile,
  formatNumber,
  formatTime,
  identiconGenerator,
} from "../../utils";
import { Context } from "../../utils/context";
import { useWindowSize } from "../../utils/useWindowSize";
import Header from "../Components/Header";
import ListLoader from "../Components/ListLoader";
import RenderCard from "../Components/SpaceCard/card";
import "./index.less";

const imgOnError = (e: any) => {
  e.target.src = NoImg;
};

const PAGE_SIZE = 8;

const layoutTags = [
  { icon: <MenuOutlined style={{ fontSize: "22px" }} />, value: "list" },
  { icon: <AppstoreOutlined style={{ fontSize: "22px" }} />, value: "card" },
];

const stateTags = [
  { label: "Joined Spaces", value: "spaces" },
  { label: "My Favorites", value: "favorites" },
  { label: "Created Spaces", value: "created" },
];

const defaultParams = {
  twitter_user_id: "",
  state: "favorites",
};

function Profile({
  changeTheme,
  theme,
}: {
  changeTheme: (theme: string) => void;
  theme: string;
}) {
  const [activeTab] = useState<string>("profile");
  const [selectedState, setSelectState] = useState<string>(defaultParams.state);
  const [layout, setLayout] = useState<string>("list");
  const { width } = useWindowSize();
  const context = useContext(Context);
  const { userInfo } = context;
  const [livePage, setLivePage] = useState<number>(1);
  const [scheduledPage, setScheduledPage] = useState<number>(1);
  const [endedPage, setEndedPage] = useState<number>(1);
  const [listenedPage, setListenedPage] = useState<number>(1);
  const [createdPage, setCreatedPage] = useState<number>(1);
  const [liveList, setLiveList] = useState<API.SpaceDTO[]>([]);
  const [scheduledList, setScheduledList] = useState<API.SpaceDTO[]>([]);
  const [endedList, setEndedList] = useState<API.SpaceDTO[]>([]);
  const [listenedList, setListenedList] = useState<API.SpaceDTO[]>([]);
  const [createdList, setCreatedList] = useState<API.SpaceDTO[]>([]);
  const [moreLive, setMoreLive] = useState<boolean>(false);
  const [moreScheduled, setMoreScheduled] = useState<boolean>(false);
  const [moreEnded, setMoreEnded] = useState<boolean>(false);
  const [moreListened, setMoreListened] = useState<boolean>(false);
  const [moreCreated, setMoreCreated] = useState<boolean>(false);
  const [personalInfo, setPersonalInfo] = useState<API.PersonalInfo>();
  const [editNickOpen, setEditNickOpen] = useState<boolean>(false);
  const [editAvatarOpen, setEditAvatarOpen] = useState<boolean>(false);
  const [newNick, setNewNick] = useState<string>("");
  const [imageNeedCrop, setImageNeedCrop] = useState<string>("");
  const [cropper, setCropper] = useState<any>(null);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [dateEvents, setDateEvents] = useState<any[]>([]);
  const [monthEvents, setMonthEvents] = useState<API.SpaceDTO[][]>([]);
  const [currentMonth, setCurrentMonth] = useState<number>(moment().month());

  const { column } = useMemo(() => {
    if (width < 1301 && width > 1060) {
      return {
        column: 2,
        scrollWidth: "calc(100vw - 420px)",
      };
    }
    if (width < 1061 && width > 750) {
      return {
        column: 1,
        scrollWidth: "calc(100vw - 420px)",
      };
    }
    if (width < 751) {
      return {
        column: 1,
        scrollWidth: "calc(100vw - 40px)",
      };
    }
    return {
      column: 3,
      scrollWidth: "calc(100vw - 420px)",
    };
  }, [width]);

  const openHostTwitter = (e: any) => {
    e.stopPropagation();
    window.open(`https://twitter.com/${personalInfo?.twitter_username}`);
  };

  const openEtherscan = (e: any) => {
    e.stopPropagation();
    window.open(
      `https://etherscan.io/address/${personalInfo?.eth_wallet_address}`
    );
  };

  const handleStateChange = (tag: string, checked: boolean) => {
    if (tag === selectedState) {
      return;
    }
    setSelectState(tag);
    if (tag === "spaces") {
      getListenedSpace();
    }
    if (tag === "favorites") {
      getLiveSpace();
      getScheduledSpace();
      getEndedSpace();
    }
    if (tag === "created") {
      getCreatedSpace();
    }
  };

  const getPersonalInfo = async () => {
    const res = await getPersonalInfoApi();
    setPersonalInfo(res.data);
  };

  const getListenedSpace = async () => {
    const res = await getListenedSpaceApi({
      size: PAGE_SIZE,
      page: listenedPage,
    });
    setListenedList(
      listenedPage === 1 ? res.data : [...listenedList, ...res.data]
    );
    setMoreListened(res.data.length === PAGE_SIZE);
  };

  const getCreatedSpace = async () => {
    const res = await getCreatedSpaceApi({
      size: PAGE_SIZE,
      page: createdPage,
    });
    setCreatedList(
      createdPage === 1 ? res.data : [...createdList, ...res.data]
    );
    setMoreCreated(res.data.length === PAGE_SIZE);
  };

  const getLiveSpace = async () => {
    const res = await getFavoriteSpacesApi({
      state: "live",
      page: livePage,
      size: PAGE_SIZE,
    });
    setLiveList(livePage === 1 ? res.data : [...liveList, ...res.data]);
    setMoreLive(res.data?.length === PAGE_SIZE);
  };

  const getScheduledSpace = async () => {
    const res = await getFavoriteSpacesApi({
      state: "scheduled",
      page: scheduledPage,
      size: PAGE_SIZE,
    });
    setScheduledList(
      scheduledPage === 1 ? res.data : [...scheduledList, ...res.data]
    );
    setMoreScheduled(res.data?.length === PAGE_SIZE);
  };

  const getEndedSpace = async () => {
    const res = await getFavoriteSpacesApi({
      state: "ended",
      page: endedPage,
      size: PAGE_SIZE,
    });
    setEndedList(endedPage === 1 ? res.data : [...endedList, ...res.data]);
    setMoreEnded(res.data?.length === PAGE_SIZE);
  };

  const updateUserInfo = async (avatar?: string) => {
    if (!newNick && !avatar) {
      setEditNickOpen(false);
      setNewNick(userInfo.nick);
      return;
    }
    const token = localStorage.getItem(TOKEN_KEY) || "";
    const data: API.UpdateAccountBody = { token };
    if (newNick) data.nick = newNick;
    if (avatar) data.profile_icon = avatar;

    try {
      const res = await updateAccountInfoApi(data);
      if (res.errno === 0) {
        setEditNickOpen(false);
        const userInfo = await getAccountInfoApi(token);
        // update context
        context.setUserInfo(userInfo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadAvatarToS3 = async (image: any) => {
    setUploadLoading(true);
    const fileObj = base64ToFile(image, "");
    const res = await getAWSConfigApi();
    const s3 = new S3Client({
      region: res.region_id,
      credentials: {
        accessKeyId: res.access_key_id,
        secretAccessKey: res.secret_access_key,
        sessionToken: res.session_token,
      },
    });
    const filename = `${res.key}/${new Date().getTime()}${fileObj.name}`;

    const uploadParams = {
      ACL: "public-read",
      Bucket: res.bucket,
      Key: filename,
      Body: fileObj,
    };
    try {
      await s3.send(new PutObjectCommand(uploadParams));
      const payload = `https://${res.final_host}/${filename}`;
      updateUserInfo(payload);
      setEditAvatarOpen(false);
      setUploadLoading(false);
    } catch (error) {
      console.log(error);
      setEditAvatarOpen(false);
      setUploadLoading(false);
    }
  };

  const doCropImage = () => {
    const image = cropper.getCroppedCanvas().toDataURL();
    uploadAvatarToS3(image);
  };

  const getLocaleImage = () => {
    const el = document.createElement("input");
    el.type = "file";
    el.click();
    el.onchange = (e) => {
      const image = el.files?.[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImageNeedCrop(reader.result as string);
        setEditAvatarOpen(true);
      };
      image && reader.readAsDataURL(image);
    };
  };

  const selectDate = (date: Moment) => {
    setCurrentMonth(date.clone().month());
    setDateEvents(getDateEvents(date.clone().date()));
  };

  const renderSpaces = (
    spaceList: API.SpaceDTO[],
    updateHandler: (item: API.SpaceDTO) => void
  ) => {
    const windowWidth =
      document.body.clientWidth > 1440 ? 1440 : document.body.clientWidth;
    const itemWidth = (windowWidth - 422) / column;

    if (!spaceList || spaceList.length === 0) {
      return <ListLoader layout={layout} />;
    }

    if (layout === "list") {
      return spaceList.map((i) => (
        <RenderCard
          key={i.space}
          item={{ ...i, is_featured: false }}
          theme={theme}
          layout={windowWidth < 751 ? "card" : layout}
          updateItem={updateHandler}
        />
      ));
    }

    return (
      <Row>
        {spaceList.map((i: API.SpaceDTO) => {
          return (
            <Col
              style={{
                width: `${itemWidth}px`,
                padding: "0 8px",
              }}
              key={i.space}
            >
              <RenderCard
                item={{ ...i, is_featured: false }}
                theme={theme}
                layout={layout}
                updateItem={updateHandler}
              />
            </Col>
          );
        })}
      </Row>
    );
  };

  const getDateEvents = (date: number) => {
    if (monthEvents[date]) {
      return monthEvents[date];
    }
    return [];
  };

  const dateCellRender = (value: Moment) => {
    const listData =
      value.clone().month() === currentMonth ? getDateEvents(value.date()) : [];
    const collect = listData.find((item: any) => item.event === "Favored");
    const join = listData.find((item: any) => item.event === "Joined");
    const cellEvents = [];

    if (collect) {
      cellEvents.push(collect);
    }

    if (join) {
      cellEvents.push(join);
    }

    return (
      <div className="date-events">
        {cellEvents.map((item: any) => (
          <span className={`dot ${item.event}`} key={item.space}></span>
        ))}
      </div>
    );
  };

  const fetchMonthlySpaces = async (
    start: string,
    end: string,
    init?: boolean
  ) => {
    const res = await getMonthlySpacesApi({ start, end });
    if (res.errno === 0) {
      const { collect_space_list, joined_space_list } = res.data;
      const days = new Array(31).fill([]);
      joined_space_list.forEach((space) => {
        const day = moment.unix(space.started_at).date();
        days[day] = [...days[day], { ...space, event: "Joined" }];
      });
      collect_space_list.forEach((space) => {
        const day = moment
          .unix(space.scheduled_start || space.started_at)
          .date();
        const isSame = days[day].find(
          (item: any) => item.space === space.space
        );
        if (!isSame) {
          days[day] = [...days[day], { ...space, event: "Favored" }];
        }
      });
      setMonthEvents(days);
      if (init) {
        setDateEvents(days[moment().date()]);
      }
    }
  };

  const renderCalendarHeader = ({
    value,
    type,
    onChange,
    onTypeChange,
  }: {
    value: Moment;
    type: CalendarMode;
    onChange: (date: Moment) => void;
    onTypeChange: (type: CalendarMode) => void;
  }) => {
    const changeMonth = async (month: number) => {
      const newValue = value.clone().subtract(month, "month");
      await fetchMonthlySpaces(
        newValue.startOf("month").format("X"),
        newValue.endOf("month").format("X")
      );
      onChange(newValue);
      selectDate(newValue.startOf("month"));
    };

    return (
      <div className="custom-header">
        <div className="month">{value.format("MMMM")}</div>
        <div className="buttons">
          <div className="prev" onClick={() => changeMonth(1)}>
            <LeftOutlined />
          </div>
          <div className="next" onClick={() => changeMonth(-1)}>
            <RightOutlined />
          </div>
        </div>
      </div>
    );
  };

  const updateListenedItem = (item: API.SpaceDTO) => {
    setListenedList(
      listenedList.map((space) => (space.space === item.space ? item : space))
    );
  };

  const updateCreatedItem = (item: API.SpaceDTO) => {
    setListenedList(
      listenedList.map((space) => (space.space === item.space ? item : space))
    );
  };

  const updateItem = (item: API.SpaceDTO) => {
    if (item.state === "ended") {
      setEndedList(
        endedList.map((space) => (space.space === item.space ? item : space))
      );
      return;
    }
    if (item.state === "scheduled") {
      setScheduledList(
        scheduledList.map((space) =>
          space.space === item.space ? item : space
        )
      );
      return;
    }
    if (item.state === "live") {
      setLiveList(
        liveList.map((space) => (space.space === item.space ? item : space))
      );
      return;
    }
  };

  useEffect(() => {
    setNewNick(userInfo?.nick || "");
  }, [userInfo]);

  useEffect(() => {
    getListenedSpace();
  }, [listenedPage]);

  useEffect(() => {
    if (livePage !== 1) {
      getLiveSpace();
    }
  }, [livePage]);

  useEffect(() => {
    if (scheduledPage !== 1) {
      getScheduledSpace();
    }
  }, [scheduledPage]);

  useEffect(() => {
    if (endedPage !== 1) {
      getEndedSpace();
    }
  }, [endedPage]);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      window.location.href = "/";
      return;
    }
    // getListenedSpace();
    getPersonalInfo();
    getEndedSpace();
    getScheduledSpace();
    getLiveSpace();
    fetchMonthlySpaces(
      moment().startOf("month").format("X"),
      moment().endOf("month").format("X"),
      true
    );
  }, []);

  const address = personalInfo?.eth_wallet_address;
  const shortAddress = address
    ? address.slice(0, 6) + "..." + address.slice(-4)
    : null;

  return (
    <div className="home-container">
      <Header
        theme={theme}
        changeTheme={changeTheme}
        activeTab={activeTab}
        // changeTab={changeTab}
        showSlogan={false}
      />
      <div className="profile-container">
        {/* profile */}
        <div className="profile-cards">
          <div className="profile-card">
            <Context.Consumer>
              {({ userInfo }) => (
                <div className={`profile-card-top`}>
                  {/* avatar */}
                  <div className={"host-avatar"}>
                    <img
                      src={
                        userInfo?.profile_icon ||
                        identiconGenerator(userInfo?.addr)
                      }
                      alt=""
                      onError={imgOnError}
                    />
                    <div className="cover" onClick={() => getLocaleImage()}>
                      <EditOutlined />
                    </div>
                  </div>
                  {/* host info */}
                  <div className="info">
                    <div className="name" onClick={() => setEditNickOpen(true)}>
                      <span className="value">{userInfo.nick}</span>
                      <EditOutlined />
                    </div>
                    {personalInfo?.twitter_username && (
                      <div className="account" onClick={openHostTwitter}>
                        <TwitterOutlined />@{personalInfo.twitter_username}
                      </div>
                    )}
                    {shortAddress && (
                      <div className="account" onClick={openEtherscan}>
                        <WalletOutlined />
                        {shortAddress}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Context.Consumer>
            <div className="info-count">
              <div className="item">
                <div className="label">Total time listened</div>
                <div className="value">
                  {personalInfo?.total_time_listened &&
                    formatTime(personalInfo.total_time_listened)}
                </div>
              </div>
              <div className="item">
                <div className="label">Spaces joined (7D)</div>
                <div className="value">
                  {formatNumber(personalInfo?.space_7day)}
                </div>
              </div>
              <div className="item">
                <div className="label">Spaces joined(Total)</div>
                <div className="value">
                  {formatNumber(personalInfo?.space_total)}
                </div>
              </div>
            </div>
          </div>
          <div className="profile-calendar">
            <div className="title">
              <img
                className="icon"
                src={theme === "dark" ? DateIcon : DateIconLight}
                alt=""
              />
              My Calendar
            </div>
            <div className="calendar">
              <Calendar
                fullscreen={false}
                dateCellRender={dateCellRender}
                headerRender={renderCalendarHeader}
                onSelect={selectDate}
              />
            </div>
            {dateEvents.length > 0 && (
              <div className="events">
                {dateEvents.map((event, index) => (
                  <div
                    className="event-card"
                    key={event.space + index}
                    onClick={() => window.open(`/space/${event.space}`)}
                  >
                    <div className={`event-type ${event.event}`}>
                      {event.event}
                      <span className="dot"></span>
                    </div>
                    <div className="event-space">
                      <div className="avatar">
                        <img src={event.users[0]?.profile_image_url} alt="" />
                      </div>
                      <div className="info">
                        <div className="state">
                          {event.state.toUpperCase()} ·{" "}
                          {event.users[0]?.username}
                        </div>
                        <div className="title">{event.title}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {/* space list */}
        <div className="profile-space-list">
          <div className="filter">
            <div className="filter-top">
              <div className="state">
                {stateTags.map((tag) => (
                  <CheckableTag
                    className={"state-tag " + tag.value}
                    key={tag.value}
                    // checked={selectedState.indexOf(tag.value) > -1}
                    checked={selectedState === tag.value}
                    onChange={(checked) =>
                      handleStateChange(tag.value, checked)
                    }
                  >
                    {tag.label}
                  </CheckableTag>
                ))}
              </div>
              <div className="lang-layout">
                <div className="layout">
                  {layoutTags.map((item) => (
                    <CheckableTag
                      key={item.value}
                      className="state-tag layout-tag"
                      checked={layout === item.value}
                      onChange={(checked) => checked && setLayout(item.value)}
                    >
                      {item.icon}
                    </CheckableTag>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {selectedState === "favorites" && (
            <div className="space-list">
              {/* live */}
              {liveList?.length > 0 && (
                <div className="space-list">
                  <div className="search-sub-title">Live</div>
                  <div className="result">
                    {renderSpaces(liveList, updateItem)}
                  </div>
                  {moreLive && (
                    <div className="more">
                      <Button
                        className="load-more-btn"
                        onClick={() => setLivePage(livePage + 1)}
                      >
                        Load more
                      </Button>
                    </div>
                  )}
                </div>
              )}

              {/* scheduled */}
              {scheduledList?.length > 0 && (
                <div className="space-list">
                  <div className="search-sub-title">Scheduled</div>
                  <div className="result">
                    {renderSpaces(scheduledList, updateItem)}
                  </div>
                  {moreScheduled && (
                    <div className="more">
                      <Button
                        className="load-more-btn"
                        onClick={() => setScheduledPage(scheduledPage + 1)}
                      >
                        Load more
                      </Button>
                    </div>
                  )}
                </div>
              )}

              {/* ended */}
              {endedList?.length > 0 && (
                <div className="space-list">
                  <div className="search-sub-title">Ended</div>
                  <div className="result">
                    {renderSpaces(endedList, updateItem)}
                  </div>
                  {moreEnded && (
                    <div className="more">
                      <Button
                        className="load-more-btn"
                        onClick={() => setEndedPage(endedPage + 1)}
                      >
                        Load more
                      </Button>
                    </div>
                  )}
                </div>
              )}
              {!liveList?.length &&
                !scheduledList?.length &&
                !endedList?.length && (
                  <div className="no-data" style={{marginTop: '-20px'}}>
                    <img
                      src={theme === "dark" ? NoDataDark : NoDataLight}
                      alt=""
                    />
                    No data
                  </div>
                )}
            </div>
          )}

          {/* listened */}
          {selectedState === "spaces" &&
            (listenedList?.length > 0 ? (
              <div className="space-list">
                <div className="result">
                  {renderSpaces(listenedList, updateListenedItem)}
                </div>
                {moreListened && (
                  <div className="more">
                    <Button
                      className="load-more-btn"
                      onClick={() => setListenedPage(listenedPage + 1)}
                    >
                      Load more
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="no-data">
                <img src={theme === "dark" ? NoDataDark : NoDataLight} alt="" />
                No data
              </div>
            ))}

          {/* created */}
          {selectedState === "created" &&
            (createdList?.length > 0 ? (
              <div className="space-list">
                <div className="result">
                  {renderSpaces(createdList, updateCreatedItem)}
                </div>
                {moreCreated && (
                  <div className="more">
                    <Button
                      className="load-more-btn"
                      onClick={() => setCreatedPage(createdPage + 1)}
                    >
                      Load more
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="no-data">
                <img src={theme === "dark" ? NoDataDark : NoDataLight} alt="" />
                No data
              </div>
            ))}
        </div>
      </div>
      {/* edit nick */}
      <Modal
        className="nick-avatar-modal"
        open={editNickOpen}
        onCancel={() => setEditNickOpen(false)}
        footer={false}
        closeIcon={<CloseIcon />}
        width={350}
      >
        <div className="title">Edit nickname</div>
        <Input
          className="nick-input"
          value={newNick}
          onChange={(e) => setNewNick(e.target.value)}
        />
        <div className="btn">
          <Button
            className="confirm"
            type="primary"
            onClick={() => updateUserInfo()}
          >
            Confirm
          </Button>
        </div>
      </Modal>
      {/* edit avatar */}
      <Modal
        className="nick-avatar-modal"
        open={editAvatarOpen}
        onCancel={() => setEditAvatarOpen(false)}
        footer={false}
        closeIcon={<CloseIcon />}
        width={600}
      >
        <div className="title">Edit avatar</div>
        <div className="crop-avatar-wrap">
          <div className="content">
            <Cropper
              style={{ height: 350, width: "100%" }}
              zoomTo={0}
              initialAspectRatio={1}
              aspectRatio={1}
              preview=".img-preview"
              src={imageNeedCrop}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance: any) => {
                setCropper(instance);
              }}
              guides={true}
            />
          </div>
          <div className="preview">
            <div className="img-preview"></div>
            <div className="img-preview radius"></div>
          </div>
        </div>
        <div className="btn">
          <Button
            className="confirm"
            type="primary"
            onClick={doCropImage}
            loading={uploadLoading}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Profile;
