import { Button, Input, InputNumber, Modal, Select, message } from "antd";
import { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import { ReactComponent as ExpandIcon } from "../../../assets/balance/expand_more.svg";
import "./index.less";
import {
  getRedPacketByIdApi,
  getWalletBalanceApi,
  sendRedPacketApi,
  validateTwitterUserApi,
  receiveRedPacketApi,
} from "../../../api";
import DefaultTokenIcon from "../../../assets/balance/default_token.png";
import redPacketBg from "../../../assets/redPacketBg.svg";
import redPacketImg from "../../../assets/airdrop_illustration_big_1.svg";
import redPacketOverImg from "../../../assets/airdrop_illustration_big_2.svg";
import redPacketOver from "../../../assets/redPacketOver.svg";
import { isAddress } from "ethers";
import { notify } from "../../../utils";
import moment from "moment";

const PacketType = {
  common: "Identical amount",
  lucky: "Random amount",
};

function RedPacketInfo({ detail }: { detail: API.RedPacketDTO }) {
  const { receiver_list, asset_info } = detail;
  if (!receiver_list) return <></>;
  return (
    <div className="receiver-list">
      {receiver_list.map((receiver) => {
        return (
          <div className="red-packet-receiver">
            <img src={receiver.profile_icon} className="receiver-icon" />
            <div className="user-and-time">
              <div className="detail-user"> {receiver.nick}</div>
              <div className="time">
                {moment.unix(receiver.recv_time).format("YYYY/MM/DD")}
              </div>
            </div>
            <div className="user-amount">
              <div>
                <img src={detail?.asset_info.icon}></img>
                <span className="asset-symbol">{asset_info.symbol}</span>
              </div>
              <div className="amount">{receiver.amount}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default function RedPacket({
  spaceId,
  channel_id,
}: {
  spaceId: string;
  channel_id: number;
}) {
  const [sendVisible, setSendVisible] = useState<boolean>(false);
  const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const [currentRedPacketId, setCurrentRedPacketId] = useState(0);
  const [openRedPacketVisible, setOpenRedPacketVisible] =
    useState<boolean>(false);
  const [redPacketOverVisible, setRedPacketOverVisible] =
    useState<boolean>(false);
  const [uid, setUid] = useState(0);
  const [receiveVisible, setReceiveVisible] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>("lucky");
  const [sendAsset, setSendAsset] = useState<API.CoinBalance>();
  const [sendQuantity, setSendQuantity] = useState<number | null>();
  const [sendTotal, setSendTotal] = useState<number | null>();
  const [sendTitle, setSendTitle] = useState<string>("");
  const [balanceList, setBalanceList] = useState<API.CoinBalance[]>([]);
  const [detail, setDetail] = useState<API.RedPacketDTO>();
  const [inputError, setInputError] = useState<string>("");
  const [filteredBalanceList, setFilteredBalanceList] = useState<
    API.CoinBalance[]
  >([]);

  useEffect(() => {
    if (typeof sendQuantity === "number") {
      if (!(sendQuantity > 0)) {
        setInputError("quantity must be large than 0");
        return;
      }
      if (sendQuantity !== Math.round(sendQuantity)) {
        setInputError("quantity must be an integer");
        return;
      }
    }
    if (typeof sendTotal === "number") {
      if (sendTotal < 0) {
        setInputError("Red packet amount must be large than 0");
        return;
      }
      if (sendAsset?.balance) {
        if (Number(sendAsset.balance) < sendTotal) {
          setInputError("balance insufficient");
          return;
        }
      }
    }
    if (sendQuantity && sendTotal) {
      const average = sendTotal / sendQuantity;
      if (average < 0.01) setInputError("average must be large than 0.01");
    }
    if (sendTitle.length > 20) {
      setInputError("Memo should be less than 20 words");
      return;
    }
    setInputError("");
  }, [sendTotal, sendQuantity, sendAsset, sendTitle]);

  const fetchBalanceList = async () => {
    const res = await getWalletBalanceApi();
    const list = res.data;
    setBalanceList(list);
    setFilteredBalanceList(list);
    setSendAsset(list.filter((b) => !!Number(b.balance))[0]);
  };
  useEffect(() => {
    fetchBalanceList();
  }, [sendVisible]);

  const confirmSend = async () => {
    if (!sendAsset?.asset_id || !sendTotal || !sendQuantity || inputError) {
      return;
    }
    const data = {
      red_bag_type: selectedType,
      asset_id: sendAsset?.asset_id,
      red_bag_num: sendQuantity,
      total_amount: sendTotal,
      space_id: spaceId,
      timestamp: new Date().getTime(),
      text: sendTitle,
    };
    const res = await sendRedPacketApi(data);
    if (res.errno !== 0) {
      notify({
        message: "Warning",
        description: res.errmsg,
        type: "waning",
      });
      return;
    }
    setSendVisible(false);
  };

  const fetchRedPacket = async (data: API.RedPacketParams) => {
    const res = await getRedPacketByIdApi(data);
    if (res.errno === 0) {
      setDetail(res.data);
      setDetailVisible(true);
      return;
    }
  };
  const isRedPacketReceived = async (
    data: API.RedPacketParams,
    uid: number
  ) => {
    const res = await getRedPacketByIdApi(data);
    if (res.errno === 0) {
      setDetail(res.data);
      let isReceived;
      if (
        !res.data.receiver_list ||
        res.data.redbag_info.remaining_amount === 0
      )
        isReceived = false;
      else
        isReceived = res.data.receiver_list.find(
          (receiver) => receiver.uid === uid
        );
      setOpenRedPacketVisible(false);

      if (isReceived)
        // setOpenRedPacketVisible(true);
        setReceiveVisible(true);
      else setRedPacketOverVisible(true);
    }
  };

  useEffect(() => {
    fetchBalanceList();
    (window as any).famliveSendRedPacket = () => setSendVisible(true);
    (window as any).famliveReceiveDetail = (
      data: API.RedPacketParams,
      uid: number
    ) => {
      isRedPacketReceived(data, uid);
    };
    (window as any).famliveRedPacketDetail = (data?: API.RedPacketParams) => {
      fetchRedPacket(data || { redbag_id: 15, channel_id: 1001713212 });
    };
    (window as any).receiveRedPacket = async (
      channel_id: number,
      red_bag_id: number,
      timestamp: number
    ) => {
      try {
        const res = await receiveRedPacketApi({
          channel_id,
          red_bag_id,
          timestamp,
        });
        if (res.data) {
          isRedPacketReceived({ redbag_id: red_bag_id, channel_id }, uid);
          const redPacketReceiveStorage =
            localStorage.getItem("red_packet_storage");
          let redPacketReceiveList;
          if (!redPacketReceiveStorage)
            redPacketReceiveList = [] as API.RedPacketParams[];
          else
            redPacketReceiveList = JSON.parse(
              redPacketReceiveStorage
            ) as API.RedPacketParams[];
          if (
            redPacketReceiveList.find((redPacket) => {
              return (
                redPacket.channel_id === channel_id &&
                redPacket.redbag_id === red_bag_id
              );
            })
          ) {
            return;
          } else {
            redPacketReceiveList.push({ redbag_id: red_bag_id, channel_id });
            localStorage.setItem(
              "red_packet_storage",
              JSON.stringify(redPacketReceiveList)
            );
          }
        }
        // isRedPacketReceived({ redbag_id: red_bag_id, channel_id }, uid);
      } catch (error: any) {
        console.log(error);
        if (error?.response?.data?.errno !== 810017)
          notify({
            type: "error",
            message: "receive red packet failed",
            description: error?.response?.data?.errmsg || "request failed",
          });
        else {
          isRedPacketReceived({ redbag_id: red_bag_id, channel_id }, uid);
        }
      }
    };
    (window as any).showRedPacketOpenModal = () => {
      setOpenRedPacketVisible(true);
    };
    (window as any).showRedPacketOpenModal = () => {
      setOpenRedPacketVisible(true);
    };
    (window as any).setRedPacketId = (id: number) => {
      setCurrentRedPacketId(id);
    };
    (window as any).showRedPacketDetail = () => {
      setDetailVisible(true);
    };
    (window as any).setUid = (id: number) => setUid(id);
  }, [uid]);

  const userReceiveInfo = detail?.receiver_list?.find(
    (receiver) => receiver.uid === uid
  );

  return (
    <div className="red-packet">
      <Modal
        className="send-red-packet"
        open={sendVisible}
        onCancel={() => setSendVisible(false)}
        footer={false}
        centered
        closeIcon={<CloseIcon />}
        destroyOnClose
      >
        <div className="title">Send Red Packet</div>
        <div className="content">
          <div className="item">
            <span className="label">Red Packet Type</span>
            <Select
              options={Object.entries(PacketType).map(([value, label]) => ({
                value,
                label,
              }))}
              value={selectedType}
              onChange={(t) => setSelectedType(t)}
            ></Select>
          </div>

          <div className="item">
            <span className="label">Asset</span>
            <Select
              style={{ width: 346, height: 53 }}
              value={sendAsset?.asset_id}
              onChange={(value) =>
                setSendAsset(balanceList.find((c) => c.asset_id === value))
              }
              suffixIcon={<ExpandIcon />}
              options={balanceList
                .filter((b) => !!Number(b.balance))
                .map((c) => ({
                  value: c.asset_id,
                  label: (
                    <div className="coin-select-item">
                      <img
                        className="coin-icon"
                        src={c.asset_icon || DefaultTokenIcon}
                        alt=""
                      />
                      <span className="coin-name">
                        {c.asset_symbol.toUpperCase()}
                      </span>
                      <span className="amount">
                        ({Number(c.balance).toFixed(2)})
                      </span>
                    </div>
                  ),
                }))}
            />
          </div>
          <div className="item">
            <span className="label">Quantity</span>
            <InputNumber
              style={{ width: "346px" }}
              value={sendQuantity}
              onChange={(e) => setSendQuantity(e)}
              placeholder="Enter Number"
            ></InputNumber>
          </div>
          <div className="item">
            <span className="label">Amount</span>
            <InputNumber
              style={{ width: "270px" }}
              value={sendTotal}
              onChange={(e) => setSendTotal(e)}
              placeholder="Enter Number"
            ></InputNumber>
            <span> {sendAsset?.asset_symbol.toUpperCase()}</span>
          </div>
          {inputError.length > 0 && (
            <div className="item" style={{ color: "red" }}>
              {inputError}
            </div>
          )}
          <div className="item">
            <span className="label">Memo</span>
            <Input
              style={{ width: "346px" }}
              value={sendTitle}
              onChange={(e) => setSendTitle(e.target.value)}
              placeholder="Best wishes"
            ></Input>
          </div>
        </div>
        <div
          className="footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0 16px",
          }}
        >
          <Button className="back-button" onClick={() => setSendVisible(false)}>
            Back
          </Button>
          <Button
            type="primary"
            style={{
              background: "#7269EF",
              borderRadius: "8px",
              width: "220px",
              border: "none",
            }}
            onClick={confirmSend}
          >
            Send
          </Button>
        </div>
      </Modal>
      <Modal
        className="red-packet-detail"
        open={detailVisible}
        onCancel={() => setDetailVisible(false)}
        footer={false}
        centered
        closeIcon={<CloseIcon />}
        destroyOnClose
      >
        {detail && (
          <div className="title">
            Red Packet from{`  ${detail.sender_info.nick}`}
          </div>
        )}

        {detail && <RedPacketInfo detail={detail}></RedPacketInfo>}
        <div className="back-button-container">
          <Button
            className="back-button"
            onClick={() => setDetailVisible(false)}
          >
            Back
          </Button>
        </div>
      </Modal>
      <div className="red-packet-open">
        <Modal
          className="red-packet-open-modal"
          open={openRedPacketVisible}
          onCancel={() => setOpenRedPacketVisible(false)}
          footer={null}
        >
          <div className="red-packet-open-content">
            <img
              src={redPacketBg}
              style={{
                width: "520px",
              }}
            ></img>
            <img className="center-img" src={redPacketImg}></img>
            <Button
              className="open-button"
              onClick={() => {
                (window as any).receiveRedPacket(
                  channel_id,
                  currentRedPacketId,
                  Date.now()
                );
              }}
            >
              {"open"}
            </Button>
          </div>
        </Modal>
      </div>
      <div className="red-packet-receive-detail">
        <Modal
          className="red-packet-receive-detail-modal"
          open={receiveVisible}
          onCancel={() => setReceiveVisible(false)}
          footer={null}
        >
          <div className="red-packet-open-content">
            <img
              src={redPacketBg}
              style={{
                width: "520px",
              }}
            ></img>

            <div className="sender-info">
              <img
                className="sender-icon"
                src={detail?.sender_info.profile_icon}
              ></img>
              <div className="sender-nick">{detail?.sender_info.nick}</div>
              <div className="sender-text">
                {detail?.redbag_info.redbag_text}
              </div>
            </div>

            <div className="receive-info">
              <img src={detail?.asset_info.icon}></img>
              <div className="asset-symbol">{detail?.asset_info.symbol}</div>
            </div>
            {userReceiveInfo && (
              <div className="receive-amount">{`+${userReceiveInfo.amount}`}</div>
            )}
            <div className="footer">
              <Button
                className="back-button"
                onClick={() => {
                  setReceiveVisible(false);
                  setDetailVisible(true);
                }}
              >
                {"details"}
              </Button>
              <Button
                className="open-button"
                onClick={() => {
                  setReceiveVisible(false);
                  (window as any).openBalance();
                }}
              >
                {"view in my assets"}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          className="red-packet-over-modal"
          open={redPacketOverVisible}
          onCancel={() => setRedPacketOverVisible(false)}
          footer={null}
        >
          <div className="red-packet-over-content">
            <img
              src={redPacketOver}
              style={{
                width: "520px",
              }}
            ></img>
            <img className="red-packet-over" src={redPacketOverImg}></img>
            <div className="tip">Red Packet is over</div>
            <div className="footer">
              <Button
                className="back-button"
                onClick={() => {
                  setRedPacketOverVisible(false);
                  setDetailVisible(true);
                }}
              >
                {"details"}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
