import { TwitterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { useEffect, useMemo, useState } from "react";
import {
  claimAirdropApi,
  getAccountInfoApi,
  getAirdropEggBalanceApi,
  getGlobalConfigApi,
  getGlobalInfoApi,
  TOKEN_KEY,
  verifyTweetApi,
} from "../../api";
import EggEmpty from "../../assets/airdrop/EggBox/EggBox.png";
import EggOpening from "../../assets/airdrop/EggBox/EggBox.gif";
import BoxEmpty from "../../assets/airdrop/EggBox/box_empty.png";
import Egg1Dark from "../../assets/airdrop/PNG/Egg1-dark.png";
import Egg1Light from "../../assets/airdrop/PNG/Egg1-light.png";
import Egg2Dark from "../../assets/airdrop/PNG/Egg2-dark.png";
import Egg2Light from "../../assets/airdrop/PNG/Egg2-light.png";
import Egg3Dark from "../../assets/airdrop/PNG/Egg3-dark.png";
import Egg3Light from "../../assets/airdrop/PNG/Egg3-light.png";
import Egg4Dark from "../../assets/airdrop/PNG/Egg4-dark.png";
import Egg4Light from "../../assets/airdrop/PNG/Egg4-light.png";

import Egg1GifDark from "../../assets/airdrop/GIF/Egg1-dark.gif";
import Egg1GifLight from "../../assets/airdrop/GIF/Egg1-light.gif";
import Egg2GifDark from "../../assets/airdrop/GIF/Egg2-dark.gif";
import Egg2GifLight from "../../assets/airdrop/GIF/Egg2-light.gif";
import Egg3GifDark from "../../assets/airdrop/GIF/Egg3-dark.gif";
import Egg3GifLight from "../../assets/airdrop/GIF/Egg3-light.gif";
import Egg4GifDark from "../../assets/airdrop/GIF/Egg4-dark.gif";
import Egg4GifLight from "../../assets/airdrop/GIF/Egg4-light.gif";

import { ReactComponent as TweetIcon } from "../../assets/airdrop/tweet_24dp.svg";
import { ReactComponent as VerifyIcon } from "../../assets/airdrop/verified_24dp.svg";
import { ReactComponent as WalletIcon } from "../../assets/airdrop/wallet_24dp.svg";
import { notify } from "../../utils";
import { bindTwitterHandler, connectTwitterHandler } from "../../utils/account";
import Bus from "../../utils/eventBus";
import LoginModal from "../Components/Login";
import "./index.less";

const tabs = [
  { name: "Genesis Airdrop", value: 0 },
  { name: "Activation Airdrop", value: 1 },
];

const infoWords = {
  welcome: "Genesis Airdrop",
  activation: "Boost Airdrop",
  mission: "Only a Step to Get the Airdrop",
  desc: "Famlive is excited to announce an airdrop for our active users participating in Twitter Spaces. If you happened to miss our Genesis Airdrop, there's no need for concern. We've initiated our second wave of airdrops, the Boost Airdrop. You can accumulate points through various forms of participation in Twitter Spaces, such as hosting, listening, chatting, asking questions, or even inviting others. Your earned points will be a determinant in our Boost Airdrop, which will take place in our third season. Embrace this opportunity and start earning points today!",
};

const ClaimMission = [
  {
    key: "twitter",
    name: "Connect Twitter",
    icon: <TwitterOutlined />,
    complete: false,
  },
  {
    key: "eth",
    name: "Connect Wallet",
    icon: <WalletIcon />,
    complete: false,
  },
  {
    key: "tweet",
    name: "Post tweet about Famlive",
    icon: <TweetIcon />,
    complete: false,
  },
];

type UserState = "empty" | "mission" | "claimed";

const EggListDark = [Egg1Dark, Egg2Dark, Egg3Dark, Egg4Dark];
const EggListLight = [Egg1Light, Egg2Light, Egg3Light, Egg4Light];

const EggGifListDark = [Egg1GifDark, Egg2GifDark, Egg3GifDark, Egg4GifDark];
const EggGifListLight = [
  Egg1GifLight,
  Egg2GifLight,
  Egg3GifLight,
  Egg4GifLight,
];

function EggImage({ index, dark }: { index: number; dark: boolean }) {
  const png = dark ? EggListDark[index] : EggListLight[index];
  const gif = dark ? EggGifListDark[index] : EggGifListLight[index];
  const [src, setSrc] = useState(png);

  useEffect(() => {
    setSrc(dark ? EggListDark[index] : EggListLight[index]);
  }, [dark, index]);

  return (
    <img
      src={src}
      alt=""
      onMouseEnter={() => setSrc(gif)}
      onMouseLeave={() => setSrc(png)}
    />
  );
}

function Airdrop({ theme }: { theme: string }) {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [title, setTitle] = useState<string>(infoWords.welcome);
  const [userState, setUserState] = useState<UserState>("empty");
  const [loginOpen, setLoginOpen] = useState<boolean>(false);
  const [missionList, setMissionList] = useState(ClaimMission);
  const [postTweet, setPostTweet] = useState<boolean>(false);
  const [eggList, setEggList] = useState<API.EggDTO[]>([]);
  const [tweetText, setTweetText] = useState<string>("");
  const [userInfo, setUserInfo] = useState<API.UserInfo>();
  const [airdropState, setAirdropState] = useState<number>(1);

  const hasTwitter = userInfo?.extra?.twitter_username;

  const changeTab = (tab: number) => {
    setCurrentTab(tab);
    setTitle(tab === 0 ? infoWords.welcome : infoWords.activation);
  };

  const connectTwitter = () => {
    if (userInfo) {
      bindTwitterHandler();
    } else {
      connectTwitterHandler();
    }
  };

  const getEggBalance = async () => {
    const res = await getAirdropEggBalanceApi();
    if (res.errno !== 0) {
      return;
    }
    const balance = res.data.egg_balance;
    setEggList(
      eggList
        .map((egg) => ({
          ...egg,
          count: balance[egg.type],
        }))
        .filter((egg) => egg.count > 0)
    );
  };

  const claimAirdrop = async () => {
    if (!allVerified) {
      return;
    }
    try {
      const res = await claimAirdropApi();
      if (res.errno === 0) {
        getEggBalance();
        notify({
          type: "success",
          message: "Successfully Claimed",
          description: "You have successfully claimed your airdrop",
        });
        setAirdropState(3);
      }
    } catch (error: any) {
      notify({
        type: "warning",
        message: "Something Wrong",
        description: error.response?.data.errmsg,
      });
    }
  };

  const verifyTweet = async () => {
    const res = await verifyTweetApi();
    if (res.errno === 0 && res.data.verify) {
      setMissionList(
        missionList.map((m) => {
          if (m.key === "tweet") {
            return { ...m, complete: true };
          }
          return m;
        })
      );
    }
  };

  const postTweetHandler = () => {
    window.open(`https://twitter.com/intent/tweet?${tweetText}`);
    setPostTweet(true);
  };

  const missionClick = (mission: any) => {
    if (mission.key === "eth") {
      // bindWalletHandler(() => window.location.reload());
    }
    if (mission.key === "tweet") {
      postTweet ? verifyTweet() : postTweetHandler();
    }
  };

  const getConfig = async () => {
    const res = await getGlobalConfigApi();
    if (res.errno !== 0) {
      return;
    }
    setTweetText(res.data.airdrop_tweet_text);
    setEggList(res.data.egg_info);
  };

  const allVerified = useMemo(() => {
    let checker = true;

    missionList.forEach((m) => {
      if (!m.complete) {
        checker = false;
      }
    });

    return checker;
  }, [missionList]);

  const getUserInfo = async () => {
    try {
      const token = localStorage.getItem(TOKEN_KEY);
      const info = await getAccountInfoApi(token || "");
      if (info.uid) {
        setUserInfo(info);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProfile = async () => {
    try {
      const res = await getGlobalInfoApi();
      if (res.errno === 0) {
        setAirdropState(res.data.airdrop_state);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getConfig();
    getUserInfo();
    getProfile();

    const loginSuccess = () => {
      getUserInfo();
    };
    Bus.addListener("loginSuccess", loginSuccess);
    return () => {
      Bus.removeListener("loginSuccess", loginSuccess);
    };
  }, []);

  useEffect(() => {
    const loginOptions = userInfo?.extra?.login_options;
    if (!loginOptions) return;
    const platforms: string[] = [];
    loginOptions.forEach((option) => {
      platforms.push(option.platform);
    });
    setMissionList(
      missionList.map((m) => ({
        ...m,
        complete: platforms.includes(m.key),
      }))
    );
  }, [userInfo]);

  useEffect(() => {
    switch (airdropState) {
      case 1:
        setUserState("empty");
        setTitle(infoWords.welcome);
        break;
      case 2:
        setUserState("mission");
        setTitle(infoWords.mission);
        verifyTweet();
        break;
      case 3:
        setUserState("claimed");
        setTitle(infoWords.welcome);
        getEggBalance();
        break;

      default:
        break;
    }
    setUserState("empty");
  }, [airdropState]);

  return (
    <div id="airdrop">
      <div className="tabs">
        {tabs.map((tab) => (
          <CheckableTag
            className={"tab-item " + tab.value}
            key={tab.value}
            checked={currentTab === tab.value}
            onChange={() => changeTab(tab.value)}
          >
            {tab.name}
          </CheckableTag>
        ))}
      </div>
      <div className={"slogan type-" + currentTab}>
        Famlive
        <br />
        {title}
      </div>
      <div className="desc">{infoWords.desc}</div>
      <div className="container">
        {userState === "empty" && (
          <div className="empty">
            <img width={500} src={hasTwitter ? BoxEmpty : EggEmpty} alt="" />
            {hasTwitter ? (
              <Button onClick={() => changeTab(1)}>Boost Airdrop</Button>
            ) : (
              <Button onClick={connectTwitter}>Connect Twitter</Button>
            )}
          </div>
        )}
        {userState === "mission" && (
          <div className="mission">
            <img width={500} src={EggOpening} alt="" />
            <div className="mission-list">
              {missionList.map((mission) => (
                <div
                  className={"mission-card " + (mission.complete && "complete")}
                  key={mission.key}
                >
                  <div className="icon">{mission.icon}</div>
                  <div className="name">{mission.name}</div>
                  <div className="status">
                    {mission.complete ? (
                      <div className="completed">
                        <VerifyIcon />
                        Completed
                      </div>
                    ) : (
                      <div className="go" onClick={() => missionClick(mission)}>
                        {mission.key === "tweet" && postTweet
                          ? "Verify"
                          : "Go!"}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <Button
              className={`claim-btn ${!allVerified && "disabled"}`}
              onClick={claimAirdrop}
            >
              Claim Your Airdrop
            </Button>
          </div>
        )}
        {userState === "claimed" && (
          <div className="claimed">
            {eggList.map((egg, index) => (
              <div className="egg-card" key={egg.type}>
                <EggImage index={index} dark={theme === "dark"} />
                <div className="name">{egg.name}</div>
                <div className="count">x{egg.count || 0}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <LoginModal visible={loginOpen} close={() => setLoginOpen(false)} />
    </div>
  );
}

export default Airdrop;
