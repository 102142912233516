import { Button, Col, Row, Tag } from "antd";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  createFavoriteSpaceApi,
  deleteFavoriteSpaceApi,
  getHostSpaceListApi,
  getInviteInfoApi,
  getSpaceInfoApi,
  getSpaceListApi,
  TOKEN_KEY,
} from "../../api";
import DateIcon from "../../assets/calendar.svg";
import { ReactComponent as Collected } from "../../assets/collected.svg";
import Logo from "../../assets/logo.svg";
import NoImg from "../../assets/noImg.png";
import AskIcon from "../../assets/ask_20dp.svg";
import AskIconLight from "../../assets/ask_20dp_light.svg";
import { ReactComponent as OutLink } from "../../assets/out_link.svg";
import { ReactComponent as ShareIcon } from "../../assets/share_twitter.svg";
import { ReactComponent as UnCollect } from "../../assets/unCollect.svg";
import { ReactComponent as Arrow } from "../../assets/arrow_back.svg";
import { Context } from "../../utils/context";
import { useWindowSize } from "../../utils/useWindowSize";
import Header from "../Components/Header";
import ListLoader from "../Components/ListLoader";
import LoginModal from "../Components/Login";
import RenderCard from "../Components/SpaceCard/card";
import "./index.less";

const imgOnError = (e: any) => {
  e.target.src = NoImg;
};

function ScheduledSpaceInfo({
  changeTheme,
  theme,
}: {
  changeTheme: (theme: string) => void;
  theme: string;
}) {
  const [activeTab] = useState<string>("spaces");
  const [spaceInfo, setSpaceInfo] = useState<API.SpaceDTO>();
  const [loginOpen, setLoginOpen] = useState<boolean>(false);
  const [hostSpaceList, setHostSpaceList] = useState<API.SpaceDTO[]>([]);
  const [recommendSpaceList, setRecommendSpaceList] = useState<API.SpaceDTO[]>(
    []
  );
  const [host, setHost] = useState<string>("");
  const [labels, setLabels] = useState<string[]>([]);
  const [recommendPage, setRecommendPage] = useState<number>(1);
  const searchParams = useParams();
  const spaceId = searchParams.id || null;
  const context = useContext(Context);
  const { width } = useWindowSize();
  const { userInfo } = context;
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const inviteCode = params.get("invite_code") || '';

  const checkLogin = () => {
    if (userInfo?.uid) {
      return true;
    }
    setLoginOpen(true);
    return false;
  };

  const { column } = useMemo(() => {
    if (width < 1301 && width > 1060) {
      return {
        column: 3,
        scrollWidth: "calc(100vw - 80px)",
      };
    }
    if (width < 1061 && width > 750) {
      return {
        column: 2,
        scrollWidth: "calc(100vw - 80px)",
      };
    }
    if (width < 751) {
      return {
        column: 1,
        scrollWidth: "calc(100vw - 40px)",
      };
    }
    return {
      column: 4,
      scrollWidth: "calc(100vw - 80px)",
    };
  }, [width]);

  const getHostSpaces = useCallback(
    async (host: string) => {
      const res = await getHostSpaceListApi({
        page: 1,
        size: column > 1 ? column : 2,
        twitter_user_id: host,
        state: "ended",
      });
      setHostSpaceList(res.data);
    },
    [column]
  );

  const getRecommendSpaces = useCallback(
    async (labels: string[]) => {
      const res = await getSpaceListApi({
        page: recommendPage,
        size: column > 1 ? column : 2,
        label: labels.join(","),
      });
      setRecommendSpaceList(res.data);
    },
    [column, recommendPage]
  );

  const getSpaceInfo = useCallback(async () => {
    if (!spaceId) {
      return;
    }
    const res = await getSpaceInfoApi({ space_id: spaceId, invite_code: inviteCode });
    setSpaceInfo(res.data);
    // get host space
    const host = res.data.users[0].id;
    setHost(host);
    getHostSpaces(host);
    // get recommend space
    const labels = res.data.labels || [];
    setLabels(labels);
    getRecommendSpaces(labels);
  }, [getHostSpaces, getRecommendSpaces, spaceId, inviteCode]);

  const collectSpace = async () => {
    if (!spaceInfo || !checkLogin()) {
      return;
    }
    const handler = spaceInfo.is_collect
      ? deleteFavoriteSpaceApi
      : createFavoriteSpaceApi;
    try {
      const res = await handler({ space_id: spaceInfo?.space });
      if (res.errno === 0) {
        getSpaceInfo();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const composeTweet = async () => {
    if (!spaceId || !checkLogin()) {
      return;
    }
    const res = await getInviteInfoApi({ space_id: spaceId });
    if (res.errno !== 0) {
      return;
    }
    const { text, url, hashtag } = res.data;
    const newUrl = `https://twitter.com/intent/tweet?text=${text}&hashtags=${hashtag}&url=${url}`;

    window.open(newUrl);
  };

  const updateItem = (item: API.SpaceDTO) => {
    setHostSpaceList(
      hostSpaceList.map((space) => (space.space === item.space ? item : space))
    );
    setRecommendSpaceList(
      recommendSpaceList.map((space) =>
        space.space === item.space ? item : space
      )
    );
  };

  const renderSpaces = (spaceList: API.SpaceDTO[]) => {
    const windowWidth =
      document.body.clientWidth > 1440 ? 1440 : document.body.clientWidth;
    const itemWidth =
      column === 1 ? (windowWidth - 40) / column : (windowWidth - 80) / column;

    if (!spaceList || spaceList.length === 0) {
      return <ListLoader layout={"card"} />;
    }

    return (
      <Row>
        {spaceList.map((i: API.SpaceDTO) => {
          return (
            <Col
              style={{
                width: `${itemWidth}px`,
                padding: "0 8px",
                marginBottom: column === 1 ? "10px" : "0px",
              }}
              key={i.space}
            >
              <RenderCard
                item={{ ...i, is_featured: false }}
                theme={theme}
                layout={"card"}
                updateItem={updateItem}
              />
            </Col>
          );
        })}
      </Row>
    );
  };

  useEffect(() => {
    getSpaceInfo();
    setTimeout(() => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (!token) {
        setLoginOpen(true);
      }
    }, 100);
  }, [getSpaceInfo, spaceId]);

  useEffect(() => {
    if (spaceInfo) {
      setTimeout(() => {
        (window as any)?.renderFamChatWidget(theme, {
          renderMode: "all",
          loggedIn: !!localStorage.getItem(TOKEN_KEY),
          featuredFaq: true,
        });
        (window as any).isModalOpen = true;
        (window as any).currentChannel = spaceInfo.channel_unique_label;
      }, 0);
    }
  }, [spaceInfo, theme]);

  return (
    <div className="space-container famlive_chat_widget_container">
      <Header
        theme={theme}
        changeTheme={changeTheme}
        activeTab={activeTab}
        // changeTab={changeTab}
        showSlogan={false}
      />
      <div className="scheduled-space-info-container">
        <div className="space-box">
          <div className={`space-info-chat-space scheduled`}>
            <div className="logo">
              <img src={Logo} alt="" />
            </div>
            <div className="avatar">
              <img
                src={spaceInfo?.users[0].profile_image_url}
                alt=""
                onError={imgOnError}
              />
            </div>
            <div className="state">
              {spaceInfo?.state.toUpperCase()}
              &nbsp;·&nbsp;
              <span className="hostname">
                {spaceInfo?.users[0] && spaceInfo.users[0].username}
              </span>
            </div>
            <div className="title">{spaceInfo?.title}</div>
            <div className="labels">
              {spaceInfo?.labels?.map((label) => (
                <Tag className="label-tag" key={label}>
                  {label}
                </Tag>
              ))}
            </div>
            <div className="time">
              <img src={DateIcon} alt="" />
              <span>
                {spaceInfo?.scheduled_start
                  ? moment
                      .unix(spaceInfo.scheduled_start)
                      .utc()
                      .format("MMM D - HH:mm")
                      .replace("-", "at")
                  : "-"}{" "}
                (UTC)
              </span>
            </div>
            {/* <div className="labels">
            <Tag className="btn-tag" onClick={composeTweet}>
              <ShareIcon />
            </Tag>
            <Tag className="btn-tag" onClick={collectSpace}>
              {spaceInfo?.is_collect ? <Collected /> : <UnCollect />}
            </Tag>
          </div> */}
          </div>
          <div className="count-down">
            <div className="buttons">
              <div
                className="button"
                onClick={() => window.open(spaceInfo?.space_url)}
              >
                <span className="icon">
                  <OutLink />
                </span>
                <span className="label">Set reminder</span>
              </div>
              <div className="button" onClick={composeTweet}>
                <span className="icon">
                  <ShareIcon />
                </span>
                <span className="label">Share space</span>
              </div>
              <div className="button" onClick={collectSpace}>
                <span className="icon collect">
                  {spaceInfo?.is_collect ? <Collected /> : <UnCollect />}
                </span>
                <span className="label">Save as favorite</span>
              </div>
            </div>
          </div>
        </div>
        <div className="faq">
          <div className="widget-header">
            <img
              className="icon"
              src={theme === "dark" ? AskIcon : AskIconLight}
              alt=""
            />
            ASK
          </div>
          <div id="chat_widget_ask_container"></div>
        </div>
      </div>
      <div className="scheduled-space-list">
        <div className="subtitle">
          <span className="title">SPACES OF THE HOST</span>
          <Button
            className="explore-more"
            onClick={() => window.open(`/host/${host}`)}
          >
            Explore more
          </Button>
        </div>
        <div className="space-list">{renderSpaces(hostSpaceList)}</div>
      </div>
      <div className="scheduled-space-list">
        <div className="subtitle">
          <span className="title">RECOMMENDED SPACES</span>
          <span className="btn">
            <Button
              className="pager"
              disabled={recommendPage === 1}
              onClick={() =>
                recommendPage > 1 && setRecommendPage(recommendPage - 1)
              }
            >
              <Arrow />
            </Button>
            <Button
              className="pager right"
              onClick={() => setRecommendPage(recommendPage + 1)}
            >
              <Arrow />
            </Button>
          </span>
        </div>
        <div className="space-list">{renderSpaces(recommendSpaceList)}</div>
      </div>
      <LoginModal visible={loginOpen} close={() => setLoginOpen(false)} />
      <div id="famlive_chat_widget_container"></div>
    </div>
  );
}

export default ScheduledSpaceInfo;
