import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { List, Tag, Tooltip } from "antd";
import moment from "moment";
import { useContext, useState } from "react";
import Lottie from "react-lottie";
import {
  createFavoriteSpaceApi,
  deleteFavoriteSpaceApi,
  getInviteInfoApi,
  TOKEN_KEY,
  userOperateApi,
} from "../../../api";
import { ReactComponent as Collected } from "../../../assets/collected.svg";
import LiveAnimation from "../../../assets/live_icon.json";
import LiveRing from "../../../assets/live_ring.json";
import LiveRingDark from "../../../assets/live_ring_dark.json";
import NoImg from "../../../assets/noImg.png";
import { ReactComponent as ShareIcon } from "../../../assets/share_twitter.svg";
import { ReactComponent as UnCollect } from "../../../assets/unCollect.svg";
import { notify } from "../../../utils";
import { Context } from "../../../utils/context";
import "./card.less";

const imgOnError = (e: any) => {
  e.target.src = NoImg;
};

function RenderCard({
  item,
  layout,
  theme,
  hideHost,
  openChat,
  updateItem,
  short,
}: {
  item: API.SpaceDTO;
  layout: string;
  theme: string;
  hideHost?: boolean;
  openChat?: (space: API.SpaceDTO) => void;
  updateItem?: (space: API.SpaceDTO) => void;
  short?: boolean;
}) {
  const [followed, setFollowed] = useState<boolean>(!!item.is_follow);
  const context = useContext(Context);
  const { userInfo } = context;

  const host =
    item.users && item.users[0] ? item.users[0] : ({} as API.SpaceUserDTO);
  const others = item.users ? item.users.slice(1) : [];

  const countNumber =
    item.participant_count - item.users?.length > 0
      ? item.participant_count - item.users?.length
      : 0;

  const openSpacePage = async (e?: any) => {
    e?.stopPropagation();
    if (item.state === "live" && openChat) {
      openChat(item);
      return;
    }
    if (item.state === "scheduled") {
      window.open(`/scheduled/${item.space}`);
      return;
    }
    window.open(`/space/${item.space}`);
    // localStorage.setItem("current_channel", item.channel_unique_label);
    (window as any).currentChannel = item.channel_unique_label;
    // localStorage.setItem("current_space", item.space);
    (window as any).currentSpace = item.space;
  };

  const openHost = (e?: any) => {
    e?.stopPropagation();
    window.open(`/host/${host.id}`);
  };

  const followHost = async (e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      (window as any).famliveLogin();
      return;
    }
    if (item.is_follow) {
      return;
    }
    try {
      await userOperateApi({
        twitter_user_id: host.id,
        operate: item.is_follow ? "unfollow" : "follow",
      });
      setFollowed(!item.is_follow);
    } catch (error) {
      notify({
        type: "warning",
        message: "Followed error",
        description: "Please try again later",
      });
    }
  };

  const checkLogin = () => {
    if (userInfo?.uid) {
      return true;
    }
    (window as any)?.famliveLogin();
    return false;
  };

  const composeTweet = async (e: any) => {
    e?.stopPropagation();
    if (!checkLogin()) {
      return;
    }
    const res = await getInviteInfoApi({ space_id: item.space });
    if (res.errno !== 0) {
      return;
    }
    const { text, url, hashtag } = res.data;
    const newUrl = `https://twitter.com/intent/tweet?text=${text}&hashtags=${hashtag}&url=${url}`;

    window.open(newUrl);
  };

  const collectSpace = async (e: any) => {
    e?.stopPropagation();
    if (!checkLogin()) {
      return;
    }
    const handler = item.is_collect
      ? deleteFavoriteSpaceApi
      : createFavoriteSpaceApi;
    try {
      const res = await handler({ space_id: item.space });
      if (res.errno === 0) {
        updateItem && updateItem({ ...item, is_collect: !item.is_collect });
      }
    } catch (error: any) {
      console.log(error);
      notify({
        type: "warning",
        message: "Collect error",
        description: error.response?.data.errmsg || error.message,
      });
    }
  };

  // list
  if (layout === "list") {
    return (
      <List.Item className="list-item" key={item.space} onClick={openSpacePage}>
        <div className="avatar-wrap">
          <div className={`host-avatar ${item.state}`} onClick={openHost}>
            <img src={host.profile_image_url} alt="" onError={imgOnError} />
            {item.state === "live" && (
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: theme === "dark" ? LiveRingDark : LiveRing,
                }}
                width={88}
                height={88}
              />
            )}
          </div>
          {item.is_featured && <span className="featured-icon">🔥</span>}
        </div>

        <div className={`center ${short && 'short'}`}>
          <div className="state">
            <span className="state-info">
              {item.state.toUpperCase()}
              &nbsp;·&nbsp;
            </span>

            <span className="hostname">{host.username}</span>
            {short ? (
              <>
                {item.state === "live" && (
                  <div className="count">
                    +{countNumber}
                    {" Listening"}
                  </div>
                )}
              </>
            ) : (
              <div className="labels">
                {item.labels?.map((label) => (
                  <Tag className="label-tag" key={label}>
                    {label}
                  </Tag>
                ))}
              </div>
            )}
          </div>
          <div className="title">{item.title}</div>
        </div>
        {!short && (
          <>
            <div className="users">
              {others.map((u, i) => (
                <div className="user" key={i}>
                  <img
                    className="user-avatar"
                    src={u.profile_image_url}
                    alt="user"
                    onError={imgOnError}
                  ></img>
                </div>
              ))}
            </div>
            {item.state === "scheduled" && item.scheduled_start && (
              <div className="time">
                {moment
                  .unix(item.scheduled_start)
                  .utc()
                  .format("MMM D - HH:mm")
                  .replace("-", "at")}{" "}
              </div>
            )}
            {countNumber > 0 && (
              <div className="count">
                +{countNumber}{" "}
                {item.state === "live" ? (
                  "Listening"
                ) : (
                  <>
                    Others
                    {/* <br />
                Joined */}
                  </>
                )}
              </div>
            )}
            <div className="buttons">
              <Tag className="btn-tag" onClick={composeTweet}>
                <ShareIcon />
              </Tag>
              <Tag className="btn-tag" onClick={collectSpace}>
                {item.is_collect ? <Collected /> : <UnCollect />}
              </Tag>
            </div>
          </>
        )}
      </List.Item>
    );
  }
  // card
  return (
    <List.Item key={item.space}>
      <div className="space-info">
        <div className={`card-top ${item.state}`} onClick={openSpacePage}>
          <div className="state">
            {item.state === "live" && (
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: LiveAnimation,
                }}
                width={20}
                height={20}
                style={{ margin: "0 3px" }}
              />
            )}
            <span className="state-name">{item.state.toUpperCase()}</span>
            &nbsp;·&nbsp;
            <span className="hostname">{host.username}</span>
            {item.is_featured && <span className="featured-icon">🔥</span>}
          </div>
          <div className="title">{item.title}</div>
          <div className="labels">
            {item.labels?.map((label, index) => (
              <span key={label}>
                <Tag className="card-label-tag">{label}</Tag>
                {index === 2 && <br />}
              </span>
            ))}
          </div>
          {!hideHost && (
            <div className={"host-avatar " + item.state} onClick={openHost}>
              {item.state === "live" && (
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: theme === "dark" ? LiveRingDark : LiveRing,
                  }}
                  width={78}
                  height={78}
                />
              )}
              <img src={host.profile_image_url} alt="" onError={imgOnError} />
              <div
                className={`follow-state ${followed && "followed"}`}
                onClick={followHost}
              >
                {followed ? (
                  <CheckOutlined />
                ) : (
                  <Tooltip
                    title="Follow"
                    placement="bottom"
                    color={theme === "dark" ? "#fff" : "#434343"}
                  >
                    <PlusOutlined />
                  </Tooltip>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="card-bottom">
          {item.state === "scheduled" && item.scheduled_start && (
            <div className="time">
              {moment
                .unix(item.scheduled_start)
                .utc()
                .format("MMM D - HH:mm")
                .replace("-", "at")}{" "}
            </div>
          )}
          <div className="users">
            {others.map((u, i) => (
              <div className="user" key={i}>
                <img
                  className="user-avatar"
                  src={u.profile_image_url}
                  alt="user"
                  onError={imgOnError}
                ></img>
              </div>
            ))}
          </div>
          {countNumber > 0 && (
            <div className="count">
              +{countNumber}{" "}
              {item.state === "live" ? (
                "Listening"
              ) : (
                <>
                  Others
                  {/* <br />
                  Joined */}
                </>
              )}
            </div>
          )}
          <div className="buttons">
            <Tag className="btn-tag" onClick={composeTweet}>
              <ShareIcon />
            </Tag>
            <Tag className="btn-tag" onClick={collectSpace}>
              {item.is_collect ? <Collected /> : <UnCollect />}
            </Tag>
          </div>
        </div>
      </div>
    </List.Item>
  );
}

export default RenderCard;
