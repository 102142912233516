import Header from "../Components/Header";
import "./index.less";

function Terms({
  theme,
  changeTheme,
}: {
  theme: string;
  changeTheme: (theme: string) => void;
}) {
  return (
    <div className="home-container">
      <Header theme={theme} changeTheme={changeTheme} activeTab={""} />
      <div id="terms">
        <div className="title">Famlive Website Terms of Service</div>
        <div className="info">
          Please read these Terms of Service carefully before using the Famlive
          website operated by us. Your access to and use of the Service is
          conditioned upon your acceptance of and compliance with these Terms.
          These Terms apply to all visitors, users, and others who wish to
          access or use the Service. By accessing or using the Service, you
          agree to be bound by these Terms. If you disagree with any part of the
          Terms, then you do not have permission to access the Service.
        </div>
        <div className="subtitle">1. Overview of the Service</div>
        <div className="desc">
          Famlive is an aggregator of Twitter Spaces, allowing users to
          discover, access, and participate in conversations on various topics.
          Users can log in to our Service using their Twitter accounts or
          MetaMask wallets.
        </div>
        <div className="subtitle">2. Registration and Account Security</div>
        <div className="desc">
          By logging in to the Service, you represent and warrant that:
          <div className="desc">
            a. You are at least 13 years of age.
            <br />
            b. You will provide accurate and complete information when creating
            an account and will keep it updated.
            <br />
            c. You will not use the Service for any illegal or unauthorized
            purpose, including but not limited to copyright infringement,
            harassment, or defamation.
          </div>
        </div>
        <div className="subtitle">
          3. Privacy Policy Your privacy is important to us.
        </div>
        <div className="desc">
          Please read our Privacy Policy to understand how we collect, use, and
          share your personal information when you access or use the Service.
        </div>
        <div className="subtitle">4. Intellectual Property</div>
        <div className="desc">
          All content on the Service, including but not limited to text,
          graphics, logos, and software, is the property of Famlive or its
          content suppliers and is protected by international copyright,
          trademark, and other intellectual property laws. Unauthorized use of
          the content may result in a violation of these laws.
        </div>
        <div className="subtitle">5. Third-Party Services</div>
        <div className="desc">
          Our Service aggregates content from Twitter Spaces and allows you to
          log in using your Twitter account or MetaMask wallet. We are not
          responsible for the content, policies, or practices of these
          third-party services. By using our Service, you agree to comply with
          the terms and conditions of these third-party services.
        </div>
        <div className="subtitle">6. User Conduct</div>
        <div className="desc">
          You agree not to use the Service to:
          <div className="desc">
            a. Impersonate any person or entity, or falsely state or otherwise
            misrepresent your affiliation with a person or entity.
            <br />
            b. Engage in any activity that infringes upon the rights of others,
            including but not limited to copyright, trademark, privacy, or other
            intellectual property rights.
            <br />
            c. Transmit any unsolicited or unauthorized advertising, promotional
            materials, junk mail, spam, or any other form of solicitation.
            <br />
            d. Intentionally or unintentionally violate any applicable local,
            state, national, or international law or regulation.
            <br />
          </div>
        </div>
        <div className="subtitle">7. Termination</div>
        <div className="desc">
          We may terminate or suspend your access to the Service immediately,
          without prior notice or liability, if you breach the Terms. Upon
          termination, your right to use the Service will cease immediately.
        </div>
        <div className="subtitle">8. Changes to the Terms</div>
        <div className="desc">
          We reserve the right to modify or replace these Terms at any time. We
          will notify you of any changes by posting the new Terms on this page.
          By continuing to access or use our Service after any revisions become
          effective, you agree to be bound by the revised Terms.
        </div>
        <div className="subtitle">9. Governing Law</div>
        <div className="desc">
          These Terms shall be governed and construed in accordance with the
          laws of Singapore, without regard to its conflict of law provisions.
        </div>
        <div className="subtitle">10. Contact Us</div>
        <div className="desc">
          If you have any questions about these Terms, please contact us at
          contact @famlive.io.
        </div>
      </div>
    </div>
  );
}

export default Terms;
