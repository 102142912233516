import {
  AppstoreOutlined,
  MenuOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Badge, Button, Col, Input, List, Row, Switch } from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  TOKEN_KEY,
  featuredHostListApi,
  followedHostListApi,
  searchHostApi,
} from "../../api/index";
import NoDataDark from "../../assets/NoData_dark.svg";
import NoDataLight from "../../assets/NoData_light.svg";
import Bus from "../../utils/eventBus";
import { useWindowSize } from "../../utils/useWindowSize";
import RenderCard from "../Components/HostCard/card";
import ListLoader from "../Components/ListLoader";
import "./index.less";

const PAGE_SIZE = 12;

const stateTags = [
  { label: "Featured", value: "featured" },
  { label: "Followed", value: "followed" },
];

const layoutTags = [
  { icon: <MenuOutlined style={{ fontSize: "22px" }} />, value: "list" },
  { icon: <AppstoreOutlined style={{ fontSize: "22px" }} />, value: "card" },
];

function Hosts({
  theme,
  hasLive,
  closeHasLive,
}: {
  theme: string;
  hasLive: boolean;
  closeHasLive: () => void;
}) {
  const [list, setList] = useState<API.HostDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [params, setParams] = useState<API.HostListParam>({
    page: 1,
    size: PAGE_SIZE,
    order: "spaceTotal",
  });
  const [selectedState, setSelectState] = useState<string>("featured");
  const [onlyLiveFollowed, setOnlyLiveFollowed] = useState<boolean>(false);
  const [layout, setLayout] = useState<string>("list");
  const { width } = useWindowSize();
  const [hasMoreFeatured, setHasMoreFeatured] = useState({
    space: true,
    listener: true,
    fans: true,
  });
  const [spacePage, setSpacePage] = useState<number>(1);
  const [listenerPage, setListenerPage] = useState<number>(1);
  const [fansPage, setFansPage] = useState<number>(1);
  const [spaceList, setSpaceList] = useState<API.HostDTO[]>([]);
  const [listenerList, setListenerList] = useState<API.HostDTO[]>([]);
  const [fansList, setFansList] = useState<API.HostDTO[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchList, setSearchList] = useState<API.HostDTO[]>([]);
  const timer = useRef<any>();

  const { column, scrollWidth } = useMemo(() => {
    if (width < 1301 && width > 1060) {
      return {
        column: 3,
        scrollWidth: "calc(100vw - 80px)",
      };
    }
    if (width < 1061 && width > 750) {
      return {
        column: 2,
        scrollWidth: "calc(100vw - 80px)",
      };
    }
    if (width < 751) {
      return {
        column: 1,
        scrollWidth: "calc(100vw - 40px)",
      };
    }
    return {
      column: 4,
      scrollWidth: "calc(100vw - 80px)",
    };
  }, [width]);

  const searchSpaceList = (params: API.HostListParam, currentState: string) => {
    if (loading) {
      return;
    }
    setLoading(true);
    setList([]);
    const handler = followedHostListApi;
    const newParams = {
      order: "live",
      page: 1,
      size: PAGE_SIZE,
    };
    handler(newParams)
      .then((res) => {
        const data = onlyLiveFollowed
          ? res.data.filter((i) => i.lastest_space?.state === "live")
          : res.data;
        if (res.errno === 0) {
          setList(data);
        }
        setHasMore(data.length === PAGE_SIZE);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchSpaceListScroll = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const newParams = {
      ...params,
      page: params.page + 1,
      order: "live",
    };
    const handler = async () =>
      searchValue
        ? await searchHostApi({
            key: searchValue,
            page: params.page + 1,
            size: PAGE_SIZE,
          })
        : await followedHostListApi(newParams);

    handler()
      .then((res) => {
        const data = onlyLiveFollowed
          ? res.data.filter((i) => i.lastest_space?.state === "live")
          : res.data;

        if (res.errno === 0) {
          setList([...list, ...data]);
          setHasMore(data.length === PAGE_SIZE);
        }
        setParams(newParams);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleStateChange = (tag: string) => {
    setSearchValue("");
    if (tag === selectedState) {
      return;
    }
    if (tag === "followed") {
      const token = localStorage.getItem(TOKEN_KEY);
      !token && (window as any).famliveLogin();
      closeHasLive();
    }
    setSelectState(tag);
    setParams({ ...params, page: 1 });
    searchSpaceList({ ...params, page: 1 }, tag);
  };

  const renderListItem = (item: any) => {
    return (
      <div style={{ padding: "0 8px" }}>
        <RenderCard item={item} theme={theme} order={params.order} />
      </div>
    );
  };

  const toggleOnlyLive = (checked: boolean) => {
    setOnlyLiveFollowed(checked);
  };

  const renderHosts = (hostList: API.HostDTO[], order: string) => {
    const windowWidth =
      document.body.clientWidth > 1440 ? 1440 : document.body.clientWidth;
    const itemWidth = (windowWidth - 80) / column;
    if (!hostList || hostList.length === 0) {
      return <ListLoader layout={layout === "list" ? "list" : "hosts"} />;
    }
    if (layout === "list") {
      let listLayout = "list";
      if (windowWidth < 700) {
        listLayout = "card";
      }
      return hostList.map((i) => (
        <RenderCard
          key={i.user_info.id}
          item={i}
          theme={theme}
          order={order}
          layout={listLayout}
        />
      ));
    }
    return (
      <Row>
        {hostList.map((i: API.HostDTO) => {
          return (
            <Col
              style={{
                width: `${itemWidth}px`,
                padding: "0 8px",
              }}
              key={i.user_info?.id}
            >
              <RenderCard item={i} theme={theme} order={order} />
            </Col>
          );
        })}
      </Row>
    );
  };

  const spaceOrderHosts = useCallback(async () => {
    const res = await featuredHostListApi({
      page: spacePage,
      size: column * 2,
      order: "spaceTotal",
    });
    setSpaceList(spacePage === 1 ? res.data : [...spaceList, ...res.data]);
    setHasMoreFeatured({
      ...hasMoreFeatured,
      space: res.data?.length === column * 2,
    });
  }, [column, hasMoreFeatured, spaceList, spacePage]);

  const listenerOrderHosts = useCallback(async () => {
    const res = await featuredHostListApi({
      page: listenerPage,
      size: column * 2,
      order: "participantCount",
    });
    setListenerList(
      listenerPage === 1 ? res.data : [...listenerList, ...res.data]
    );
    setHasMoreFeatured({
      ...hasMoreFeatured,
      listener: res.data?.length === column * 2,
    });
  }, [listenerPage, column, listenerList, hasMoreFeatured]);

  const fansOrderHosts = useCallback(async () => {
    const res = await featuredHostListApi({
      page: fansPage,
      size: column * 2,
      order: "fansNum",
    });
    setFansList(fansPage === 1 ? res.data : [...fansList, ...res.data]);
    setHasMoreFeatured({
      ...hasMoreFeatured,
      fans: res.data?.length === column * 2,
    });
  }, [fansPage, column, fansList, hasMoreFeatured]);

  const searchHost = async () => {
    setLoading(true);
    const res = await searchHostApi({
      key: searchValue,
      page: 1,
      size: PAGE_SIZE,
    });
    if (!res.data) {
      setList([]);
      setLoading(false);
      setHasMore(false);
      return;
    }
    setList(res.data);
    setHasMore(res.data.length === PAGE_SIZE);
    setLoading(false);
  };

  const onSearch = (event: any) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (!searchValue) {
      setSelectState("featured");
      clearTimeout(timer.current);
      return;
    }
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setSelectState("search");
    setList([]);
    setLoading(true);
    timer.current = setTimeout(() => {
      searchHost();
    }, 500);
  }, [searchValue]);

  useEffect(() => {
    if (spacePage !== 1) {
      spaceOrderHosts();
    }
  }, [spacePage]);

  useEffect(() => {
    if (listenerPage !== 1) {
      listenerOrderHosts();
    }
  }, [listenerPage]);

  useEffect(() => {
    if (fansPage !== 1) {
      fansOrderHosts();
    }
  }, [fansPage]);

  useEffect(() => {
    spaceOrderHosts();
    listenerOrderHosts();
    fansOrderHosts();

    const loginSuccess = () => {
      searchSpaceList(params, selectedState);
    };
    Bus.addListener("loginSuccess", loginSuccess);
    return () => {
      Bus.removeListener("loginSuccess", loginSuccess);
    };
  }, []);

  useEffect(() => {
    searchSpaceList(params, selectedState);
  }, [onlyLiveFollowed]);

  return (
    <div className="hosts-content">
      <div className="search-input">
        <Input
          className="input"
          value={searchValue}
          allowClear
          prefix={<SearchOutlined style={{ marginRight: "8px" }} />}
          placeholder="Search host"
          onChange={onSearch}
          onPressEnter={onSearch}
        />
      </div>
      {!searchValue && (
        <div className="filter">
          <div className="filter-top">
            <div className="state">
              {stateTags.map((tag) => (
                <Badge
                  key={tag.value}
                  dot={tag.value === "followed" && hasLive}
                >
                  <CheckableTag
                    className={"state-tag " + tag.value}
                    key={tag.value}
                    checked={selectedState === tag.value}
                    onChange={() => handleStateChange(tag.value)}
                  >
                    {tag.label}
                  </CheckableTag>
                </Badge>
              ))}
            </div>
            <div className="lang-layout">
              {selectedState === "featured" && (
                <div className="layout">
                  {layoutTags.map((item) => (
                    <CheckableTag
                      key={item.value}
                      className="state-tag layout-tag"
                      checked={layout === item.value}
                      onChange={(checked) => checked && setLayout(item.value)}
                    >
                      {item.icon}
                    </CheckableTag>
                  ))}
                </div>
              )}
              {selectedState === "followed" && (
                <div className="toggle-live">
                  <span className="label">Live Only</span>
                  <Switch
                    checked={onlyLiveFollowed}
                    onChange={toggleOnlyLive}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {selectedState === "featured" ? (
        <div className="list-container">
          <div className="list-order">
            <div className="hosts-sub-title">BY SPACE (7D)</div>
            <div className="result">{renderHosts(spaceList, "spaceTotal")}</div>
            {hasMoreFeatured.space && (
              <div className="more">
                <Button
                  className="load-more-btn"
                  onClick={() => setSpacePage(spacePage + 1)}
                >
                  Load more
                </Button>
              </div>
            )}
          </div>
          <div className="list-order">
            <div className="hosts-sub-title">BY LISTENERS (7D)</div>
            <div className="result">
              {renderHosts(listenerList, "participantCount")}
            </div>
            {hasMoreFeatured.listener && (
              <div className="more">
                <Button
                  className="load-more-btn"
                  onClick={() => setListenerPage(listenerPage + 1)}
                >
                  Load more
                </Button>
              </div>
            )}
          </div>
          <div className="list-order">
            <div className="hosts-sub-title">BY FOLLOWERS (7D)</div>
            <div className="result">{renderHosts(fansList, "fansNum")}</div>
            {hasMoreFeatured.fans && (
              <div className="more">
                <Button
                  className="load-more-btn"
                  onClick={() => setFansPage(fansPage + 1)}
                >
                  Load more
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : list.length === 0 && !loading ? (
        <div className="no-data">
          <img src={theme === "dark" ? NoDataDark : NoDataLight} alt="" />
          No data
        </div>
      ) : (
        <InfiniteScroll
          dataLength={list.length}
          next={fetchSpaceListScroll}
          hasMore={hasMore}
          style={{ width: scrollWidth, maxWidth: "1356px" }}
          loader={<ListLoader layout={"hosts"} />}
          scrollableTarget="body"
        >
          <List
            className="home-list"
            grid={{ gutter: 16, column }}
            dataSource={list}
            renderItem={(item) => renderListItem(item)}
          />
        </InfiniteScroll>
      )}
    </div>
  );
}

export default Hosts;
