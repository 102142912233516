import {
  AppstoreOutlined,
  MenuOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Col, List, Row } from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "react-lottie";
import { useParams } from "react-router-dom";
import {
  getHostSpaceListApi,
  getTwitterUserApi,
  TOKEN_KEY,
  userOperateApi,
} from "../../api";
import FollowIcon from "../../assets/follow_icon.svg";
import LiveRing from "../../assets/live_ring.json";
import NoImg from "../../assets/noImg.png";
import { formatNumber, notify } from "../../utils";
import { useWindowSize } from "../../utils/useWindowSize";
import Header from "../Components/Header";
import ListLoader from "../Components/ListLoader";
import LoginModal from "../Components/Login";
import RenderCard from "../Components/SpaceCard/card";
import "./index.less";

const imgOnError = (e: any) => {
  e.target.src = NoImg;
};

const PAGE_SIZE = 12;

const layoutTags = [
  { icon: <MenuOutlined style={{ fontSize: "22px" }} />, value: "list" },
  { icon: <AppstoreOutlined style={{ fontSize: "22px" }} />, value: "card" },
];
const defaultParams = {
  twitter_user_id: "",
  state: "ended",
};

function HostInfo({
  changeTheme,
  theme,
}: {
  changeTheme: (theme: string) => void;
  theme: string;
}) {
  const [activeTab] = useState<string>("hosts");
  const [list, setList] = useState<API.SpaceDTO[]>([]);
  const [liveSpaceList, setLiveSpaceList] = useState<API.SpaceDTO[]>([]);
  const [scheduledSpaceList, setScheduledSpaceList] = useState<API.SpaceDTO[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [params, setParams] = useState<API.HostSpaceListParam>({
    ...defaultParams,
    page: 1,
    size: PAGE_SIZE,
  });
  const [selectedState] = useState<string>(defaultParams.state);
  const [layout, setLayout] = useState<string>("list");
  const [hostInfo, setHostInfo] = useState<API.HostInfo>();
  const [followState, setFollowState] = useState<string>("following");
  const [loginOpen, setLoginOpen] = useState<boolean>(false);
  const { width } = useWindowSize();
  const searchParams = useParams();
  const HostId = searchParams.id || "";

  const { column, scrollWidth } = useMemo(() => {
    if (width < 1301 && width > 1060) {
      return {
        column: 3,
        scrollWidth: "calc(100vw - 420px)",
      };
    }
    if (width < 1061 && width > 750) {
      return {
        column: 2,
        scrollWidth: "calc(100vw - 420px)",
      };
    }
    if (width < 751) {
      return {
        column: 1,
        scrollWidth: "calc(100vw - 40px)",
      };
    }
    return {
      column: 3,
      scrollWidth: "calc(100vw - 420px)",
    };
  }, [width]);

  const searchSpaceList = (params: API.HostSpaceListParam) => {
    if (loading) {
      return;
    }
    setLoading(true);
    setList([]);
    getHostSpaceListApi({
      ...params,
      twitter_user_id: HostId,
      page: 1,
      size: PAGE_SIZE,
    })
      .then((res) => {
        if (res.errno === 0) {
          setList(res.data);
        }
        setHasMore(res.data.length === PAGE_SIZE);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchSpaceListScroll = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const newParam = {
      ...params,
      page: params.page + 1,
      twitter_user_id: HostId,
    };
    getHostSpaceListApi(newParam)
      .then((res) => {
        if (res.errno === 0) {
          setList([...list, ...res.data]);
          setHasMore(res.data.length === PAGE_SIZE);
        }
        setParams(newParam);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getHostInfo = async () => {
    const res = await getTwitterUserApi({ twitter_user_id: HostId });
    setHostInfo(res.data);
    setLiveSpaceList(res.data?.live_space || []);
    setScheduledSpaceList(res.data?.scheduled_space || []);
    setFollowState(res.data?.is_follow ? "following" : "followed");
  };

  const formatList = (arr: API.SpaceDTO[]) => {
    if (layout === "list") {
      return arr;
    }
    if (selectedState === "scheduled" || selectedState === "ended") {
      // format date
      arr = arr.map((item) => {
        const timestamp =
          selectedState === "scheduled" ? item.scheduled_start : item.ended_at;
        return {
          ...item,
          date: moment.unix(timestamp).utc().format("MM-DD"),
        };
      });
      // group
      const newArr: API.SpaceDTO[][] = [];
      let date = arr[0] && arr[0].date;
      let tempArr: API.SpaceDTO[] = [];
      arr.forEach((item) => {
        if (item.date === date) {
          tempArr.push(item);
        } else {
          newArr.push(tempArr);
          tempArr = [item];
          date = item.date;
        }
      });
      if (tempArr.length) {
        newArr.push(tempArr);
      }
      return newArr;
    }
    return arr;
  };

  const renderListItem = (item: any) => {
    if (width < 751) {
      return (
        <RenderCard
          item={item}
          layout={"card"}
          theme={theme}
          updateItem={updateItem}
        />
      );
    }
    if (layout === "list") {
      return (
        <RenderCard
          item={item}
          layout={layout}
          theme={theme}
          updateItem={updateItem}
        />
      );
    }
    if (selectedState === "scheduled" || selectedState === "ended") {
      const list = item;
      const windowWidth =
        document.body.clientWidth > 1440 ? 1440 : document.body.clientWidth;
      const itemWidth = (windowWidth - 96) / column - 12;
      return (
        <div className="date-card">
          <div className="date">
            <span>{list[0] && list[0].date}</span>
          </div>
          <div className="line"></div>
          <Row>
            {list.map((i: any) => {
              return (
                <Col
                  style={{
                    width: `${itemWidth}px`,
                    margin: "0 5px",
                  }}
                  key={i.space}
                >
                  <RenderCard
                    item={i}
                    layout={layout}
                    theme={theme}
                    updateItem={updateItem}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      );
    }
    return (
      <RenderCard
        item={item}
        layout={layout}
        theme={theme}
        updateItem={updateItem}
      />
    );
  };

  const listColumn = useMemo(() => {
    if (layout === "list") {
      return 1;
    }
    if (layout === "card" && selectedState === "scheduled") {
      return 1;
    }
    if (layout === "card" && selectedState === "ended") {
      return 1;
    }
    return column;
  }, [column, layout, selectedState]);

  const followHost = async (e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      setLoginOpen(true);
      return;
    }
    if (!hostInfo) {
      return;
    }
    try {
      await userOperateApi({
        twitter_user_id: hostInfo.user_info.id,
        operate: hostInfo.is_follow ? "unfollow" : "follow",
      });
      getHostInfo();
    } catch (error) {
      notify({
        type: "warning",
        message: "Followed error",
        description: "Please try again later",
      });
    }
  };

  const openHostTwitter = (e: any) => {
    e.stopPropagation();
    window.open(`https://twitter.com/${host?.username}`);
  };

  const updateItem = (item: API.SpaceDTO) => {
    if (item.state === "ended") {
      setList(list.map((space) => (space.space === item.space ? item : space)));
      return;
    }
    if (item.state === "scheduled") {
      setScheduledSpaceList(
        scheduledSpaceList.map((space) =>
          space.space === item.space ? item : space
        )
      );
      return;
    }
    if (item.state === "live") {
      setLiveSpaceList(
        liveSpaceList.map((space) =>
          space.space === item.space ? item : space
        )
      );
      return;
    }
  };

  useEffect(() => {
    getHostInfo();
    searchSpaceList(params);

    (window as any).famliveLogin = () => setLoginOpen(true);
  }, []);

  const host = hostInfo?.user_info;

  return (
    <div className="home-container">
      <Header
        theme={theme}
        changeTheme={changeTheme}
        activeTab={activeTab}
        showSlogan={false}
      />
      <div className="host-info-container">
        {/* host-info */}
        <div className="host-info-card">
          <div className={`host-info-card-top`}>
            {/* avatar */}
            <div
              className={
                "host-avatar " + (liveSpaceList && liveSpaceList[0]?.state)
              }
            >
              {liveSpaceList && liveSpaceList[0]?.state === "live" && (
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: LiveRing,
                  }}
                  width={78}
                  height={78}
                />
              )}
              <img src={host?.profile_image_url} alt="" onError={imgOnError} />
            </div>
            {/* host info */}
            <div className="info">
              <div className="name">
                <span>{host?.name}</span>
              </div>
              <div className="account" onClick={openHostTwitter}>
                <span>
                  <TwitterOutlined />@{host?.username}
                </span>
              </div>
            </div>
          </div>
          <div className="follow-btn">
            <div
              className={`btn ${followState}`}
              onClick={(e) => followHost(e)}
              onMouseEnter={() => {
                if (hostInfo?.is_follow) {
                  setFollowState("unfollow");
                }
              }}
              onMouseOut={() =>
                setFollowState(hostInfo?.is_follow ? "following" : "followed")
              }
            >
              {followState === "unfollow" && "Unfollow"}
              {followState === "followed" && (
                <span>
                  <img className="icon" src={FollowIcon} alt="" />
                  Follow
                </span>
              )}
              {followState === "following" && "Following"}
            </div>
          </div>
          <div className="info-count">
            <div className="item">
              <div className="label">Follower</div>
              <div className="value">{formatNumber(host?.follower_num)}</div>
            </div>
            <div className="item">
              <div className="label">Spaces(7D)</div>
              <div className="value">{formatNumber(hostInfo?.space_7day)}</div>
            </div>
            <div className="item">
              <div className="label">Spaces(Total)</div>
              <div className="value">{formatNumber(hostInfo?.space_total)}</div>
            </div>
          </div>
          {/* live space */}
          {liveSpaceList &&
            liveSpaceList.map((liveSpace) => (
              <div className="live-space" key={liveSpace.space}>
                <RenderCard
                  item={liveSpace}
                  layout={"card"}
                  hideHost
                  theme={theme}
                  updateItem={updateItem}
                />
              </div>
            ))}
          {/* scheduled space */}
          {scheduledSpaceList &&
            scheduledSpaceList.map((scheduledSpace) => (
              <div className="live-space" key={scheduledSpace.space}>
                <RenderCard
                  item={scheduledSpace}
                  layout={"card"}
                  hideHost
                  theme={theme}
                  updateItem={updateItem}
                />
              </div>
            ))}
        </div>
        {/* space list */}
        <div className="host-space-list" id="host-space-scroll-target">
          <div className="filter">
            <div className="filter-top">
              <div className="lang-layout">
                <div className="layout">
                  {layoutTags.map((item) => (
                    <CheckableTag
                      key={item.value}
                      className="state-tag layout-tag"
                      checked={layout === item.value}
                      onChange={(checked) => checked && setLayout(item.value)}
                    >
                      {item.icon}
                    </CheckableTag>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <InfiniteScroll
            dataLength={list.length}
            next={fetchSpaceListScroll}
            hasMore={hasMore}
            style={{ width: scrollWidth, maxWidth: "1018px" }}
            loader={<ListLoader layout={layout} />}
            scrollableTarget="body"
          >
            <List
              className="home-list"
              rowKey={"space"}
              grid={{ gutter: 16, column: listColumn }}
              dataSource={formatList(list) as any}
              renderItem={(item) => renderListItem(item)}
            />
          </InfiniteScroll>
        </div>
        <LoginModal visible={loginOpen} close={() => setLoginOpen(false)} />
      </div>
    </div>
  );
}

export default HostInfo;
