import { Skeleton, Space } from "antd";
import { useMemo } from "react";
import { useWindowSize } from "../../../utils/useWindowSize";
import "./index.less";

function ListLoader({ layout }: { layout: string }) {
  const { width } = useWindowSize();

  const { column } = useMemo(() => {
    if (width < 1301 && width > 1060) {
      return {
        column: 3,
      };
    }
    if (width < 1061 && width > 750) {
      return {
        column: 2,
      };
    }
    if (width < 751) {
      return {
        column: 1,
      };
    }
    return {
      column: 4,
    };
  }, [width]);

  const card = (key?: number) => {
    return (
      <div
        className="list-loader-card"
        key={key}
        style={{ width: `${100 / column}%` }}
      >
        <div className="list-loader-card-inner">
          <Skeleton.Input className="state" active />
          <Skeleton.Button className="title" active block />
          <Space className="desc">
            <Skeleton.Input className="text" active />
            <Skeleton.Avatar className="avatar" active />
          </Space>
          <Skeleton.Button active block />
        </div>
      </div>
    );
  };

  const hostCard = (key?: number) => {
    return (
      <div
        className="list-loader-host-card"
        key={key}
        style={{ width: `${100 / column}%` }}
      >
        <div className="list-loader-host-card-inner">
          <Space className="top">
            <Skeleton.Avatar className="avatar" active />
            <Skeleton.Button className="name" active />
          </Space>
          <div className="bottom">
            <Skeleton.Input className="text" active />
            <Skeleton.Button className="title" active block />
            <Skeleton.Input className="text long" active />
            <Skeleton.Button className="time" active block />
          </div>
        </div>
      </div>
    );
  };

  const listItem = (key?: number) => {
    return (
      <div className="list-loader-list" key={key}>
        <Skeleton avatar paragraph={{ rows: 1 }} active />
      </div>
    );
  };

  const len = Array.from({ length: column }, (v, k) => k);

  return (
    <div className="list-loader-container">
      <>
        {layout === "card" && len.map((i) => card(i))}
        {layout === "list" && len.map((i) => listItem(i))}
        {layout === "hosts" && len.map((i) => hostCard(i))}
      </>
    </div>
  );
}

export default ListLoader;
