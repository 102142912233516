import ETH from "../assets/balance/ETH.png";
import USDT from "../assets/balance/USDT.png";
import USDC from "../assets/balance/USDC.png";
import BNB from "../assets/balance/BNB.png";
import DAI from "../assets/balance/DAI.png";
import { JsonRpcProvider, ethers } from "ethers";
import UsdtAbi from "./ABI/usdt.json";
import UsdcAbi from "./ABI/usdc.json";
import DaiAbi from "./ABI/dai.json";

export const CryptoCoins = [
  { name: "ETH", icon: ETH },
  { name: "USDT", icon: USDT },
  { name: "USDC", icon: USDC },
  { name: "BNB", icon: BNB },
  { name: "DAI", icon: DAI },
];

const coinMap: any = {};
CryptoCoins.forEach((coin) => (coinMap[coin.name.toLocaleLowerCase()] = coin));

export const CryptoCoinMap = coinMap;

export const ChainNetworks = [
  { name: "ETH mainnet", value: "eth_mainnet" },
  { name: "ETH goerli", value: "eth_goerli" },
  { name: "BSC mainnet", value: "bsc_mainnet" },
  { name: "bsc testnet", value: "bsc_mainnet" },
];

export const getUSDTPrice = async () => {
  const provider = new JsonRpcProvider("https://api.securerpc.com/v1");
  const aggregatorV3InterfaceABI = UsdtAbi;
  const addr = "0x3E7d1eAB13ad0104d2750B8863b489D65364e32D";
  const priceFeed = new ethers.Contract(
    addr,
    aggregatorV3InterfaceABI,
    provider
  );
  const roundData = await priceFeed.latestRoundData();
  const decimals = await priceFeed.decimals();
  return Number(
    (roundData.answer.toString() / Math.pow(10, decimals.toString())).toFixed(2)
  );
};

export const getUSDCPrice = async () => {
  const provider = new JsonRpcProvider("https://api.securerpc.com/v1");
  const aggregatorV3InterfaceABI = UsdcAbi;
  const addr = "0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6";
  const priceFeed = new ethers.Contract(
    addr,
    aggregatorV3InterfaceABI,
    provider
  );
  const roundData = await priceFeed.latestRoundData();
  const decimals = await priceFeed.decimals();
  return Number(
    (roundData.answer.toString() / Math.pow(10, decimals.toString())).toFixed(2)
  );
};

export const getDAIPrice = async () => {
  const provider = new JsonRpcProvider("https://api.securerpc.com/v1");
  const aggregatorV3InterfaceABI = DaiAbi;
  const addr = "0xAed0c38402a5d19df6E4c03F4E2DceD6e29c1ee9";
  const priceFeed = new ethers.Contract(
    addr,
    aggregatorV3InterfaceABI,
    provider
  );
  const roundData = await priceFeed.latestRoundData();
  const decimals = await priceFeed.decimals();
  return Number(
    (roundData.answer.toString() / Math.pow(10, decimals.toString())).toFixed(2)
  );
};

export const shortHash = (hash: string) => {
  if (!hash || hash.length < 12) {
    return hash;
  }
  return `${hash.slice(0, 6)}...${hash.slice(-4)}`;
};
