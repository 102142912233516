import axios from "axios";
import { notify } from "../utils";

export const TOKEN_KEY = "FAMLIVE_TOKEN";
export const THEME_KEY = "FAMLIVE_THEME";
export const CUSTOM_URL_KEY = "FAMLIVE_CUSTOM_URL";

export const baseUrl = "https://dev-lb.famchat.io";
export const splUrl = "https://spl-it.xyz";

axios.interceptors.request.use(
  function (config) {
    const token: string = localStorage.getItem(TOKEN_KEY) || "";
    const headers = config.headers || {};
    headers.token = token;
    return {
      ...config,
      headers,
    };
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response?.data?.errno === 10092) {
      notify({
        type: "warning",
        message: "",
        description: error.response?.data.errmsg,
      });
    }
    return Promise.reject(error);
  }
);

// get space list
export const getSpaceListApi = async (data: API.SpaceListQuery) => {
  const params = { ...data };
  if (params.state === "featured") {
    params.featured = true;
    params.state = "";
  } else {
    delete params.featured;
  }
  const res: API.PageResult<API.SpaceDTO> = await axios.get(
    `${baseUrl}/channels/space_list`,
    { params }
  );
  return res;
};

// get label list
export const getLabelListApi = async () => {
  const res: API.PageResult<string[]> = await axios.get(
    `${baseUrl}/channels/label_list`
  );
  return res;
};

// login
export const loginByAddressApi = async (params: API.LoginParam) => {
  const res: { nonce: number; login_msg: string } = await axios.get(
    `${baseUrl}/auth/login`,
    {
      params,
    }
  );
  return res;
};

// auth
export const authorizationApi = async (params: API.AuthParam) => {
  const res: { token: string } = await axios.get(
    `${baseUrl}/auth/authorization`,
    { params }
  );
  return res;
};

// get account info
export const getAccountInfoApi = async (token: string) => {
  const res: API.UserInfo = await axios.post(`${baseUrl}/account/info`, {
    token,
  });
  return res;
};

// bind wallet
export const bindWalletApi = async (data: API.BindWalletBody) => {
  const res: any = await axios.post(`${baseUrl}/auth/bind_wallet`, data);
  return res;
};

// disconnect platform
export const disconnectPlatformApi = async (platform: "twitter" | "eth") => {
  const res: any = await axios.post(`${baseUrl}/auth/disconnect_bind`, {
    platform,
  });
  return res;
};

// featured host list
export const featuredHostListApi = async (params: API.HostListParam) => {
  const res: API.HostListRes = await axios.get(
    `${baseUrl}/channels/twitter_space_host_list`,
    { params }
  );
  return res;
};

// followed host list
export const followedHostListApi = async (params: API.HostListParam) => {
  const res: API.HostListRes = await axios.get(
    `${baseUrl}/sns/twitter/followed_user_list`,
    { params }
  );
  return res;
};

// user operate: follow/unfollow host
export const userOperateApi = async (data: API.UserOperateBody) => {
  const res: any = await axios.post(
    `${baseUrl}/sns/twitter/user_operate`,
    data
  );
  return res;
};

// get twitter user info
export const getTwitterUserApi = async (params: {
  twitter_user_id: string;
}) => {
  const res: API.Result<API.HostInfo> = await axios.get(
    `${baseUrl}/channels/twitter_user_info`,
    { params }
  );
  return res;
};

// get host space list
export const getHostSpaceListApi = async (params: API.HostSpaceListParam) => {
  const res: API.PageResult<API.SpaceDTO> = await axios.get(
    `${baseUrl}/channels/twitter_user_space_list`,
    { params }
  );
  return res;
};

// get space info by id
export const getSpaceInfoApi = async (params: {
  space_id: string;
  invite_code: string;
}) => {
  const res: API.Result<API.SpaceDTO> = await axios.get(
    `${baseUrl}/channels/space_info`,
    {
      params,
    }
  );
  return res;
};

export const getMediaStream = async (params: {
  space_id: string;
  media_key: string;
}) => {
  const res: any = await axios.get(`${baseUrl}/channels/space_media_stream`, {
    params,
  });
  return res;
};

export const getSpaceDetail = (params: { space_id: string }) => {
  return axios.get(`${baseUrl}/channels/space_detail`, { params });
};

// get all fam space
export const getFamSpaceListApi = async (data: API.FamSpaceListBody) => {
  const res: { channels: API.FamSpaceDTO[]; total: number } = await axios.post(
    `${baseUrl}/famspace/channels`,
    data
  );
  return res;
};

// create a fam space with detail
export const createFamSpaceApi = async (data: API.CreateFamSpaceBody) => {
  const res: { famspace_id: number } = await axios.post(
    `${baseUrl}/famspace/create`,
    data
  );
  return res;
};

// join fam space
export const joinFamSpaceApi = async (data: API.JoinFamSpaceBody) => {
  const res: API.JoinFamSpaceRes = await axios.post(
    `${baseUrl}/famspace/join`,
    data
  );
  return res;
};

// generate a token for fam space
export const getFamSpaceTokenApi = async (data: API.FamSpaceTokenBody) => {
  const res: API.FamSpaceTokenRes = await axios.post(
    `${baseUrl}/famspace/token`,
    data
  );
  return res;
};

// get self role
export const getSelfFamSpaceRoleApi = async (data: { famspace_id: number }) => {
  const res: API.SelfFamSpaceRole = await axios.post(
    `${baseUrl}/famspace/role`,
    data
  );
  return res;
};

// fam space members
export const getFamSpaceMembersApi = async (data: { famspace_id: number }) => {
  const res: { members: API.FamSpaceMemberDTO[] } = await axios.post(
    `${baseUrl}/famspace/members`,
    data
  );
  return res;
};

// change role of user
export const changeFamSpaceUserRoleApi = async (
  data: API.ChangeUserRoleBody
) => {
  const res: { errno: number; errmsg: string } = await axios.post(
    `${baseUrl}/famspace/change_role`,
    data
  );
  return res;
};

// force kick out a user
export const banFamSpaceUserApi = async (data: API.BanUserBody) => {
  const res: { errno: number; errmsg: string } = await axios.post(
    `${baseUrl}/famspace/ban`,
    data
  );
  return res;
};

// quit a fam space
export const quitFamSpaceApi = async (data: { famspace_id: number }) => {
  const res: { errno: number; errmsg: string } = await axios.post(
    `${baseUrl}/famspace/quit`,
    data
  );
  return res;
};

// close a famspace
export const closeFamSpaceApi = async (data: { famspace_id: number }) => {
  const res: { errno: number; errmsg: string } = await axios.post(
    `${baseUrl}/famspace/close`,
    data
  );
  return res;
};

// get famspace by id
export const getFamSpaceByIdApi = async (data: { famspace_id: number }) => {
  const res: API.FamSpaceDTO = await axios.post(
    `${baseUrl}/famspace/channel`,
    data
  );
  return res;
};

// request microphone
export const requestMicroPhoneApi = async (data: { famspace_id: number }) => {
  const res: { errno: number; errmsg: string } = await axios.post(
    `${baseUrl}/famspace/reqmic`,
    data
  );
  return res;
};

// transfer famspace
export const transferFamspaceApi = async (data: {
  famspace_id: number;
  to_uid: number;
}) => {
  const res: { errno: number; errmsg: string } = await axios.post(
    `${baseUrl}/famspace/transfer`,
    data
  );
  return res;
};

// global info
export const getGlobalInfoApi = async () => {
  const res: API.Result<API.UserGlobalInfo> = await axios.get(
    `${baseUrl}/channels/famlive/user_global_profile`
  );
  return res;
};

// search space
export const searchSpaceApi = async (params: API.SearchSpaceAndHostParams) => {
  const res: API.PageResult<API.SpaceDTO> = await axios.get(
    `${baseUrl}/channels/famlive/search_twitter_space`,
    { params }
  );
  return res;
};

// search host
export const searchHostApi = async (params: API.SearchSpaceAndHostParams) => {
  const res: API.PageResult<API.HostDTO> = await axios.get(
    `${baseUrl}/channels/famlive/search_twitter_user`,
    { params }
  );
  return res;
};

// create favorite
export const createFavoriteSpaceApi = async (data: { space_id: string }) => {
  const res: { errno: number; errmsg: string } = await axios.post(
    `${baseUrl}/channels/famlive/create_space_collection`,
    data
  );
  return res;
};

// delete favorite
export const deleteFavoriteSpaceApi = async (data: { space_id: string }) => {
  const res: { errno: number; errmsg: string } = await axios.post(
    `${baseUrl}/channels/famlive/delete_space_collection`,
    data
  );
  return res;
};

// favorite space
export const getFavoriteSpacesApi = async (params: API.FavoriteSpaceParam) => {
  const res: API.PageResult<API.SpaceDTO> = await axios.get(
    `${baseUrl}/channels/famlive/list_space_collection`,
    { params }
  );
  return res;
};

// personal info
export const getPersonalInfoApi = async () => {
  const res: API.Result<API.PersonalInfo> = await axios.get(
    `${baseUrl}/channels/famlive/personal_info`
  );
  return res;
};

// listened space
export const getListenedSpaceApi = async (params: API.FavoriteSpaceParam) => {
  const res: API.PageResult<API.SpaceDTO> = await axios.get(
    `${baseUrl}/channels/famlive/listened_space_list`,
    { params }
  );
  return res;
};

// update account info
export const updateAccountInfoApi = async (data: API.UpdateAccountBody) => {
  const res: { errno: number; errmsg: string } = await axios.post(
    `${baseUrl}/account/update_info`,
    data
  );
  return res;
};

// get aws update config
export const getAWSConfigApi = async () => {
  const res: API.AWSConfig = await axios.post(`${baseUrl}/sender/file`, {
    token: localStorage.getItem(TOKEN_KEY),
  });
  return res;
};

// invite space
export const getInviteInfoApi = async (params: { space_id: string }) => {
  const res: API.Result<API.TweetDTO> = await axios.get(
    `${baseUrl}/channels/famlive/invite_space`,
    { params }
  );
  return res;
};
export const getSpaceTranscribeStatus = async (space_id: string) => {
  const res: any = await axios.get(
    `${baseUrl}/channels/famlive/query_space_stt_info`,
    {
      params: {
        token: localStorage.getItem(TOKEN_KEY),
        space_id,
      },
    }
  );
  return res;
};

// monthly space
export const getMonthlySpacesApi = async (params: {
  start: string;
  end: string;
}) => {
  const res: API.Result<API.MonthlySpaces> = await axios.get(
    `${baseUrl}/channels/famlive/monthly_space_list`,
    { params }
  );
  return res;
};

// get aws update config
export const addTwitterSpaceApi = async (data: { url: string }) => {
  const res: any = await axios.post(
    `${baseUrl}/channels/famlive/add_twitter_space`,
    data
  );
  return res;
};

export const getCaptionsByLocation = async (location: string) => {
  const res: any = await axios.get(location);
  return res;
};

// created space
export const getCreatedSpaceApi = async (params: API.FavoriteSpaceParam) => {
  const res: API.PageResult<API.SpaceDTO> = await axios.get(
    `${baseUrl}/channels/famlive/host_space_list`,
    { params }
  );
  return res;
};

// homepage recommend
export const getHomepageRecommendApi = async () => {
  const res: API.PageResult<API.SpaceDTO> = await axios.get(
    `${baseUrl}/channels/famlive/homepage_recommend_list`
  );
  return res;
};

// get recommend spaces by state
export const getRecommendListApi = async (params: API.FavoriteSpaceParam) => {
  const res: API.PageResult<API.SpaceDTO> = await axios.get(
    `${baseUrl}/channels/famlive/recommend_list`,
    { params }
  );
  return res;
};

// global config
export const getGlobalConfigApi = async () => {
  const res: API.Result<API.GlobalConfig> = await axios.get(
    `${baseUrl}/channels/famlive/global_config`
  );
  return res;
};

// verify tweet
export const verifyTweetApi = async () => {
  const res: API.Result<{ verify: boolean }> = await axios.get(
    `${baseUrl}/channels/famlive/verify_user_tweet`
  );
  return res;
};

// claim airdrop
export const claimAirdropApi = async () => {
  const res: API.Result<any> = await axios.post(
    `${baseUrl}/channels/famlive/claim_airdrop`
  );
  return res;
};

// airdrop egg balance
export const getAirdropEggBalanceApi = async () => {
  const res: API.Result<API.EggBalance> = await axios.get(
    `${baseUrl}/channels/famlive/airdrop_egg_account`
  );
  return res;
};

// bind wallet sign message
export const getBindWalletSignMessageApi = async (
  params: API.BindWalletSignMessageParams
) => {
  const res: API.Result<API.BindWalletSignMessage> = await axios.get(
    `${baseUrl}/auth/bind_wallet_sigmsg`,
    { params }
  );
  return res;
};

// get user wallet address
export const getWalletAddressByChainApi = async (data: {
  network: string;
  asset_id: number;
}) => {
  const res: API.Result<{ address: string }> = await axios.post(
    `${baseUrl}/chaindata/wallet/address`,
    data
  );
  return res;
};

// get wallet balance
export const getWalletBalanceApi = async () => {
  const res: API.Result<API.CoinBalance[]> = await axios.get(
    `${baseUrl}/chaindata/wallet/balance`
  );
  return res;
};

// get wallet history
export const getWalletChangeHistoryApi = async () => {
  const res: API.Result<API.WalletHistory[]> = await axios.get(
    `${baseUrl}/chaindata/wallet/change_history`
  );
  return res;
};

// validate twitter user
export const validateTwitterUserApi = async (params: { username: string }) => {
  const res: API.Result<API.SpaceUserDTO> = await axios.get(
    `${baseUrl}/channels/famlive/look_for_twitter_user`,
    { params }
  );
  return res;
};

// send asset
export const sendAssetApi = async (data: API.SendAssetBody) => {
  const res: API.Result<any> = await axios.post(
    `${baseUrl}/chaindata/wallet/send_asset`,
    data
  );
  return res;
};

// claim asset
export const claimAssetApi = async (data: API.ClaimAssetBody) => {
  const res: API.Result<API.ClaimAssetRes> = await axios.post(
    `${baseUrl}/chaindata/wallet/claims_asset`,
    data
  );
  return res;
};

// get support assets
export const getSupportAssetsApi = async () => {
  const res: API.Result<API.NetworkAndAssets[]> = await axios.get(
    `${baseUrl}/chaindata/wallet/support_assets`
  );
  return res;
};

// add erc20 token by contract
export const addErc20TokenApi = async (data: {
  network: string;
  contract_addr: string;
}) => {
  const res: API.Result<any> = await axios.post(
    `${baseUrl}/chaindata/wallet/add_erc20_token`,
    data
  );
  return res;
};

// send red packet
export const sendRedPacketApi = async (data: API.SendRedPacketBody) => {
  const res: API.Result<any> = await axios.post(
    `${baseUrl}/chaindata/wallet/send_redbag`,
    data
  );
  return res;
};

// get red packet
export const getRedPacketByIdApi = async (params: API.RedPacketParams) => {
  const res: API.Result<API.RedPacketDTO> = await axios.get(
    `${baseUrl}/chaindata/wallet/redbag_info`,
    { params }
  );
  return res;
};
// receive red packet
export const receiveRedPacketApi = async (
  params: API.ReceiveRedPacketParams
) => {
  const res: API.Result<any> = await axios.post(
    `${baseUrl}/chaindata/wallet/receive_redbag`,
    { ...params }
  );
  return res;
};

export const validateFamliveUserApi = async (params: API.FamliveUserParam) => {
  const res: API.Result<API.FamliveUserDTO> = await axios.get(
    `${baseUrl}/account/look_for_account_info`,
    { params }
  );
  return res;
};
