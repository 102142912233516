import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import moment from "moment";
import { useState } from "react";
import Lottie from "react-lottie";
import { TOKEN_KEY, userOperateApi } from "../../../api";
import DateIcon from "../../../assets/calendar.svg";
import DateIconLight from "../../../assets/calendar_light.svg";
import LiveRing from "../../../assets/live_ring.json";
import NoImg from "../../../assets/noImg.png";
import { formatNumber, notify } from "../../../utils";
import "./card.less";

const imgOnError = (e: any) => {
  e.target.src = NoImg;
};

function RenderCard({
  item,
  theme,
  order,
  layout,
  short,
}: {
  item: API.HostDTO;
  theme: string;
  order: string;
  layout?: string;
  short?: boolean;
}) {
  const host = item.user_info || {};
  const [followed, setFollowed] = useState<boolean>(!!item.is_follow);

  const openHost = (e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    window.open(`/host/${item.user_info.id}`);
  };

  const openSpace = (e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    window.open(`/space/${item.lastest_space.space}`);
  };

  const followHost = async (e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      (window as any).famliveLogin();
      return;
    }
    if (item.is_follow) {
      return;
    }
    try {
      await userOperateApi({
        twitter_user_id: item.user_info.id,
        operate: item.is_follow ? "unfollow" : "follow",
      });
      setFollowed(!item.is_follow);
    } catch (error) {
      notify({
        type: "warning",
        message: "Followed error",
        description: "Please try again later",
      });
    }
  };

  const openHostTwitter = (e: any) => {
    e.stopPropagation();
    window.open(`https://twitter.com/${host.username}`);
  };

  const openNextSpace = (e: any) => {
    e.stopPropagation();
    if (item.next_space) {
      window.open(`/scheduled/${item.next_space.space}`);
    }
  };

  if (layout === "list") {
    // list
    return (
      <div className="host-info list" key={host.id} onClick={openHost}>
        <div className={`card-top`}>
          {/* avatar */}
          <div className={"host-avatar "}>
            {item.lastest_space?.state === "live" && (
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: LiveRing,
                }}
                width={78}
                height={78}
              />
            )}
            <img src={host.profile_image_url} alt="" onError={imgOnError} />
            <div
              className={`follow-state ${followed && "followed"}`}
              onClick={followHost}
            >
              {followed ? (
                <CheckOutlined />
              ) : (
                <Tooltip
                  title="Follow"
                  placement="bottom"
                  color={theme === "dark" ? "#fff" : "#434343"}
                >
                  <PlusOutlined />
                </Tooltip>
              )}
            </div>
          </div>
          {/* host info */}
          <div className="info">
            <div className="name">
              <span className="host-name">{host.name}</span>
            </div>
            <div className="account">
              <span className="host-name" onClick={openHostTwitter}>
                @{host.username}
              </span>
            </div>
          </div>
        </div>

        {!short && (
          <>
            {/* latest space */}
            <div className="latest-space" onClick={openSpace}>
              <div className="sub-title">Last Space</div>
              <div className="space-name">{item.lastest_space?.title}</div>
              <div className="space-labels">
                {item.lastest_space?.labels?.map((label, index) => (
                  <span key={label}>
                    <Tag className="card-label-tag">{label}</Tag>
                    {index === 2 && <br />}
                  </span>
                ))}
              </div>
            </div>
            {/* next space */}
            <div className="card-bottom" onClick={openNextSpace}>
              <div className="sub-title">Next Space</div>
              <div className="time">
                <img src={theme === "dark" ? DateIcon : DateIconLight} alt="" />
                {item.next_space?.scheduled_start
                  ? moment
                      .unix(item.next_space.scheduled_start)
                      .utc()
                      .format("ddd, MMM D")
                  : "-"}
              </div>
            </div>
            {/* list only */}
            <div className="order-info">
              <div className="name">
                {order === "spaceTotal" && (
                  <span className="count">{formatNumber(item.space_7day)}</span>
                )}
                {order === "fansNum" && (
                  <span className="count">
                    {formatNumber(host.follower_num)}
                  </span>
                )}
                {order === "participantCount" && (
                  <span className="count">
                    {formatNumber(item.participant_total)}
                  </span>
                )}
              </div>
              <div className="account">
                {order === "spaceTotal" && (
                  <span className="count">Spaces(7D)</span>
                )}
                {order === "fansNum" && (
                  <span className="count">Followers</span>
                )}
                {order === "participantCount" && (
                  <span className="count">Listeners(7D)</span>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  // card
  return (
    <div className="host-info" key={host.id} onClick={openHost}>
      <div className={`card-top`}>
        {/* avatar */}
        <div className={"host-avatar "}>
          {item.lastest_space?.state === "live" && (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: LiveRing,
              }}
              width={78}
              height={78}
            />
          )}
          <img src={host.profile_image_url} alt="" onError={imgOnError} />
          <div
            className={`follow-state ${followed && "followed"}`}
            onClick={followHost}
          >
            {followed ? (
              <CheckOutlined />
            ) : (
              <Tooltip
                title="Follow"
                placement="bottom"
                color={theme === "dark" ? "#fff" : "#434343"}
              >
                <PlusOutlined />
              </Tooltip>
            )}
          </div>
        </div>
        {/* host info */}
        <div className="info">
          <div className="name">
            <span className="host-name">{host.name}</span>
            {order === "spaceTotal" && (
              <span className="count">{formatNumber(item.space_7day)}</span>
            )}
            {order === "fansNum" && (
              <span className="count">{formatNumber(host.follower_num)}</span>
            )}
            {order === "participantCount" && (
              <span className="count">
                {formatNumber(item.participant_total)}
              </span>
            )}
          </div>
          <div className="account">
            <span className="host-name" onClick={openHostTwitter}>
              @{host.username}
            </span>
            {order === "spaceTotal" && (
              <span className="count">Spaces(7D)</span>
            )}
            {order === "fansNum" && <span className="count">Followers</span>}
            {order === "participantCount" && (
              <span className="count">Listeners(7D)</span>
            )}
          </div>
        </div>
      </div>
      {/* latest space */}
      <div className="latest-space" onClick={openSpace}>
        <div className="sub-title">Last Space</div>
        <div className="space-name">{item.lastest_space?.title}</div>
        <div className="space-labels">
          {item.lastest_space?.labels?.map((label, index) => (
            <span key={label}>
              <Tag className="card-label-tag">{label}</Tag>
              {index === 2 && <br />}
            </span>
          ))}
        </div>
      </div>
      {/* next space */}
      <div className="card-bottom" onClick={openNextSpace}>
        <div className="sub-title">Next Space</div>
        <div className="time">
          <img src={theme === "dark" ? DateIcon : DateIconLight} alt="" />
          {item.next_space?.scheduled_start
            ? moment
                .unix(item.next_space.scheduled_start)
                .utc()
                .format("ddd, MMM D")
            : "-"}
        </div>
      </div>
    </div>
  );
}

export default RenderCard;
