import React, { useEffect, useState, useRef } from "react";
import { getSpaceTranscribeStatus, getCaptionsByLocation } from "../../api";
import "./index.less";
type Captions = {
  jobName: string;
  accountId: string;
  results: {
    transcripts: {
      transcript: string;
    }[];
    items: {
      start_time: string;
      language_code: string;
      end_time: string;
      alternatives: {
        confidence: string;
        content: string;
      }[];
      type: string;
    }[];
  };
  status: string;
};

const usePrevious: <T>(value: T) => T = (value) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const formatCaptionTime = (currentTime: number) => {
  let result = "";
  const hour = Math.floor(currentTime / 3600);

  const minute = Math.floor((currentTime - hour * 3600) / 60);
  const second = Math.floor(currentTime - hour * 3600 - minute * 60);
  if (hour) result = result + hour + ":";
  const minuteString = minute >= 10 ? minute.toString() : "0" + minute;
  const secondString = second >= 10 ? second.toString() : "0" + second;
  result = result + minuteString + ":";
  result += secondString;
  return result;
};

const RecordPlayer = ({ captions }: { captions: Captions }) => {
  const hlsInstance = (window as any).hlsInstance;
  const [currentPosition, setCurrentPosition] = useState(0);
  const [time, setTime] = useState(Date.now());
  const timerRef = useRef(-1);
  const [shouldScrollToCurrentCaption, setShouldScrollToCurrentCaption] =
    useState(false);

  const lastTime = usePrevious(time);
  const captionList = captions.results.items;

  let tempString = "";
  let startTime = 0;
  const sentenceList = captionList.flatMap((caption, index) => {
    if (caption.type === "punctuation") {
      const content = tempString + caption.alternatives[0].content;
      const time = startTime;
      startTime = 0;
      tempString = "";
      return [
        {
          startTime: time,
          content,
        },
      ];
    }
    if (caption.type === "pronunciation") {
      if (tempString === "" && caption.start_time) {
        startTime = Number(caption.start_time);
      }
      tempString += " ";
      tempString += caption.alternatives[0].content;
      return [];
    }
    return [];
  });

  const findCurrentSentenceIndex = () => {
    const currentTime = hlsInstance?.media?.currentTime;
    if (currentTime) {
      return sentenceList.findIndex((sentence, index) => {
        if (currentTime > sentence.startTime) {
          if (index === sentenceList.length - 1) return true;
          const nextSentence = sentenceList[index + 1];
          if (nextSentence && currentTime < nextSentence.startTime) {
            return true;
          }
        }
        return false;
      });
    }
    return -1;
  };

  useEffect(() => {
    const timer = setInterval(() => setTime((time) => time + 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (lastTime === time) {
      return;
    }
    const currentSentenceIndex = findCurrentSentenceIndex();
    if (currentSentenceIndex !== -1) setCurrentPosition(currentSentenceIndex);
  }, [lastTime, time, currentPosition]);

  function isInViewPort(element: HTMLElement) {
    const recordContainer = document.querySelector(".record-container");
    const recordContainerRec = recordContainer?.getBoundingClientRect();
    if (!recordContainerRec) return false;
    const { top, bottom } = element.getBoundingClientRect();
    return (
      top - recordContainerRec.top >= 60 && bottom <= recordContainerRec.bottom
    );
  }
  useEffect(() => {
    const containerElement = document.querySelector(".caption-list-container");
    const currentCaptionElement = document.getElementById(
      `caption-${currentPosition}`
    );
    const currentTimer = timerRef.current;
    if (!currentTimer) {
      currentCaptionElement?.scrollIntoView({ block: "center" });
    }
    const handleScroll = () => {
      const currentCaptionElement = document.getElementById(
        `caption-${currentPosition}`
      );
      if (currentCaptionElement) {
        const isInUserView = isInViewPort(currentCaptionElement);
        if (!isInUserView) {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }

          timerRef.current = Number(
            setTimeout(() => {
              setShouldScrollToCurrentCaption(true);
            }, 3000)
          );
        } else if (isInUserView && timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = 0;
        }
      }
    };
    containerElement?.addEventListener("scroll", handleScroll);

    return () => {
      containerElement?.removeEventListener("scroll", handleScroll);
    };
  }, [currentPosition]);

  useEffect(() => {
    if (shouldScrollToCurrentCaption) {
      const currentCaptionElement = document.getElementById(
        `caption-${currentPosition}`
      );
      if (currentCaptionElement)
        currentCaptionElement.scrollIntoView({ block: "center" });
      setShouldScrollToCurrentCaption(false);
    }
  }, [shouldScrollToCurrentCaption, currentPosition]);

  return (
    <div className="caption-list-container">
      {sentenceList?.map((sentence, index) => {
        const currentTimeString = formatCaptionTime(sentence.startTime);
        return (
          <div
            id={"caption-" + index}
            className={
              "caption-container " + (index === currentPosition ? "active" : "")
            }
            onClick={() => {
              (window as any).hlsInstance.media.currentTime =
                sentence.startTime;
            }}
          >
            <div className="caption-time">{currentTimeString}</div>
            <div className="caption-content">{sentence.content}</div>
          </div>
        );
      })}
    </div>
  );
};

export default RecordPlayer;
