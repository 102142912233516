import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popover } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { searchHostApi, searchSpaceApi } from "../../../api";
import NoDataDark from "../../../assets/NoData_dark.svg";
import NoDataLight from "../../../assets/NoData_light.svg";
import { useWindowSize } from "../../../utils/useWindowSize";
import RenderCard from "../HostCard/card";
import ListLoader from "../ListLoader";
import RenderSpaceCard from "../SpaceCard/card";
import "./index.less";

const PAGE_SIZE = 8;

function HeaderSearch({ theme }: { theme: string }) {
  const timer = useRef<any>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [liveSpaces, setLiveSpaces] = useState<API.SpaceDTO[]>([]);
  const [hosts, setHosts] = useState<API.HostDTO[]>([]);
  const [hostPage, setHostPage] = useState<number>(1);
  const [livePage, setLivePage] = useState<number>(1);
  const [resultOpen, setResultOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState({
    host: true,
    live: true,
  });
  const { width } = useWindowSize();

  const { column } = useMemo(() => {
    if (width < 1301 && width > 1060) {
      return {
        column: 3,
        scrollWidth: "calc(100vw - 80px)",
      };
    }
    if (width < 1061 && width > 750) {
      return {
        column: 2,
        scrollWidth: "calc(100vw - 80px)",
      };
    }
    if (width < 751) {
      return {
        column: 1,
        scrollWidth: "calc(100vw - 40px)",
      };
    }
    return {
      column: 4,
      scrollWidth: "calc(100vw - 80px)",
    };
  }, [width]);

  const searchLive = useCallback(async () => {
    if (!searchValue) {
      return;
    }
    // live
    const res = await searchSpaceApi({
      key: searchValue,
      page: livePage,
      size: PAGE_SIZE,
    });
    if (!res.data) {
      return;
    }
    if (livePage === 1) {
      setLiveSpaces(res.data);
    } else {
      setLiveSpaces([...liveSpaces, ...res.data]);
    }
    setHasMore({
      ...hasMore,
      live: res.data?.length === PAGE_SIZE,
    });
  }, [hasMore, livePage, liveSpaces, searchValue]);

  const searchHost = useCallback(async () => {
    if (!searchValue) {
      return;
    }
    const res = await searchHostApi({
      key: searchValue,
      page: hostPage,
      size: column * 2,
    });
    if (!res.data) {
      setHasMore({
        ...hasMore,
        host: false,
      })
      return;
    }
    if (hostPage === 1) {
      setHosts(res.data);
    } else {
      setHosts([...hosts, ...res.data]);
    }
    setHasMore({
      ...hasMore,
      host: res.data?.length === column * 2,
    });
  }, [column, hasMore, hostPage, hosts, searchValue]);

  const onSearch = (event: any) => {
    setHosts([]);
    setLiveSpaces([]);
    setHostPage(1);
    setLivePage(1);
    setSearchValue(event.target.value);
    setResultOpen(true);
  };

  const updateItem = (item: API.SpaceDTO) => {
    setLiveSpaces(
      liveSpaces.map((space) => (space.space === item.space ? item : space))
    );
  };

  /** load more start */
  useEffect(() => {
    if (hostPage !== 1) {
      searchHost();
    }
  }, [hostPage]);

  useEffect(() => {
    if (livePage !== 1) {
      searchLive();
    }
  }, [livePage]);
  /** load more end */

  // debounce search
  useEffect(() => {
    if (!searchValue) {
      return;
    }
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setLoading(true);
    timer.current = setTimeout(() => {
      Promise.all([searchLive(), searchHost()]).finally(() =>
        setLoading(false)
      );
    }, 1000);
  }, [searchValue]);

  const resultContent = (
    <div className="search-result">
      {/* spaces */}
      {liveSpaces?.length > 0 && (
        <div className="space-list">
          <div className="search-sub-title">Spaces</div>
          <div className="result">
            {liveSpaces.map((space) => (
              <RenderSpaceCard
                item={space}
                layout={"list"}
                theme={theme}
                updateItem={updateItem}
                short
              />
            ))}
          </div>
          {hasMore.live && (
            <div className="more">
              <Button
                className="load-more-btn"
                onClick={() => setLivePage(livePage + 1)}
              >
                Load more
              </Button>
            </div>
          )}
        </div>
      )}
      {/* host */}
      {hosts?.length > 0 && (
        <div className="host-list">
          <div className="search-sub-title">HOSTS</div>
          <div className="result">
            {hosts.map((host) => (
              <RenderCard
                item={host}
                theme={theme}
                order="fansNum"
                layout="list"
                short
              />
            ))}
          </div>
          {hasMore.host && (
            <div className="more">
              <Button
                className="load-more-btn"
                onClick={() => setHostPage(hostPage + 1)}
              >
                Load more
              </Button>
            </div>
          )}
        </div>
      )}

      {/* loading */}
      {loading && (
        <div className="result-loading">
          <ListLoader layout="list" />
        </div>
      )}

      {/* nodata */}
      {!loading && hosts.length === 0 && liveSpaces.length === 0 && (
        <div className="no-data">
          <img src={theme === "dark" ? NoDataDark : NoDataLight} alt="" />
          No data
        </div>
      )}
    </div>
  );

  return (
    <div className="header-search">
      <Popover
        content={resultContent}
        trigger="click"
        zIndex={99999999}
        open={resultOpen}
        // arrowPointAtCenter
        overlayClassName={"header-search-result"}
        showArrow={false}
        placement="bottomLeft"
        onOpenChange={(open) => setResultOpen(searchValue ? open : false)}
      >
        <div className="search-input">
          <Input
            className="input"
            value={searchValue}
            allowClear
            prefix={<SearchOutlined style={{ marginRight: "8px" }} />}
            placeholder="Search space or host"
            onChange={onSearch}
            onPressEnter={onSearch}
          />
        </div>
      </Popover>
    </div>
  );
}

export default HeaderSearch;
