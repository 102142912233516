import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { THEME_KEY } from "./api";
import "./App.less";
import Home from "./pages";
import Agora from "./pages/Agora";
import HostInfo from "./pages/HostInfo";
import Landing from "./pages/Landing";
import Profile from "./pages/Profile";
import SpaceInfo from "./pages/SpaceInfo";
import ScheduledSpaceInfo from "./pages/SpaceInfo/scheduled";
import Terms from "./pages/Terms";
import { notify } from "./utils";
import { Context } from "./utils/context";
// import { onboard } from "./utils/account";

const dark = {
  "--main-bg": "#111518",
  "--label-border": "#323232",
  "--label-bg": "#141414",
  "--label-text": "rgba(255,255,255,0.8)",
  "--label-active-bg": "#D0D0D0",
  "--gradient-bg":
    "linear-gradient(135deg, #7CE6B2 0%, #92C0F3 31%, #DCCFFE 77%, #FBFAFF 100%)",
  "--gradient-text-green": "linear-gradient(135deg, #7CE6B2 0%, #92C0F3 100%)",
  "--gradient-text-purple":
    "linear-gradient(135deg, #E67CA7 0%, #9299F3 31%, #DCCFFE 77%, #FBFAFF 100%)",
  "--card-bg": "#1E262C",
  "--card-bottom-bg": "rgba(208,208,208,0.1)",
  "--card-button-text": "#d0d0d0",
  "--black-text": "#000",
  "--label-hover-bg": "#1D1D1D",
  "--white-text": "rgba(255,255,255,0.6)",
  "--list-item-bg": "#1E262C",
  "--list-item-border": "#111518",
  "--list-item-text": "#fff",
  "--select-arrow": "rgba(255, 255, 255, 0.3)",
  "--popover-bg": "rgba(30, 38, 44, 1)",
  "--popover-text": "rgba(255, 255, 255, 0.85)",
  "--login-bg": "#242E36",
  "--right-text-background": "#36404A",
  "--right-text": "#EFF2F7",
  "--conversation-name": "rgba(255, 255, 255, 0.60)",
  "--proposal-title": "rgba(255, 255, 255, 1)",
  "--like-border": "#fff",
  "--like-num": "rgba(255, 255, 255, 1)",
  "--divider": "rgba(54, 64, 74, 1)",
  "--host-info-bg": "#1E262C",
  "--host-info-divider": "#36404A",
  "--card-icon-btn": "rgba(208,208,208,0.1)",
};

const light = {
  "--main-bg": "#F2F4F6",
  "--label-border": "#D0D0D0",
  "--label-bg": "#fff",
  "--label-text": "#000000",
  "--label-active-bg": "#000",
  "--gradient-bg":
    "linear-gradient(135deg, #36B275 0%, #659EDD 31%, #9E82E8 76%, #967EF5 100%)",
  "--gradient-text-green": "linear-gradient(135deg, #36B275 0%, #659EDD 100%)",
  "--gradient-text-purple": "linear-gradient(135deg, #9E82E8 0%, #967EF5 100%)",
  "--card-bg": "#fff",
  "--card-bottom-bg": "rgba(50,50,50,0.05)",
  "--card-button-text": "#323232",
  "--black-text": "#fff",
  "--label-hover-bg": "#D0D0D0",
  "--white-text": "rgba(0,0,0,0.6)",
  "--list-item-bg": "#FFFFFF",
  "--list-item-border": "#F2F4F6",
  "--list-item-text": "#000",
  "--select-arrow": "rgba(0, 0, 0, 0.3)",
  "--popover-bg": "#fff",
  "--popover-text": "rgba(0, 0, 0, 0.85)",
  "--login-bg": "#fff",
  "--right-text-background": "#EBEEF2",
  "--right-text": "#323232",
  "--conversation-name": "rgba(0, 0, 0, 0.60)",
  "--proposal-title": "rgba(0, 0, 0, 1)",
  "--like-border": "#7a52fc",
  "--like-num": "rgba(114, 105, 239, 1)",
  "--divider": "rgba(235, 238, 242, 1)",
  "--host-info-bg": "#fff",
  "--host-info-divider": "#EBEEF2",
  "--card-icon-btn": "rgba(0,0,0,0.4)",
};

function App() {
  const [theme, setTheme] = useState("dark");
  const [userInfo, setUserInfo] = useState<API.UserInfo>({} as API.UserInfo);
  const [airdropState, setAirdropState] = useState<number>(1);

  const themes: Record<string, any> = { dark, light };

  const changeTheme = (t: string) => {
    const nextTheme = themes[t];
    setTheme(t);
    // onboard.state.actions.updateTheme(t as any);
    sessionStorage.setItem(THEME_KEY, t);
    Object.keys(nextTheme).forEach((key) => {
      document.documentElement.style.setProperty(key, nextTheme[key]);
    });
  };

  useEffect(() => {
    const darkChrome = matchMedia("(prefers-color-scheme: dark)").matches;
    const customTheme = sessionStorage.getItem(THEME_KEY);
    const storageTheme = customTheme
      ? customTheme
      : darkChrome
      ? "dark"
      : "light";
    changeTheme(storageTheme);
    (window as any).theme = storageTheme;

    window.addEventListener("offline", () => {
      notify({
        type: "warning",
        message: "Network offline",
        description: "",
      });
    });
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Landing theme={theme} changeTheme={changeTheme} />,
    },
    {
      path: "/:tab",
      element: (
        <Home
          theme={theme}
          changeTheme={changeTheme}
          loggedIn={!!userInfo.uid}
        />
      ),
    },
    {
      path: "/host/:id",
      element: <HostInfo theme={theme} changeTheme={changeTheme} />,
    },
    {
      path: "/space/:id",
      element: (
        <SpaceInfo
          theme={theme}
          changeTheme={changeTheme}
          loggedIn={!!userInfo.uid}
        />
      ),
    },
    {
      path: "/scheduled/:id",
      element: <ScheduledSpaceInfo theme={theme} changeTheme={changeTheme} />,
    },
    {
      path: "/agora",
      element: <Agora />,
    },
    {
      path: "/profile",
      element: <Profile theme={theme} changeTheme={changeTheme} />,
    },
    {
      path: "/terms",
      element: <Terms theme={theme} changeTheme={changeTheme} />,
    },
    // {
    //   path: "/replay",
    //   element: <Replay />,
    // },
  ]);

  const state: any = {
    userInfo: userInfo,
    airdropState: airdropState,
    setUserInfo: (info: API.UserInfo) => setUserInfo(info),
    updateAirdropState: (state: number) => setAirdropState(state),
  };

  return (
    <div className="App">
      <Context.Provider value={state}>
        <RouterProvider router={router} />
      </Context.Provider>
    </div>
  );
}

export default App;
